const urlDev = "https://adoring-shape-33260.pktriot.net";

// const base = "Homolog";
// const base = "Dev";

const base = "Prod";
const url = selecionarBase(base);

const global = {
  // visaoCliente: true,
  visaoCliente: false,

  tokenK2: "",
  api: {
    baseURLK2: "",
    baseURL: url.baseURL,
    baseURLVetus: url.baseURLVetus,
    baseUrlChamefacil: url.baseUrlChamefacil,
    baseUrlChamefacil_api: url.baseUrlChamefacil_api,
    token_pagSeguro: url.token_pagSeguro,
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
      blue: "#5684da",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};

function selecionarBase(base) {
  let baseURL = urlDev + "/centro_olhos_api/public";
  // let baseURL = urlDev + "/caesegatos_dev/public";
  let baseURLVetus = urlDev + "/oftalmo_dev/api";

  // Api ChameFacil
  let baseUrlChamefacil =
    "https://chamefacilcentrodeolhosveterianarios.homolog.prolins.com.br/api";
  let baseUrlChamefacil_api =
    "https://chamefacilcentrodeolhosveterianariosapi.homolog.prolins.com.br/api";

  // Api ChameFacil Cães e Gatos
  // let baseUrlChamefacil = "https://chamefacilpettreeteste.prolins.com.br/api";
  // let baseUrlChamefacil_api =
  //   "https://chamefacilpettreeapiteste.prolins.com.br/api";

  let token_pagSeguro = "";

  switch (base) {
    case "Prod":
      // Api Pettree
      baseURL = "https://api.centrodeolhosvet.pettree.com.br/v1/public";
      baseURLVetus =
        "https://api.centrodeolhosvet.pettree.com.br/vet1/public/api";

      // Api ChameFacil
      baseUrlChamefacil_api =
        "https://chamefacilcentrodeolhosveterianariosapi.prolins.com.br/api";
      baseUrlChamefacil =
        "https://chamefacilcentrodeolhosveterianarios.prolins.com.br/api";

      // Api ChameFacil CaeseGatos
      // baseUrlChamefacil_api =
      //   "https://chamefacilpettreeapi2.prolins.com.br/api";
      // baseUrlChamefacil = "https://chamefacilpettree.prolins.com.br/api";

      break;
    case "Homolog":
      // Api Pettree
      // baseURL = "https://api.caesegatos.homolog.prolins.com.br/v1/public";
      baseURL = "https://api.centrodeolhosvet.homolog.prolins.com.br/v1/public";
      // baseURLVetus =
      baseURLVetus =
        "https://api.centrodeolhosvet.homolog.prolins.com.br/vet1/public/api";
      break;
  }

  return {
    baseURL,
    baseURLVetus,
    baseUrlChamefacil,
    baseUrlChamefacil_api,
    token_pagSeguro,
  };
}
export { global };
