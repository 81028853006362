import HTTPService from "@/services/http_service.js";
// import { global } from "@/config/constants";

export default class ComandaService extends HTTPService {
  static build() {
    return new ComandaService();
  }

  getRecebimentos(unidadeId, dataInicial, dataFinal) {
    return this.get(
      `comanda/meus-recebimentos/new/${unidadeId}/${dataInicial}/${dataFinal}`
    ).then((resp) => resp.json());
  }

  getRecebimentosByUnidade(unidadeId, dataInicial, dataFinal, options) {
    let queryparams = {
      page: options.page,
      per_page: options.itemsPerPage,
    };
    return this.get(
      `comanda/recebimentos/unidade/${unidadeId}/${dataInicial}/${dataFinal}`,
      queryparams
    ).then((resp) => resp.json());
  }

  getPdfRecebimentos(unidadeId, dataInicial, dataFinal) {
    return this.get(
      `comanda/meus-recebimentos/new/pdf/${unidadeId}/${dataInicial}/${dataFinal}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  getPdfRecebimentosByUnidade(unidadeId, dataInicial, dataFinal) {
    return this.get(
      `comanda/recebimentos/us/unidade/pdf/1/${unidadeId}/${dataInicial}/${dataFinal}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  getExcelRecebimentosByUnidade(unidadeId, dataInicial, dataFinal) {
    return this.get(
      `comanda/recebimentos/us/unidade/excel/1/${unidadeId}/${dataInicial}/${dataFinal}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  intensComandaAbertos(petId, unidadeId) {
    return this.get(
      `comanda/recepcao/itens/abertos/pet/${petId}/${unidadeId}`
    ).then(async (resp) => {
      return {
        status: resp.status,
        response: await resp.json(),
      };
    });
  }

  async getComandasByClienteId(clienteId) {
    return await this.get(`comanda/cliente/${clienteId}`).then(
      async (response) => {
        if (response.status !== 200) return [];
        const { data } = await response.json();
        return data;
      }
    );
  }

  async getComandasRecepcaoByClienteId(clienteId, unidadeId) {
    return await this.get(
      `comanda/recepcao/cliente/${clienteId}/${unidadeId}`
    ).then(async (response) => {
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
    });
  }

  async getComandasByPetId(clienteId, unidadeId, petId) {
    return await this.get(
      `comanda/recepcao/cliente/pet/${clienteId}/${unidadeId}/${petId}`
    ).then(async (response) => {
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
    });
  }

  async getComandaItensByComandaId(comandaId) {
    return await this.get(`comanda/${comandaId}/itens`).then(
      async (response) => {
        if (response.status !== 200) return [];
        const { data } = await response.json();
        return data;
      }
    );
  }

  async getComandaItensPagosByComandaId(comandaId) {
    return await this.get(`comanda/itens/pagos/${comandaId}`).then(
      async (response) => {
        if (response.status !== 200) return [];
        const { data } = await response.json();
        return data;
      }
    );
  }

  async auditoriaComandaItensByComandaId(comandaId) {
    return await this.get(`audit/comanda/itens/${comandaId}`).then(
      async (response) => {
        if (response.status !== 200) return [];
        const { data } = await response.json();
        return data;
      }
    );
  }

  async getRelatorioComandaById(comandaId) {
    return await this.get(`comanda/relatorio/${comandaId}`).then((resp) => {
      if (resp.status !== 200) return [];
      return resp.json();
    });
  }

  async pdfByComandaId(comandaId) {
    return await this.get(`comanda/relatorio-individual/pdf/${comandaId}`).then(
      (resp) => {
        if (resp.status !== 200) return resp;
        return resp.blob();
      }
    );
  }

  async pdfItensExcluidosByComandaId(comandaId) {
    return await this.get(`comanda/new/full/export/pdf/${comandaId}`).then(
      (resp) => {
        if (resp.status !== 200) return resp;
        return resp.blob();
      }
    );
  }

  async excelByComandaId(comandaId) {
    return await this.get(`comanda/full/export/excel/${comandaId}`).then(
      (resp) => {
        if (resp.status !== 200) return resp;
        return resp.blob();
      }
    );
  }

  pagarItemComanda(pagamento) {
    return this.post("comanda/item/marcar-pago/forma-pagamento", {
      ...pagamento,
    });
  }

  excluirItemComanda(item) {
    return this.postJson("comanda/item/excluir", item);
  }

  async fecharComanda(comandaId) {
    const response = await this.post(`comanda/${comandaId}/fechar`);
    return response;
  }

  getRelatorioComandas(dataInicial, dataFinal, unidade, status) {
    if (status == "todas") {
      return this.get(
        `comanda/periodo/${dataInicial}/${dataFinal}/${unidade}`
      ).then((response) => {
        return response.json();
      });
    } else {
      return this.get(
        `comanda/periodo/${status}/${dataInicial}/${dataFinal}/${unidade}`
      ).then((response) => {
        return response.json();
      });
    }
  }

  pdfRelatorioComandas(dataInicial, dataFinal, unidade, status) {
    return this.get(
      `comanda/pdf/periodo/${dataInicial}/${dataFinal}/${unidade}/${status}`
    );
    // ).then((resp) => {
    //   if (resp.status != 200) return resp;
    //   return resp.blob();
    // });
  }

  excelRelatorioComandas(dataInicial, dataFinal, unidade, status) {
    return this.get(
      `comanda/excel/periodo/${dataInicial}/${dataFinal}/${unidade}/${status}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  pdfRelatorioComandaById(comandaId) {
    return this.get(`comanda/relatorio/pdf/${comandaId}`).then((resp) =>
      resp.blob()
    );
  }
}
