<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Prontuários</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pacientes"
      :options.sync="options"
      :server-items-length="totalList"
      sort-by="nome"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat max-width="100%">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex mt-3">
            <v-text-field
              v-model="searchId"
              label="Id do Pet"
              placeholder="Id do Pet"
              class="mr-5 col-4"
              append-icon="mdi-magnify"
              @click:append="searchPetById"
              @keydown.enter="searchPetById"
              single-line
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar por nome do pet"
              placeholder="Pesquisar por nome"
              single-line
              hide-details
            ></v-text-field>
          </div>

          <!-- DIALOG FOTOS CLASSIFICADAS -->
          <v-dialog
            v-model="dialogFotosClassificadas"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <fotos-classificadas
              :paciente="paciente.id_paciente"
              :key="rederComp"
              @close="close()"
            />
          </v-dialog>
          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialog"
            v-if="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <prontuarios
              :consultas="prontuarios"
              :pacienteInfo="prontuariosInfo"
              @close="close()"
            />
          </v-dialog>

          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialogGaleria"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <galerias :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="white">
              <v-card-text>
                Carregando...
                <v-progress-linear
                  indeterminate
                  color="green lighten-3"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogArquivo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <arquivo :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog
            v-model="dialogUploadExames"
            v-if="dialogUploadExames"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <UploadExames
              :paciente="paciente"
              :key="rederComp"
              @close="dialogUploadExames = false"
            />
          </v-dialog>
          <v-dialog
            v-model="dialogAtestado"
            v-if="dialogAtestado"
            hide-overlay
            persistent
            width="30%"
          >
            <atestado @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogExames"
            v-if="dialogExames"
            hide-overlay
            persistent
            width="600"
          >
            <exames @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogEncaminhamento"
            v-if="dialogEncaminhamento"
            hide-overlay
            persistent
            width="55%"
          >
            <encaminhamento @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogReceita"
            v-if="dialogReceita"
            hide-overlay
            persistent
            width="60%"
          >
            <receita @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
            <termos @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogReceitaCarbonada"
            hide-overlay
            persistent
            width="50%"
          >
            <receita-carbonada @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogAddAnaminese" persistent fullscreen>
            <v-card>
              <v-toolbar style="background-color: #1daf80; color: #fff">
                <v-btn icon dark @click="dialogAddAnaminese = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Anamnese Adicional</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <modal-nova-anaminese
                v-if="dialogAddAnaminese"
                :paciente="infoAnamnese"
                @close="(dialogAddAnaminese = false), teste()"
              />
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-if="true" v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          title="Declaração"
          @click="gerarAtestado(item)"
          >fas fa-file-medical
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Encaminhamento Médico"
          @click="gerarEncaminhamento(item)"
          >fas fa-user-md</v-icon
        >
        <v-icon
          small
          class="mr-2"
          title="Solicitar exames"
          @click="gerarSolicitacaoDeExame(item)"
          >fas fa-tasks
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Receita Novas"
          @click="gerarReceitasNovas(item)"
          >fas fa-capsules
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Receita Carbonada"
          @click="gerarReceitasCarbonada(item)"
          >fas fa-file-alt
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Termo de Responsabilidade"
          @click="gerarTermos(item)"
          >fas fa-file-signature</v-icon
        >
        <v-icon
          size="19"
          class="mr-2"
          title="Upload de exames"
          @click="listUpload(item)"
          >mdi-file-upload-outline
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Galeria de fotos"
          @click="listGalerias(item)"
          >fas fa-images
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Fotos classificadas"
          @click="listFotosClassificadas(item)"
          >fa-solid fa-bookmark
        </v-icon>
        <v-icon small class="mr-2" title="Documentos" @click="listFile(item)"
          >fas fa-folder
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Anamnese Adicional"
          v-if="perfilId != 1"
          @click="anamneseAdicional(item)"
          >fas fa-notes-medical
        </v-icon>
        <v-icon
          small
          class="mr-2"
          color="primary"
          title="Prontuários"
          @click="listProntuarios(item)"
          >fas fa-file-medical-alt
        </v-icon>
        <v-icon size="19" @click="showComandasPet(item)">
          mdi mdi-magnify
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogComandas"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogComandas = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Comandas </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>

        <modal-comandas :tutor="tutor" :petId="petIdComanda" />
      </v-card>
    </v-dialog>

    <!-- EXAMES PDF -->
    <div id="examesPDF" ref="examesPDF" v-show="false">
      <div
        id="header"
        :class="
          htmlToPdf == 'receita' || 'receita-carbonada'
            ? 'header-receita d-flex'
            : 'd-flex'
        "
      >
        <img
          src="@/assets/images/logo_sistema.jpg"
          style="margin: auto;"
          alt="Logo Centro Olhos"
          v-if="convenioPublico"
        />
        <img
          src="@/assets/images/logo_sistema_diagnostico.jpg"
          style="margin: auto;"
          alt="Logo Diagnóstico Veterinário"
          v-if="!convenioPublico"
        />
        <!-- <img
          src="../assets/images/logo_caes_gatos.jpg"
          style="margin: auto"
          alt="Logo cães e gatos"
        /> -->
        <!-- <img
          src="../assets/images/logo_televet_play.png"
          style="margin: auto"
          alt="Logo anclivepa particular"
          v-if="convenioPublico == false"
        /> -->
      </div>
      <div id="main">
        <!-- DOC ATESTADO -->
        <div v-if="htmlToPdf == 'atestado'">
          <div v-if="tipoDeclaracao == 'atestado'">
            <p class="mt-1 text-center"><b>Atestado Médico</b></p>
            <p class="indent resp">
              Atesto para devidos fins que o(a) Sr(a). <b>{{ nome }}</b
              >, inscrito(a) no CPF sob o n° {{ cpf }}, compareceu ao Centro de
              Olhos Veterinário - {{ unidade }} para realização de procedimentos
              veterinários, nesta data,
              {{
                data.getDate() +
                  "/" +
                  (data.getMonth() + 1) +
                  "/" +
                  data.getFullYear()
              }}.
            </p>
          </div>
          <div v-else-if="tipoDeclaracao == 'declaração de horas'">
            <p class="mt-1 text-center">
              <b>Declaração de Horas</b>
            </p>
            <p class="indent resp" v-if="preencherDadosTutor">
              Declaro, para os devidos fins, que o(a) Sr(a).
              {{ tutor.nome }}, portador do RG: {{ tutor.rg }} e CPF:
              {{ tutor.cpf }}, esteve acompanhando o pet {{ pet }}, em
              atendimento emergencial no Centro de Olhos Veterinário, na data
              ____ de _______________ de _______________, das ____:____ as
              ____:____.
            </p>
            <p class="indent resp" v-else>
              Declaro, para os devidos fins, que o(a) Sr(a).
              __________________________________________, portador do RG
              ______________________________ e CPF
              _____________________________, esteve acompanhando o pet
              ___________________________ em atendimento emergencial no Centro
              de Olhos Veterinário, na data ____ de _______________ de
              _______________, das ____:____ as ____:____.
            </p>
          </div>

          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              {{ informacoes_unidade.cidade }}, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p
              class="d-flex flex-column"
              style="margin: 0 !important; justify-content: center; align-items: center;"
            >
              <span> _________________________________________</span>
              <span>{{ unidade }}, CNPJ: {{ informacoes_unidade.cnpj }}</span>
            </p>
          </div>
          <div class="m-auto text-center rodape" style="width: 200px;">
            <!-- <span class="mr-1">{{ unidade }}</span> -->
            <span class="mr-1">
              {{
                informacoes_unidade.logradouro +
                  ", " +
                  informacoes_unidade.numero +
                  ", " +
                  informacoes_unidade.bairro +
                  ", " +
                  informacoes_unidade.cidade +
                  "/" +
                  informacoes_unidade.estado
              }}
              - {{ informacoes_unidade.cep }}
            </span>
            <span>{{ informacoes_unidade.telefone }}</span>
          </div>
        </div>
        <!-- DOC RECEITA -->
        <!-- <div
          class="d-flex flex-column w-100 resp"
          v-else-if="htmlToPdf == 'receita'"
          style="margin-bottom: 30px"
        >
          <div>
            <p>
              Tutor: <span class="space">_</span><b>{{ nome }}</b
              ><br />
              Pet: <span class="space">_</span><b>{{ pet }}</b>
            </p>
            <p class="mx-auto text-center mb-1"><b>MEDICAÇÕES</b></p>
          </div>
          <u v-for="(item, i) in receita" :key="i">
            <li class="d-flex flex-column resp">
              <span v-if="item.titulo">
                <strong
                  ><u>{{ item.titulo }}</u></strong
                > </span
              ><br />
              <p class="resp">
                {{ item.receitaTexto }}
              </p>
            </li>
          </u>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              {{ gerarData().dia }}<span class="space">_</span>de<span
                class="space"
                >_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
          </div>
          <p class="d-flex flex-column ml-auto">
            <span> _________________________________________</span>
            <span class="ml-auto">{{ usuarioNome }}</span>
          </p>
        </div> -->
        <!-- DOC RECEITA CARBONADA -->
        <div
          v-else-if="
            [
              'exames',
              'encaminhamento',
              'receita-carbonada',
              'receita',
            ].includes(htmlToPdf)
          "
          class="carbon zeroEspaco color-black"
        >
          <!-- <div class="row row-cols-2" style="width: 18rem">
            <div class="col" style="border-right: solid gray 0.01px !important">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="
                      border: solid gray 0.1px !important;
                      width: 4.5rem !important;
                      padding-right: 1px;
                      padding-left: 1px;
                    "
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
            <div class="col">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp receituario_controle">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="
                      border: solid gray 0.1px !important;
                      width: 4.5rem !important;
                      padding-right: 1px;
                      padding-left: 1px;
                    "
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0"
            style="width: 18rem; padding-top: 0px"
          >
            <div
              class="col"
              style="
                border-right: solid gray 0.01px !important;
                padding-top: 0px;
              "
            >
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
            <div class="col" style="padding-top: 0px">
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0 pr-0"
            style="width: 18rem; padding: 0px"
          >
            <div
              class="col"
              style="
                border-right: solid gray 0.01px !important;
                margin-left: 1px;
              "
            >
              <div
                class="row rows-cols-2"
                style="border: solid gray 0.01px !important"
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right: 0px">
                  <span> IDENTIFICAÇÃO DO FORNECEDOR </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="row rows-cols-2"
                style="
                  border-top: solid gray 0.01px !important;
                  border-right: solid gray 0.01px !important;
                  border-bottom: solid gray 0.01px !important;
                "
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right: 0px">
                  <span> IDENTIFICAÇÃO DO FORNECEDOR </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
          </div> -->
          <div class="body">
            <div class="d-flex justify-content-between align-items-center">
              <p>{{ unidade }}</p>
              <div class="text-right">
                <p>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} -
                  {{ informacoes_unidade.cidade }}
                </p>
                <p>CEP: {{ informacoes_unidade.cep }}</p>
                <p>Tel.: {{ informacoes_unidade.telefone }}</p>
                <p>CNPJ: {{ informacoes_unidade.cnpj }}</p>
              </div>
            </div>
            <hr />
            <div
              class="d-flex justify-content-between align-items-center m-0 mt-1 p-0 px-1"
              v-if="htmlToPdf == 'receita-carbonada'"
            >
              <p>Receita de Controle Especial</p>
              <p>1ª via: Farmácia | 2ª via: Paciente</p>
            </div>
            <div class="d-flex justify-content-between align-items-stretch gap">
              <div class="border p-1">
                <b>Identificação do Emitente</b><br />
                <br /><b>Nome: </b> <span>{{ usuarioNome }}</span> <br /><b
                  >CRMV: </b
                >{{ crmv }} <span></span> <br /><b>Endereço: </b>
                <span>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} </span
                ><br />
                <span>
                  {{ informacoes_unidade.cidade }} -
                  {{ informacoes_unidade.cep }}
                </span>
              </div>
              <div class="border p-1">
                <b>Animal</b><br />
                <br />
                <b>Nome: </b> <span>{{ pet.nome }}</span> <br />
                <b>Idade: </b> <span>{{ pet.idade }}</span> <br />
                <b>Espécie: </b> <span>{{ pet.especie }}</span> <br />
                <b>Raça: </b> <span>{{ pet.raca }}</span> <br />
                <b>Sexo: </b> <span>{{ pet.sexo }}</span> <br />
                <b>Peso: </b> <span>{{ pet.peso }}</span>
              </div>
              <div class="border p-1">
                <b>Tutor</b><br />
                <br />
                <b>Nome: </b> <span>{{ tutor.nome }}</span> <br />
                <b>CPF: </b> <span>{{ tutor.cpf }}</span> <br />
                <b>Endereço: </b>
                <span> {{ tutor.logradouro }}, {{ tutor.numero }} </span><br />
                <span> {{ tutor.cidade }} - {{ tutor.cep }} </span>
              </div>
            </div>
            <div v-if="htmlToPdf == 'receita-carbonada'">
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  <div>
                    <b>{{ i + 1 }} - {{ item.medicamento }}</b>
                    <span> ( {{ item.quantidade }} )</span>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li>
                <br />
              </u>
            </div>
            <div
              v-else-if="htmlToPdf == 'receita'"
              id="intensReceitaTeste"
              class="ml-1 break-w"
              style="width: 94%;"
            >
              <!-- <u
                v-for="(item, i) in receita"
                :key="i"
                style="word-break:break-all"
              >
                <li class="d-flex flex-column zeroEspaco marg-2">
                  <span v-if="item.titulo">
                    <strong
                      ><u>{{ item.titulo }}</u></strong
                    > </span
                  ><br />
                  <p class="descMedicacao">
                    {{ item.receitaTexto }}
                  </p>
                </li>
              </u> -->
            </div>
            <div
              v-else-if="htmlToPdf == 'encaminhamento'"
              class="ml-1 break-w"
              style="width: 94%;"
            >
              <div id="corpoEncaminhamento"></div>
              <div class="d-flex flex-column align-end pt-8">
                <p class=" text-center">
                  {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                  }}<span class="space">_</span>de<span class="space">_</span
                  >{{ gerarData().mes }}<span class="space">_</span
                  >{{ gerarData().ano }}.
                </p>
                <p class=" text-center">
                  _________________________________________
                </p>
                <p class=" text-center resp">{{ usuarioNome }}</p>
              </div>
            </div>
            <!-- DOC EXAMES -->
            <div
              class="d-flex flex-column w-100"
              v-else-if="htmlToPdf == 'exames'"
            >
              <!-- <div
            class="d-flex justify-content-between align-items-stretch gap p-0"
          >
            <div>
              <b>Pet</b>
              <br />
              <b>Nome: </b> <span>{{ pet.nome_pet }}</span> <br />
              <b>Id: </b> <span>{{ pet.id_pet }}</span> <br />
              <b>Espécie: </b> <span>{{ pet.nome_especie }}</span> <br />
              <b>Raça: </b> <span>{{ pet.nome_raca }}</span> <br />
              <b>Sexo: </b> <span>{{ pet.sexo }}</span>
            </div>
            <div>
              <b>Tutor</b>
              <br />
              <b>Nome: </b> <span>{{ tutor.nome }}</span> <br />
              <b>CPF: </b> <span>{{ tutor.cpf }}</span> <br />
              <b>Endereço: </b>
              <span> {{ tutor.logradouro }}, {{ tutor.numero }} </span><br />
              <span> {{ tutor.cidade }} - {{ tutor.cep }} </span>
            </div>
          </div>
          <hr /> -->
              <b>Solicito:</b>
              <u v-for="(exame, i) in exames" :key="i">
                <li class="text-nowrap resp">{{ exame }}</li>
              </u>
              <div class="break-w">
                <p>OBS.:<span class="space">_</span>{{ obs }}</p>
                <p v-if="obs2 != ''">
                  OBS.:<span class="space">_</span>{{ obs2 }}
                </p>
                <p class="d-flex flex-column">
                  <span class="ml-auto">
                    _________________________________________</span
                  >
                  <span class="ml-auto">{{ usuarioNome }}</span>
                </p>
              </div>
            </div>
          </div>
          <div v-if="!['encaminhamento', 'exames'].includes(htmlToPdf)">
            <br /><br />
            <hr />
            <div
              class="
                d-flex
                justify-content-between
                align-items-stretch
                gap
              "
            >
              <div>
                <p>
                  {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                  }}<span class="space">_</span>de<span class="space">_</span
                  >{{ gerarData().mes }}<span class="space">_</span
                  >{{ gerarData().ano }}.
                </p>
                <p>
                  <b>{{ usuarioNome }}</b>
                </p>
                <p>CRMV: {{ crmv }}</p>
                <br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
              <div>
                <p><b>Identificação do Comprador</b></p>
                <p>Nome:</p>
                <p>RG:</p>
                <p>Endereço:</p>
                <p>Cidade e UF:</p>
                <p>Telefone:</p>
              </div>
              <div>
                <p><b>Identificação do Fornecedor</b></p>
                <p>Data:</p>
                <br /><br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
            </div>
          </div>
        </div>

        <!-- DOC TERMOS -->
        <div v-else-if="htmlToPdf == 'termo'" class="termo">
          <!-- CABEÇALHO -->
          <p class="text-center">
            <b v-if="tipoTermo == 'termo_um'">AUTORIZAÇÃO PARA EUTANÁSIA</b>
            <b v-if="tipoTermo == 'termo_dois'"
              >AUTORIZAÇÃO PARA TRANSFUSÃO SANGUÍNEA</b
            >
            <b v-if="tipoTermo == 'termo_tres'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA TRANSFUSÃO
              SANGUÍNEA</b
            >
            <b v-if="tipoTermo == 'termo_quatro'">TERMO DE COMPROMISSO</b>
            <b v-if="tipoTermo == 'termo_cinco'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA <br />
              REALIZAÇÃO DE PROCEDIMENTO CIRÚRGICO</b
            >
            <b v-if="tipoTermo == 'termo_seis'"
              >RETIRADA DO ANIMAL DA INTERNAÇÃO
            </b>
          </p>

          <!-- CORPO -->
          <div class="small text-justify">
            <div v-if="tipoTermo == 'termo_um'">
              <span><b>Data:</b> {{ dataAtual }}</span
              ><br />
              <span><b>Animal: </b> {{ pet.nome }}</span
              ><br />
              <span><b>Proprietário: </b> {{ tutor.nome }}</span
              ><br />
              <span
                ><b>Endereço: </b> {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}</span
              ><br />
              <span><b>Telefone: </b> {{ tutor.telefone_1 }}</span
              ><br /><br />
              <span>
                Autorizo nesta data a Eutanásia do animal______________, de
                minha propriedade. </span
              ><br /><br /><br />
            </div>
            <div v-if="tipoTermo == 'termo_dois'">
              <span><b>Data:</b> {{ dataAtual }}</span
              ><br />
              <span><b>Animal: </b> {{ pet.nome }}</span
              ><br />
              <span><b>Proprietário: </b> {{ tutor.nome }}</span
              ><br />
              <span
                ><b>Endereço: </b> {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}</span
              ><br />
              <span><b>Telefone: </b> {{ tutor.telefone_1 }}</span
              ><br /><br />
              <span
                >Autorizo nesta data a Transfusão Sanguínea do animal:
                _________________________, de minha propriedade. Declaro que
                estou ciente dos riscos inerentes ao procedimento.
              </span>
              <br /><br /><br />
            </div>
            <div v-if="tipoTermo == 'termo_tres'">
              <span>
                <span class="mr-1"></span>
                Autorizo a transfusão de sangue total, a respeito da ausência de
                teste de compatibilidade sanguínea, por motivo de urgência. </span
              ><br /><br />
              <span>
                <span class="mr-1"></span>
                Eu, {{ tutor.nome }}, declaro que fui devidamente informado(a),
                em linguagem clara e objetiva pelo médico(a) veterinário(a)
                ___________________________, sobre os riscos inerentes ao
                procedimento tais como: reações hemolíticas, alérgicas, febre,
                choque, hipotensão e morte. As reações transfusionais ocorrem em
                cerca de 1-3% dos pacientes transfundidos. </span
              ><br /><br />
              <span>
                <span class="mr-1"></span>
                Declaro, ciente orientado e por vontade própria autorizar o
                procedimento. </span
              ><br /><br /><br />

              <span><b>Tutor: </b> {{ tutor.nome }}</span
              ><br />
              <span><b>Paciente: </b> {{ pet.nome }}</span
              ><br />
              <span><b>Data:</b> {{ dataAtual }}</span
              ><br />
              <span><b>Testemunha: </b> ______________________________</span
              ><br /><br />
            </div>
            <div v-if="tipoTermo == 'termo_quatro'">
              <span>
                <span class="mr-1"></span>
                Ao autorizar nesta data o internamento do animal
                {{ pet.nome }} da espécie {{ pet.especie }}, raça
                {{ pet.raca }}, pelagem ___________________, nascido em
                {{ pet.nascimento }}, comprometo-me comunicar-me pessoalmente ou
                por telefone com o setor responsável para saber a data de alta
                do animal, a fim de retirá-lo. </span
              ><br /><br />

              <span>
                <span class="mr-1"></span>
                Estou ciente que devo saldar as despesas decorrentes do
                internamento e tratamento deste, mesmo que porventura venha
                ocorrer óbito do animal enfermo. </span
              ><br /><br />

              <span>
                <span class="mr-1"></span>
                Estou ciente que os animais que recebem alta e não forem
                retirados após 48 horas, terão as diárias cobradas em dobro e
                isento a clínica Veterinária, através deste tempo, por óbito,
                extravio ou qualquer outro tipo de acidente que possa ocorrer
                com o referido animal. </span
              ><br /><br /><br />

              <span><b>Nome:</b> {{ tutor.nome }}</span
              ><br /><br />
              <span
                ><b>Endereço: </b> {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}</span
              ><br /><br />
              <span><b>Telefone: </b> {{ tutor.telefone_1 }}</span
              ><br /><br />
              <span><b>CAUÇÃO: </b> _________________________</span>
              <br /><br /><br />
            </div>
            <div v-if="tipoTermo == 'termo_cinco'">
              <span class="mr-1"><b>Paciente: </b>{{ pet.nome }}</span>
              <span><b>Sexo: </b>{{ pet.sexo }}</span
              ><br />
              <span><b>Espécie: </b>{{ pet.especie }}</span
              ><br />
              <span class="mr-1"><b>Raça: </b>{{ pet.raca }}</span>
              <span><b>Data de Nascimento: </b>{{ pet.nascimento }}</span
              ><br />
              <span><b>Tutor: </b>{{ tutor.nome }}</span
              ><br />
              <span
                ><b>Endereço: </b> {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}</span
              ><br /><br /><br />

              <span>
                <span class="mr-1"></span>
                Declaro o livre consentimento para a realização do procedimento
                cirúrgico necessário no animal acima identificado, a ser
                realizado pelo(a) Médico(a)
                Veterinário(a)_________________________________________________. </span
              ><br /><br />
              <span>
                <span class="mr-1"></span>
                Declaro, ainda, ter sido esclarecido(a) acerca dos possíveis
                riscos, inerentes ao procedimento proposto, estando o referido
                profissional isento de quaisquer responsabilidades decorrentes
                de tais riscos. </span
              ><br /><br /><br />
            </div>
            <div v-if="tipoTermo == 'termo_seis'">
              <span>
                <span class="mr-1"></span>
                Eu, {{ tutor.nome }}, CPF Nº {{ cpf }} proprietário do animal de
                nome {{ pet.nome }}, espécie {{ pet.especie }} raça
                {{ pet.raca }}. Internado nesta Clínica Veterinária, estou
                ciente do quadro clínico do animal acima descrito, internado
                neste estabelecimento e opto, por motivos pessoais, pela
                retirada do animal do serviço de internação, interrompendo assim
                o tratamento. Assim, estou ciente da possibilidade de evolução
                não favorável no quadro clínico do meu animal e me
                responsabilizo por isso, isentando os médicos veterinários deste
                estabelecimento das complicações decorrentes da interrupção do
                tratamento e locomoção do animal.</span
              >
            </div>
          </div>
          <div
            v-if="tipoTermo == 'termo_cinco' || tipoTermo == 'termo_seis'"
            class="text-right"
          >
            <br /><span
              >{{ informacoes_unidade.cidade }},
              _______/_______/_______________</span
            ><br />
          </div>
          <div v-else class="text-right">
            <span class="mr-1">_________________________, </span>
            <span>_______/_______/_______________</span><br />
          </div>
          <br />

          <!-- RODAPÉ -->
          <div>
            <br />
            <div class="m-auto text-center">
              <span>_____________________________________</span>
              <br />
              <span>Assinatura do(a) tutor(a) e/ou responsável</span>
              <br />
              <br />
              <!-- <span><b>Testemunhas</b></span
              ><br /><br /> -->
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center">
              <div>
                <span>Nome:</span><br />
                <span>CPF:</span><br />
                <span>Assinatura:</span><br />
              </div>
              <div>
                <span>Nome:</span><br />
                <span>CPF:</span><br />
                <span>Assinatura:</span><br />
              </div>
              <div class="w-2"></div>
            </div> -->
          </div>
          <!-- <div class="d-flex justify-content-center rodape">
            <span class="mr-1">{{ unidade }}</span>
            <span class="mr-1">
              {{ informacoes_unidade.logradouro }},
              {{ informacoes_unidade.numero }},
              {{ informacoes_unidade.bairro }},
              {{ informacoes_unidade.cidade }}/{{
                informacoes_unidade.estado
              }}
              - {{ informacoes_unidade.cep }}
            </span>
            <span>{{ informacoes_unidade.telefone }}</span>
          </div> -->
          <!-- <p
            class="resp indent"
            style="font-size: 3px !important; margin-bottom: 20px"
            v-if="tipoTermo == 'termo_um'"
          >
            O (a) paciente ou responsável pelo paciente, Sr. (a)
            <b>{{ nome }}</b
            >, sob o CPF:{{ cpf }}, aqui declarado, dá plena autorização ao seu
            médico assistente, Dr. LUIZ NESTOR VASCONCELOS RODRIGUES C.R.M. Nº.
            6110, para fazer as investigações necessárias ao diagnóstico e
            executar tratamentos, operações, anestesias, transfusões de sangue,
            ou outras condutas médicas que venham de encontro às necessidades
            clínico-cirúrgicas do caso, bem como dá ciência de informações sobre
            sua capacidade técnico profissional e moral, comprometendo-se a
            respeitar as instruções que lhe forem fornecidas, inclusive quanto
            aos imprevistos oriundos da eventualidade como infecções, necroses
            de retalhos ou regiões operadas, assimetrias, deiscências, seromas,
            rejeições, no caso de próteses, embolia pulmonar, trombose venosa
            profunda, entre outras complicações inerentes aos procedimentos.
            Outrossim, sou consciente dos riscos informados, incluindo o de
            morte, ou da não observância das orientações médicas
            recomendadas.<br />
            CIRURGIA (S) PROPOSTAS (S): (indicação):<br />
            {{ procedimento }}
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_um'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Assinatura paciente ou responsável legal) </span>
            </p>
            <p class="d-flex flex-column mr-auto resp">
              NOME POR EXTENSO: {{ nome }}<br />
              RG. Ou CPF.: {{ cpf }}
            </p>
          </div>

          <p class="resp text-center" v-if="tipoTermo == 'termo_dois'">
            <b>TERMO DE CONSENTIMENTO INFORMADO EXPRESSO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_dois'">
            Declaro que recebi o "TERMO DE INFORMAÇÕES PRÉ-CIRÚRGICAS" como
            também todos os esclarecimentos necessários sobre as informações
            específicas contidas neste termo sobre a cirurgia a ser realizada,
            onde foram explicados os detalhes sobre as cicatrizes resultantes,
            outros estados orgânicos, eventuais intercorrências, evoluções
            favoráveis e desfavoráveis pós-cirúrgicas, infecções, deiscências,
            necroses, assimetrias, perda da sensibilidade, rejeições, no caso de
            próteses, embolia pulmonar, trombose venosa profunda, inclusive
            risco de morte, entre outras complicações do procedimento bem como
            os cuidados para minimizar esses problemas. Assim, livremente e de
            espontânea vontade, assino (amos) o presente TERMO DE CONSENTIMENTO
            PÓS-INFORMADO para que o Dr. LUIZ NESTOR VASCONCELOS RODRIGUES,
            C.R.M. Nº. 6110 possa realizar o procedimento clínico-cirúrgico de e
            para que produza os devidos efeitos legais.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_dois'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>Paciente</span>
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Responsável legal, qdo.menor)</span>
            </p>
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_tres'">
            <b>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_tres'">
            Eu <b>{{ nome }}</b
            >, Declaro para devidos fins que estou ciente de que realizarei
            procedimento cirúrgico em vigência da pandemia de infecção pelo
            COVID-19, com potencial risco de infecção, uma vez que a infecção é
            comunitária. Também estou ciente que o procedimento que serei
            submetida <b>{{ procedimento }}</b
            >, em riscos anestésico cirúrgicas, tendo potencial necessidade de
            internação em unidade de terapia intensiva (U.T.I.), que, no momento
            estão sendo utilizadas para pacientes portadores de COVID-19 (CORONA
            VÍRUS). Portando, estou ciente de que, em caso de internação em
            U.T.I., estarei em um ambiente sem isolamento e em contato próximo
            com pacientes com COVID -19. Tendo alto risco de contaminação por
            este vírus.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_tres'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <div class="d-flex flex-row justify-space-between">
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-1">Assinatura Paciente/ Responsável</span><br />
                <span class="ml-1"> CPF: {{ cpf }}</span>
              </p>
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-3">Testemunha</span><br />
                CPF:______________________________
              </p>
            </div>
          </div> -->
        </div>
        <!-- DOC ENCAMINHAMENTO -->
        <div
          class="d-flex flex-column"
          v-else-if="htmlToPdf == 'encaminhamento'"
        >
          <!-- <p class="resp">{{ nomeMedico }}</p>
          <p class="resp">
            Encaminho o pet <b>{{ pet }}</b
            >, de tutor(a) <b>{{ nome }}</b
            >, para avaliação e conduta que julgar necessária.
          </p>
          <p class="ml-auto">Agradeço a atenção.</p> -->
        </div>
      </div>
      <!-- <div id="footer" v-if="htmlToPdf != 'receita-carbonada'">
        <img src="@/assets/images/footer.jpg" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Atestado from "@/components/documentos/atestado.vue";
import Encaminhamento from "@/components/documentos/encaminhamento.vue";
import Exames from "@/components/documentos/exames.vue";
import Receita from "@/components/documentos/receita.vue";
import ReceitaCarbonada from "@/components/documentos/receita_carbonada.vue";
import Termos from "@/components/documentos/termos.vue";
import Galerias from "@/components/prontuarios/galeria.vue";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import UploadExames from "@/components/prontuarios/uploadExames.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import ModalComandas from "@/components/pacientes/modal_comandas_teste.vue";
import ModalNovaAnaminese from "@/components/recepcao_medico/infoChamada/nova_anaminese";
import ProfissionalService from "@/services/profissional_service.js";
// import ConsultaService from "@/services/consulta_service.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import PetsService from "../services/pets_service.js";
import PacienteService from "../services/paciente_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    Prontuarios,
    Galerias,
    Arquivo,
    Atestado,
    Exames,
    Encaminhamento,
    Receita,
    ReceitaCarbonada,
    Termos,
    FotosClassificadas,
    UploadExames,
    ModalNovaAnaminese,
    ModalComandas,
  },
  beforeMount() {
    if (this.$route.query.search) {
      this.search = this.$route.params.search;
    }
    if (this.$router.params.paciente) {
      this.buscarEspecifico = true;
    }
  },

  data: () => ({
    usuarioNome: "",
    unidade: "",
    rederComp: 0,
    dialog: false,
    loading: false,
    dialogLoading: false,
    search: "",
    searchId: "",
    totalList: 10,
    buscarEspecifico: false,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Id Pet",
        align: "start",
        sortable: true,
        value: "id_pet",
      },
      { text: "Pet", value: "nome_pet" },
      {
        text: "Tutor",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    dialogCadastro: false,
    dialogGaleria: false,
    dialogFotosClassificadas: false,
    dialogUploadExames: false,
    dialogAddAnaminese: false,
    dialogComandas: false,
    petIdComanda: {},
    paciente: {},
    visualizar: false,
    pacientes: [],
    prontuarios: [],
    htmlToPdf: "",
    // Documentos
    arquivos: [],
    dialogArquivo: false,
    crmv: "",
    // Atestado
    dialogAtestado: false,
    nome: "",
    pet: "",
    tipoDeclaracao: "",
    //Encaminhamento
    dialogEncaminhamento: false,
    nomeMedico: "",
    //Solicitar Exame
    dialogExames: false,
    exames: [],
    obs: "",
    obs2: "",
    //Receita Nova
    dialogReceita: false,
    receita: "",
    //Receita Carbonada
    dialogReceitaCarbonada: false,
    tutor: {},
    logradouro: "",
    numero: "",
    bairro: "",
    cidade: "",
    cep: "",
    telefone: "",
    //Termos
    dialogTermo: false,
    tipoTermo: "",
    procedimento: "",
    cpf: "",
    pacienteService: "",
    petService: PetsService.build(),
    convenioPublico: true,
    //Prontuarios
    prontuariosInfo: "",
    preencherDadosTutor: false,
    infoAnamnese: {
      TutorCodigo: "",
      PetCodigo: "",
    },
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
  }),

  computed: {
    nomeHospitalConvenio() {
      return "Centro de Olhos Veterinário";
    },
    dataAtual() {
      return new Date().toLocaleDateString();
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarListaPacientes();
      }
      if (value.length == 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarListaPacientes();
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaPacientes();
      },
      deep: true,
    },
  },

  mounted() {
    this.usuarioNome = JSON.parse(
      sessionStorage.getItem("vuex")
    ).usuario.dados.nome;
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade.nome;
    this.unidade = this.unidade.toUpperCase();
    this.crmv = JSON.parse(sessionStorage.getItem("vuex")).perfil.documento;
    this.informacoes_unidade = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade;
    this.data = new Date();
    this.pacienteService = new PacienteService();
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      this.atualizarListaPacientes();
    }
    this.atualizarListaPacientes();
  },
  methods: {
    forceRender() {
      this.rederComp += 1;
    },
    atualizarListaPacientes(filterId) {
      const profissionalService = new ProfissionalService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;

          this.pacientes = body.data.pets.map((paciente) => {
            return paciente;
          });

          if (this.buscarEspecifico) {
            this.pacientes = this.pets.filter(
              (paciente) => paciente.id == this.$router.params.paciente.id
            );
          }
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      // this.search = this.buscarEspecifico
      //   ? this.$router.params.paciente.nome
      //   : this.search;

      profissionalService.getPacienteList(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        filterId ? this.searchId : this.search,
        filterId,
        this.options.ordenacao,
        this.options.desc
      );

      if (this.buscarEspecifico) {
        this.buscarEspecifico = false;
      }
    },
    getTutor(idPaciente) {
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.tutor = body.data;
          console.log(this.tutor);
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {};
      this.pacienteService.get({ onSucess, onError, onEnd }, idPaciente);
    },
    async gerarAtestado(item) {
      this.htmlToPdf = "";
      console.log(item);
      await this.getTutor(item.id_paciente);
      this.pet = item.nome_pet;
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogAtestado = true;
    },
    gerarEncaminhamento(item) {
      this.htmlToPdf = "";
      this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        idade: this.calcularIdade(item.data_nascimento),
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "macho" : "fêmea",
        peso: item.peso || "Não cadastrado",
      };
      this.nome = item.nome;
      this.dialogEncaminhamento = true;
      this.htmlToPdf = "encaminhamento";
    },
    async gerarSolicitacaoDeExame(item) {
      console.log(item);
      this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        idade: this.calcularIdade(item.data_nascimento),
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "macho" : "fêmea",
        peso: item.peso || "Não cadastrado",
      };
      this.nome = item.nome;
      this.htmlToPdf = "";
      // this.pet = item.nome_pet;
      // this.nome = item.nome;
      this.dialogExames = true;
    },
    async gerarReceitasNovas(item) {
      this.htmlToPdf = "";
      await this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        idade: this.calcularIdade(item.data_nascimento),
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "macho" : "fêmea",
        peso: item.peso || "Não cadastrado",
      };
      this.nome = item.nome;
      this.htmlToPdf = "receita";
      this.dialogReceita = true;
    },
    calcularIdade(dataNascimento) {
      if (!dataNascimento) {
        return 0;
      }
      const dataAtual = new Date();
      const dataNascimentoPet = new Date(dataNascimento);

      let anos = dataAtual.getFullYear() - dataNascimentoPet.getFullYear();
      let meses =
        anos * 12 + (dataAtual.getMonth() - dataNascimentoPet.getMonth());

      if (meses < 12) {
        return `${meses} meses`;
      } else {
        return `${Math.floor(meses / 12)} anos e ${meses % 12} meses`;
      }
    },
    async gerarReceitasCarbonada(item) {
      console.log(item);
      await this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "macho" : "fêmea",
        peso: item.peso || "Não cadastrado",
      };
      this.nome = item.nome;
      this.htmlToPdf = "";
      this.dialogReceitaCarbonada = true;
    },
    async gerarTermos(item) {
      console.log(item);
      this.htmlToPdf = "";
      await this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "Macho" : "Fêmea",
        peso: item.peso || "Não cadastrado",
        petCodico: item.id_pet,
        cor: item.cor,
        nascimento: item.data_nascimento
          .split("-")
          .reverse()
          .join("/"),
      };
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogTermo = true;
      setTimeout(() => {
        console.log(this.tutor);
      }, 1000);
    },
    async getTutorByPacienteId(idCliente) {
      await this.pacienteService
        .getPacienteByClienteId(idCliente)
        .then((resp) => {
          this.tutor = resp.data;
        });
    },
    async showComandasPet(item) {
      this.petIdComanda = item.id_pet;
      await this.getTutorByPacienteId(item.id_cliente);
      this.dialogComandas = true;
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },

    gerarPDF(info) {
      let content = "";
      // this.convenioPublico = info.convenio == "publico" ? true : false;
      this.convenioPublico = info.convenio == "centro de olhos" ? true : false;
      switch (info.doc) {
        case "atestado":
          if (info.tipo == "declaração de horas") {
            this.preencherDadosTutor = info.preencherDadosTutor;
          }
          this.htmlToPdf = info.doc;
          this.tipoDeclaracao = info.tipo;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Declaração",
              "padrao"
            );
          });
          break;
        case "termo":
          this.htmlToPdf = info.doc;
          this.tipoTermo = info.termo_tipo;
          this.procedimento = info.procedimento;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, info.termo, "padrao");
          });
          break;
        case "receita":
          // this.htmlToPdf = info.doc;
          document.getElementById("intensReceitaTeste").innerHTML =
            info.receita;
          this.receitas = document.getElementById(
            "intensReceitaTeste"
          ).children;
          this.receitas.forEach((item) => {
            // item.innerText = item.innerText.replaceAll("_", ".");
            // console.log(item.innerText);
            item.style.cssText =
              "margin: 3px; font-size: 4px; width: 97%; font-family: Times, Times New Roman, serif;";
          });
          // this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Receita", "padrao");
          });
          break;
        case "receita-carbonada":
          // console.log(info.doc);
          this.htmlToPdf = info.doc;
          this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Receita-carbonada",
              "padrao"
            );
          });
          break;
        case "encaminhamento":
          // this.nomeMedico = info.nomeMedico;
          content = info.content.replaceAll(
            'class="ql-align-right"',
            'style="text-align: end;"'
          );
          content = info.content.replaceAll(
            "<p>",
            '<p style="margin-top: -10px;">'
          );

          document.getElementById("corpoEncaminhamento").innerHTML = content;
          document.getElementById("corpoEncaminhamento").style.cssText =
            "font-family: Times, Times New Roman, serif; line-height: 75%; margin-top: 10px";

          // console.log(document.getElementById("corpoEncaminhamento").innerHTML);
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Encaminhamento",
              "padrao"
            );
          });
          break;
        case "exames":
          this.htmlToPdf = info.doc;
          this.exames = info.exames;
          this.obs = info.obs;
          this.obs2 = info.obs2;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Solicitação de exames",
              "padrao"
            );
          });
          break;

        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      // console.log(this.htmlToPdf);
      // console.log(content);
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },

    async listProntuarios(item) {
      this.paciente = item;
      this.dialogLoading = true;
      await this.petService
        .getConsultasbyUnidade(
          item.id_pet,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        // .getConsultas(item.id_pet)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          console.log(data);
          this.prontuarios = data.data.consultas;
          this.prontuariosInfo = item;
          this.dialog = true;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    anamneseAdicional(item) {
      if (item.id_cliente != 0 && item.id_pet != 0) {
        this.infoAnamnese.TutorCodigo = item.id_cliente;
        this.infoAnamnese.PetCodigo = item.id_pet;
        this.infoAnamnese.NomeTutor = item.nome;
        this.infoAnamnese.NomePet = item.nome_pet;
        this.dialogAddAnaminese = true;
      } else {
        this.dialogAddAnaminese = false;
      }
    },
    teste() {
      console.log("Teste aqui");
    },
    listFile(item) {
      this.paciente = item;
      this.dialogArquivo = true;
      this.forceRender();
    },

    listUpload(item) {
      console.log(item);
      this.paciente = item;
      this.dialogUploadExames = true;
      this.forceRender();
    },

    listGalerias(item) {
      this.paciente = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas(item) {
      this.paciente = item;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      this.forceRender();
    },

    searchPetById() {
      console.log(this.searchId);
      this.options.page = 1;
      if (this.searchId > 1) {
        this.atualizarListaPacientes(true);
      } else {
        this.atualizarListaPacientes();
      }
    },
  },
};
</script>
<style scoped>
#examesPDF {
  text-align: center;
}

p {
  letter-spacing: 0.3px;
}

.break-w {
  word-wrap: break-word;
}

strong {
  letter-spacing: 0.5px !important;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header img {
  width: 2rem;
}
.header-receita {
  margin: 0 !important;
  padding: 0 !important;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.carbon {
  width: 100vw !important;
  font-size: 3px;
}

.carbon .body {
  min-height: 30vh !important;
}
.carbon .rodape {
  min-height: 70vh !important;
  position: static !important;
  bottom: 0 !important;
}

.carbon p,
.carbon hr {
  margin: 0 !important;
  padding: 0 !important;
}
.gap {
  gap: 1px;
}
.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.zeroEspaco {
  padding: 0 !important;
  margin: 0 !important;
}
.marg-2 {
  margin-left: 0.7rem !important;
  margin-right: 0.7rem !important;
}
.indent {
  text-indent: 5px;
}
.termo {
  font-size: 3.5px;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
}
.color-black {
  color: black;
}
.rodape {
  position: fixed;
  bottom: 0;
}
.termo .small {
  line-height: 4px !important;
  letter-spacing: 0.2px !important;
  font-size: 3.2px;
}
.listaIntensReceita {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  color: brown !important;
}
</style>
