<template>
  <v-card>
    <span v-if="renderLoading">
      <v-card-title>
        <span class="headline">Edição de médico</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-circular size="50" indeterminate color="primary" />
        </v-container>
      </v-card-text>
    </span>

    <div v-else>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Edição de Médico</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <div class="p-5">
        <form class="p-lg-3 row">
          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Nome</label>
            <v-text-field
              v-model="medicoNome"
              placeholder="Seu nome completo"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">E-mail</label>
            <v-text-field
              v-model="email"
              :rules="[rules.required]"
              placeholder="E-mail"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Senha</label>
            <v-text-field
              v-model="senha"
              :rules="[rules.required]"
              placeholder="Senha"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">CPF</label>
            <v-text-field
              v-model="cpf"
              :rules="[rules.required]"
              placeholder="CPF"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">RG</label>
            <v-text-field
              v-model="rg"
              :rules="[rules.required]"
              placeholder="RG"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">CRMV</label>
            <v-text-field
              v-model="documento"
              :rules="[rules.required]"
              placeholder="CRMV"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-2 form-group clearfix mb-3">
            <label for="nome">Gênero</label>
            <v-radio-group :rules="[rules.required]" v-model="genero">
              <v-radio label="Masculino" value="M" />
              <v-radio label="Feminino" value="F" />
            </v-radio-group>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Profissão</label>
            <v-text-field
              v-model="profissao"
              :rules="[rules.required]"
              placeholder="Profissão"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Estado civil</label>
            <v-text-field
              v-model="estado_civil"
              :rules="[rules.required]"
              placeholder="Estado civil"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Nascimento</label>
            <v-text-field
              v-model="data_nascimento"
              :rules="[rules.required]"
              placeholder="Nascimento"
              style="padding-top: 0 !important"
            >
            </v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Telefone 1</label>
            <v-text-field
              v-model="telefone_1"
              :rules="[rules.required]"
              placeholder="Telefone"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Telefone 2</label>
            <v-text-field
              v-model="telefone_2"
              placeholder="Telefone"
              v-mask="'(##)####-####'"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">CEP</label>
            <v-text-field
              v-mask="'#####-###'"
              v-model="cep"
              @blur="searchCep"
              :rules="[rules.required]"
              placeholder="CEP"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Logradouro</label>
            <v-text-field
              v-model="logradouro"
              :rules="[rules.required]"
              placeholder="Logradouro"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Número</label>
            <v-text-field
              v-model="numero"
              :rules="[rules.required]"
              placeholder="Número"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Complemento</label>
            <v-text-field
              v-model="complemento"
              placeholder="Complemento"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Referência</label>
            <v-text-field
              v-model="referencias"
              placeholder="Referência"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Bairro</label>
            <v-text-field
              v-model="bairro"
              :rules="[rules.required]"
              placeholder="Bairro"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Cidade</label>
            <v-text-field
              v-model="cidade"
              :rules="[rules.required]"
              placeholder="Cidade"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Estado</label>
            <v-text-field
              v-model="estado"
              :rules="[rules.required]"
              placeholder="Estado"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
          <div class="col-lg-4 form-group clearfix mt-n3">
            <label for="">Unidades</label>
            <v-combobox
              v-model="unidadesSelecionadas"
              :items="unidades"
              label="Selecione a Unidade"
              item-text="text"
              item-value="value"
              multiple
            ></v-combobox>
          </div>

          <div
            class="col-lg-3 form-group clearfix"
            style="padding-top: 0 !important"
          >
            <label>Status</label>
            <v-select
              :items="statusSelect"
              item-text="descricao"
              item-value="id"
              v-model="ativo"
            />
          </div>

          <div
            class="col-lg-3 form-group clearfix"
            style="padding-top: 0 !important"
          >
            <label>Tipo Médico</label>
            <v-select
              :items="tipoMedicoList"
              item-text="descricao"
              item-value="id"
              v-model="tipoMedico"
            />
          </div>

          <div class="col-md-12 text-right">
            <button
              @click.prevent="salvarEdicao"
              class="btn btn-app-primary btn-rounded font-weight-bold"
            >
              <span v-if="!loading"> Salvar Edição </span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </form>
      </div>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import MedicosService from "../../services/medico_service";
import UnidadeService from "../../services/unidade_service";
// import PictureInput from "vue-picture-input";

export default {
  mixins: [Mixin],
  components: {
    // PictureInput,
  },
  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    medicoNome: "",
    image: "",
    email: "",
    rg: "",
    genero: "",
    data_nascimento: "",
    profissao: "",
    estado_civil: "",
    cep: "",
    numero: "",
    complemento: "",
    referencias: "",
    cpf: "",
    documento: "",
    telefone_1: "",
    telefone_2: "",
    bairro: "",
    cidade: "",
    estado: "",
    logradouro: "",
    senha: "",
    renderLoading: false,
    itemsDataTable: [],
    unidades: [],
    unidadesSelecionadas: [],
    services: {
      unidadeService: UnidadeService.build(),
    },
    statusSelect: [
      { descricao: "Ativo", id: 1 },
      { descricao: "Inativo", id: 2 },
    ],
    ativo: 0,
    tipoMedicoList: [
      { descricao: "Padrão", id: 2 },
      { descricao: "Sênior", id: 1 },
    ],
    tipoMedico: 1,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    medicoService: MedicosService.build(),
  }),
  props: {
    medicoId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    unidadesSelecionadas() {
      console.log(this.unidadesSelecionadas);
    },
  },
  async beforeMount() {
    await this.setUnidades();
    this.renderLoading = true;
    this.formCadastro = document.forms[0];
    await this.getMedico();
    this.renderLoading = false;
  },
  methods: {
    async setUnidades() {
      await this.services.unidadeService
        .getUnidadeList()
        .then((response) => {
          console.log("response");
          console.log(response);
          this.unidades = response.map((u) => ({
            value: u.unidadeId,
            text: u.nomeFantasia,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    async getMedico() {
      const response = await this.medicoService.getMedico(this.medicoId);
      const { data } = await response.json();
      console.log("data");
      console.log(data);
      this.unidadesSelecionadas = data.clinica.map((unidade) => {
        return {
          value: unidade.id_unidade,
          text: unidade.descricao_unidade,
        };
      });
      this.ativo = data.ativo == null ? 1 : parseInt(data.ativo);
      this.tipoMedico = data.tipoMedico == null ? 1 : parseInt(data.tipoMedico);
      this.medicoNome = data.nome;
      this.email = data.email;
      this.rg = data.rg;
      this.documento = data.documento;
      this.genero = data.genero;
      this.data_nascimento = data.data_nascimento;
      this.profissao = data.profissao;
      this.estado_civil = data.estado_civil;
      this.cep = data.cep;
      this.numero = data.numero;
      this.complemento = data.complemento;
      this.referencias = data.referencias;
      this.cpf = data.cpf;
      this.telefone_1 = data.telefone_1;
      this.telefone_2 = data.telefone_2;
      this.bairro = data.bairro;
      this.cidade = data.cidade;
      this.estado = data.estado;
      this.logradouro = data.logradouro;
      this.senha = data.senha ?? "";
    },
    tooglePermission(permissao) {
      const permissaoToEdit = this.pet.permissoes.find(
        ({ permissaoId }) => permissao.permissaoId === permissaoId
      );
      permissaoToEdit.ativo = !permissaoToEdit.ativo;
    },
    async salvarEdicao() {
      this.loading = true;
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("ativo", this.ativo);
        formData.append("senior", this.tipoMedico);
        formData.append("foto", this.image);
        formData.append("nome", this.medicoNome);
        formData.append("email", this.email);
        formData.append("rg", this.rg);
        formData.append("documento", this.documento);
        formData.append("genero", this.genero);
        formData.append("data_nascimento", this.data_nascimento);
        formData.append("profissao", this.profissao);
        formData.append("estado_civil", this.estado_civil);
        formData.append("cep", this.cep);
        formData.append("numero", this.numero);
        formData.append("complemento", this.complemento);
        formData.append("referencias", this.referencias);
        formData.append("cpf", this.cpf);
        formData.append("telefone_1", this.telefone_1);
        formData.append("telefone_2", this.telefone_2);
        formData.append("bairro", this.bairro);
        formData.append("cidade", this.cidade);
        formData.append("estado", this.estado);
        formData.append("logradouro", this.logradouro);
        formData.append("senha", this.senha);
        formData.append(
          "unidade",
          this.unidadesSelecionadas.map((u) => u.value).toString()
        );
        const response = await this.medicoService.editarMedico(
          this.medicoId,
          formData
        );
        this.$_ACTIONS_showSnackbarMessage({
          message:
            response.status === 200
              ? "O médico foi atualizado com sucesso."
              : this.$global.messages.internalServerError,
          color: response.status === 200 ? "sucess" : "error",
        });
        response.status === 200 && this.$emit("response", this.pet);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
  },
  trocarImagem() {
    if (this.$refs.pictureInput) {
      this.avatarPet = this.$refs.pictureInput.file;
      this.image = this.$refs.pictureInput.image;
    } else {
      console.log("API File Reader não suportada: use o <form>");
    }
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
