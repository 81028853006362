<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">
        Cadastro de procedimento
      </h3>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="descricao"
              outlined
              label="Nome"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- CAMPO TIPO DE PROCEDIMENTO -->
            <v-select
              v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
              :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
              :loading="TIPO_PROCEDIMENTO_MIXIN_loading"
              item-text="descricao"
              item-value="id"
              label="Tipo de procedimento"
              color="black"
              outlined
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento == 10">
          <v-col cols="12" class="pt-0">
            <h5 style="color: red">
              Lembrando que o Tipo de procedimento:
              <strong>Exame - Laudo</strong>, necessita de um laudo, com isso
              ele será criado junto nesse processo.
            </h5>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12"> -->
        <!-- CAMPO EXAMES -->
        <!-- <v-select
              v-model="exames_procedimento"
              :items="exames"
              item-text="nome"
              item-value="id"
              outlined
              chips
              label="exames"
              multiple
            ></v-select>
          </v-col>
        </v-row> -->
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-5 px-8">
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn dark class="bg-verde" :loading="loading" @click="cadastrar">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin.js";
import ExameService from "@/services/exame_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin, TipoProcedimentoMixin],
  beforeMount() {
    this.lista_exames();
  },
  mounted() {
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista();
  },
  data: () => ({
    tipo_procedimento: "",
    tipos_procedimentos: [],
    descricao: "",
    loading: false,

    exames_procedimento: [],
    exames: [],
    loading_exames: false,
  }),
  methods: {
    cadastrar() {
      if (!this.descricao || !this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        return;
      }

      const procedimento = {
        descricao: this.descricao,
        id_tipo_procedimento: this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
        criado_por: this.$_GETTERS_usuario.id,
        exames: this.exames_procedimento,
      };
      const procedimentoService = new ProcedimentoService();
      this.loading = true;

      if (this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento == 10) {
        procedimentoService.cadastrarProcedimentoLaudo(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                procedimento.id = body.data.id_new_procedimento;
              }
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: status === 200 ? "sucess" : "error",
              });
              this.$emit("response", procedimento);
            },
            onError: (error) => {
              console.error(error);
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            },
            onEnd: () => {
              this.loading = false;
            },
          },
          procedimento
        );
      } else {
        procedimentoService.cadastrar(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                procedimento.id = body.data.id_new_procedimento;
              }
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: status === 200 ? "sucess" : "error",
              });
              this.$emit("response", procedimento);
            },
            onError: (error) => {
              console.error(error);
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            },
            onEnd: () => {
              this.loading = false;
            },
          },
          procedimento
        );
      }
    },
    lista_exames() {
      this.loading_exames = true;
      const exameService = new ExameService();
      exameService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.exames = body.data.exames;
          } else {
            this.exames = [];
          }
        },
        onError: (error) => {
          console.error(error);
          this.exames = [];
        },
        onEnd: () => {
          this.loading_exames = false;
        },
      });
    },
  },
};
</script>
