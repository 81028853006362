<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Formulários
        </h3>
      </div>
    </div>

    <v-data-table
      :search="search"
      :headers="headers"
      :items="forms"
      sort-by="titulo"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            v-model="dialogNovoFormulario"
            max-width="500px"
            fullscreen
            persistent
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowNovoFormularioBtn"
                dark
                class="mb-2 bg-verde"
                rounded
                v-bind="attrs"
                v-on="on"
              >
                Novo Formulário
              </v-btn>
            </template>
            <v-card>
              <v-toolbar class="bg-verde" dark>
                <v-btn icon dark @click="dialogNovoFormulario = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Novo formulário</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <formulario-novo
                v-if="dialogNovoFormulario"
                @close="close"
                @response="atualizarListaForms"
              />
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <formulario-edit-show
              v-if="dialog"
              :formulario="formulario"
              @close="dialog = false"
              @response="atualizarListaForms"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-dialog v-model="dialogConfirmDesativar" max-width="500px">
          <dialog-confirmacao-desativar
            v-if="modalConfirmDesativar"
            :form="formulario"
            @cancel="
              () => {
                modalConfirmDesativar = false;
                dialogConfirmDesativar = false;
              }
            "
            @confirm="desativarForm"
          />
        </v-dialog>
        <v-icon
          v-if="allowDeletarBtn"
          small
          class="mr-2"
          @click="confirm(item)"
        >
          mdi mdi-delete
        </v-icon>
        <v-icon
          v-if="allowVisualizarBtn"
          small
          class="mr-2"
          @click="editItem(item, true)"
        >
          mdi mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FormularioEditShow from "@/components/formularios/formulario_edit_show.vue";
import FormularioNovo from "@/components/formularios/formulario_novo.vue";
import DialogConfirmacaoDesativar from "@/components/formularios/dialog_confirmacao_desativar.vue";
import FormularioService from "@/services/formulario_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    FormularioEditShow,
    FormularioNovo,
    DialogConfirmacaoDesativar,
  },
  data: () => ({
    dialog: false,
    loading: false,
    search: "",
    dialogNovoFormulario: false,
    dialogEditeFormulario: false,
    dialogConfirmDesativar: false,
    modalConfirmDesativar: false,
    formulario: {},
    visualizar: false,
    forms: [],
    headers: [
      {
        text: "Descrição",
        align: "start",
        sortable: true,
        value: "titulo",
      },
      { text: "Qtd. perguntas", value: "quantidade_perguntas" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      titulo: "",
      desccricao: "",
      quantidade_perguntas: "",
    },
    defaultItem: {
      titulo: "",
      desccricao: "",
      quantidade_perguntas: "",
    },
    services: {
      authService: AuthService.build(),
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.atualizarListaForms();
  },

  computed: {
    allowNovoFormularioBtn() {
      return this.services.authService.temPermissao(
        endpoints["FormularioController::POST->registerFormulario"]
      );
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(
        endpoints["FormularioController::POST->desativaFormulario/$1"]
      );
    },
    allowVisualizarBtn() {
      return this.services.authService.temPermissao(
        endpoints["FormularioController::GET->getFormularioByID/$1"]
      );
    },
  },

  methods: {
    atualizarListaForms() {
      this.loading = true;
      this.forms = [];
      const formularioService = new FormularioService();
      formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              body.data.formularios.forEach((form) => {
                let formulario = {};
                (formulario.id = form.id), (formulario.titulo = form.titulo);
                formulario.descricao = form.descricao;
                formulario.quantidade_perguntas = form.quantidade_perguntas;
                this.forms.push(formulario);
              });
            } else {
              if (status != 206 && status != 204) {
                this.$_ACTIONS_showSnackbarMessage({
                  message:
                    body.message || this.$global.messages.internalServerError,
                  color: status === 200 ? "sucess" : "error",
                });
              }
            }
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        { ativo: 1 }
      );
    },

    editItem(item) {
      // this.editedIndex = this.forms.indexOf(item);
      // this.editedItem = item;
      this.formulario = item;
      this.dialogEditeFormulario = true;
      this.dialog = true;
    },

    desativarForm() {
      this.modalConfirmDesativar = false;
      this.dialogConfirmDesativar = false;
      this.atualizarListaForms();
    },
    confirm(item) {
      this.formulario = item;
      this.dialogConfirmDesativar = true;
      this.modalConfirmDesativar = true;
      this.atualizarListaForms();
    },
    close() {
      this.dialog = false;
      if (this.dialogEditeFormulario == true) {
        this.atualizarListaForms();
        this.dialogEditeFormulario = false;
      } else {
        this.atualizarListaForms();
        this.dialogNovoFormulario = false;
      }
    },
    // save(event) {
    //   console.log(event);
    //   if (this.editedIndex > -1) {
    //     Object.assign(this.pacientes[this.editedIndex], this.editedItem);
    //   } else {
    //     this.pacientes.push(this.editedItem);
    //   }
    //   this.close();
    // },
  },
};
</script>
