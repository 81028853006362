<template>
  <v-card>
    <v-toolbar dark style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Pagamento</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        @click="pagarItens"
        color="white"
        class="text-green"
        :disabled="!validarPagamento()"
      >
        <v-icon left dark>
          mdi-cash-register
        </v-icon>
        <strong style="margin-top: 2px;">Pagar Itens</strong>
      </v-btn>
    </v-toolbar>
    <div class="container-fluid mt-6">
      <v-dialog persistent v-model="dialogPagamento" max-width="750px">
        <modal-forma-pagamento
          v-if="dialogPagamento"
          @close="dialogClose()"
          @response="responsePagamento($event)"
          :infoComanda="{
            valorPagar: valorPagar,
            valorFaltaPagar: valorPagar - totalPago,
            formasdePagamento: formasdePagamento,
          }"
          :userCreditos="creditos"
          :pagamento="pagamento"
          :editar="editarPagamento"
          :pagamentoIndex="pagamentoIndex"
        />
      </v-dialog>
      <div class="row px-4">
        <div class="col-6">
          <v-card elevation="2">
            <v-card-title class="pb-1">
              Detalhes
            </v-card-title>
            <v-simple-table dense height="215px" fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Procedimento
                    </th>
                    <th class="text-left">
                      Tipo Procedimento
                    </th>
                    <th class="text-left">
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in comandas" :key="item.name">
                    <td class="text-left">{{ item.procedimento_descricao }}</td>
                    <td class="text-left">
                      {{ item.tipo_procedimento_descricao }}
                    </td>
                    <td class="text-left">
                      {{ formatarSaldo(item.valor_original) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
        <div class="col-6">
          <v-card elevation="2">
            <v-card-title class="pb-1">Desconto</v-card-title>
            <div class="container pb-0">
              <div class="row">
                <div class="col-4">
                  <v-select
                    :items="listaDescontos"
                    v-model="desconto"
                    item-text="desc"
                    item-value="id"
                    label="Tipo Desconto"
                  ></v-select>
                </div>
                <div class="col-4 pt-6" v-if="desconto == 2">
                  <v-text-field
                    v-model="descontoPercent"
                    :disabled="desconto != 2"
                    @keydown="verificarTecla($event)"
                    type="number"
                    step="0.01"
                    min="0"
                    max="100"
                    label="Percentual"
                    style="padding-top: 0 !important"
                  ></v-text-field>
                </div>
                <div class="col-4 pt-6" v-if="desconto == 3">
                  <v-text-field
                    v-model="descontoValor"
                    v-money="moneyConfig"
                    @keydown="verificarTecla($event)"
                    type="text"
                    step="0.01"
                    min="0"
                    label="Valor"
                    style="padding-top: 0 !important"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <v-divider> </v-divider>
            <v-card-title class="pb-1 pt-0">
              Resumo
            </v-card-title>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Valor
                    </th>
                    <th class="text-left">
                      Desconto
                    </th>
                    <th class="text-left">
                      Desc. Percentual
                    </th>
                    <th class="text-left">
                      Desc. Valor
                    </th>
                    <th class="text-left">
                      Valor à Pagar
                    </th>
                    <th class="text-left">
                      Falta Pagar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">{{ formatarSaldo(valorTotal) }}</td>
                    <td class="text-left">{{ tipoDesconto(desconto) }}</td>
                    <td class="text-left">{{ descontoPercent }}%</td>
                    <td class="text-left" v-if="desconto == 3">
                      {{ getDesconto(descontoValor) }}
                    </td>
                    <td class="text-left" v-else>
                      {{ formatarSaldo(descontoValor) }}
                    </td>
                    <td class="text-left">{{ formatarSaldo(valorPagar) }}</td>
                    <td class="text-left">
                      {{ formatarSaldo(valorPagar - totalPago) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </div>
      <div class="row px-4">
        <div class="col-12">
          <v-card elevation="2" class="px-5 py-5">
            <div class="d-flex justify-content-between">
              <div>
                <h3 class="text-left">Pagamentos</h3>
              </div>
              <div>
                <v-btn
                  @click="novoPagamento"
                  color="#3651ba"
                  class="ma-2 white--text"
                >
                  <v-icon left dark>
                    mdi-plus
                  </v-icon>
                  <strong style="margin-top: 2px;">Pagamento</strong>
                </v-btn>
              </div>
            </div>
            <v-divider class="mt-0"></v-divider>
            <v-simple-table dense height="180px" fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Pagamento
                    </th>
                    <th class="text-left">
                      Valor
                    </th>
                    <th class="text-left">
                      Recebido
                    </th>
                    <th class="text-left">
                      Troco
                    </th>
                    <th class="text-left" style="width: 100px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in pagamentos" :key="index">
                    <td class="text-left">{{ item.tipoPagDec }}</td>
                    <td class="text-left">
                      {{ item.valorFormatada }}
                    </td>
                    <td class="text-left">
                      {{
                        item.valorRecebidoFormatada
                          ? item.valorRecebidoFormatada
                          : item.valorFormatada
                      }}
                    </td>
                    <td class="text-left">
                      {{
                        item.valorTrocoFormatada
                          ? item.valorTrocoFormatada
                          : "R$ 0,00"
                      }}
                    </td>
                    <td class="text-left">
                      <div class="d-flex gap-3">
                        <v-btn
                          class="mx-2"
                          icon
                          dark
                          x-small
                          color="#009f6b"
                          title="editar"
                          @click="editarItem(item, index)"
                        >
                          <v-icon dark>
                            mdi-pen
                          </v-icon>
                        </v-btn>
                        <v-btn
                          class="mx-2"
                          icon
                          dark
                          x-small
                          color="#a93434"
                          title="Excluir"
                          @click="excluirItem(index)"
                        >
                          <v-icon dark>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style scoped>
.v-text-field__details {
  display: none !important;
}
</style>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
// import ComandaService from "@/services/comanda_service.js";
//import FormaPagamentoService from "../../services/forma_pagamento_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import FormaPagamentoService from "../../services/forma_pagamento_service";
import CreditoClienteService from "../../services/credito_cliente_service";
import ModalFormaPagamento from "@/components/pacientes/modal_forma_pagamento.vue";
import { VMoney } from "v-money";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  components: { ModalFormaPagamento },
  directives: { money: VMoney },
  data: () => ({
    valorTotal: 0,
    valorPagar: 0,
    totalPago: 0,
    desconto: 1,
    descontoPercent: 0,
    descontoValor: 0,
    listaDescontos: [
      { id: 1, desc: "Sem Desconto" },
      { id: 2, desc: "Percentual" },
      { id: 3, desc: "Valor" },
    ],
    moneyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: true,
    },
    formaPagamentoService: FormaPagamentoService.build(),
    formasdePagamento: [],
    creditosCliente: [],
    checkCreditoCliente: [],
    // Pagamentos
    pagamentos: [],
    pagamento: {},
    pagamentoIndex: -1,
    dialogPagamento: false,
    editarPagamento: false,
    creditos: [],
    creditoInvalido: [],
    checkCredito: [],
    checkGerarNovoCredito: [],
  }),
  props: {
    comandas: {
      type: Array,
      required: true,
    },
  },
  watch: {
    desconto() {
      this.descontoPercent = 0;
      this.descontoValor = 0;
      if (this.desconto == 1) {
        this.valorPagar = this.valorTotal;
      }
    },
    descontoPercent() {
      if (this.desconto == 2 && this.descontoPercent <= 100) {
        let descontoValor = (this.descontoPercent * this.valorTotal) / 100;
        this.valorPagar = this.valorTotal - descontoValor;
        this.descontoValor = parseFloat(descontoValor).toFixed(2);
      } else if (this.desconto == 2) {
        this.descontoPercent = 100;
      }
    },
    descontoValor() {
      if (this.desconto == 3) {
        if (this.formatValor(this.descontoValor) <= this.valorTotal) {
          this.descontoPercent = 0;
          this.valorPagar =
            this.valorTotal - this.formatValor(this.descontoValor);
          this.descontoPercent = parseFloat(
            (this.formatValor(this.descontoValor) / this.valorTotal) * 100
          ).toFixed(2);
        } else if (this.formatValor(this.descontoValor) > this.valorTotal) {
          this.valorPagar = 0;
        }
      }
    },
    valorRecebidoPagamento() {
      if (this.formaPagamentoSelecionada == 7) {
        let valorTroco =
          this.formatValor(this.valorPagamento) -
          this.formatValor(this.valorRecebidoPagamento);
        this.valorTrocoPagamento = this.formatarSaldo(valorTroco);
      }
    },
  },
  async mounted() {
    this.calcularValor();
    this.getFormasPagamento();
    this.getCreditoCliente();
  },
  computed: {},
  methods: {
    formatValor(valor) {
      if (typeof valor == "string") {
        const newValor = parseFloat(
          valor
            .replaceAll("R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ).toFixed(2);
        return newValor;
      } else {
        return valor;
      }
      // return parseFloat(valor).toFixed(2)
    },
    formatarSaldo(saldo) {
      if (saldo) {
        saldo = saldo.toString().replaceAll(",", "");
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(saldo);
      } else {
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(0);
      }
    },
    tipoDesconto(id) {
      let desconto = this.listaDescontos.find((ele) => {
        return ele.id == id;
      });
      return desconto.desc;
    },
    getTipoPagamentoDesc(id) {
      let desconto = this.formasdePagamento.find((ele) => {
        return ele.value == id;
      });
      return desconto.text;
    },
    verificarTecla(event) {
      if (
        ((event.keyCode >= 65 && event.keyCode <= 90) ||
          (event.keyCode >= 97 && event.keyCode <= 122)) &&
        ![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 188, 190].includes(
          event.keyCode
        )
      ) {
        event.preventDefault();
      }
    },
    calcularValor() {
      let valorTotal = 0;
      for (let index = 0; index < this.comandas.length; index++) {
        const itemComanda = this.comandas[index];
        // valorTotal = valorTotal + parseFloat(itemComanda.valor);
        valorTotal = valorTotal + parseFloat(itemComanda.valor_original);
      }
      this.valorTotal = valorTotal;
      this.valorPagar = valorTotal;
    },
    responsePagamento(pagamento) {
      if (pagamento.index >= 0) {
        this.pagamentos.splice(pagamento.index, 1, pagamento.item);
      } else {
        this.pagamentos.push(pagamento.item);
      }
      this.calcularTotalPago();
      this.limparPagamento();
    },
    limparPagamento() {
      this.pagamento = {};
      this.pagamentoIndex = -1;
    },
    calcularTotalPago() {
      let registroPagamentos = 0;
      for (let index = 0; index < this.pagamentos.length; index++) {
        registroPagamentos =
          parseFloat(registroPagamentos) +
          parseFloat(this.pagamentos[index].valor);
      }
      this.totalPago = registroPagamentos;
    },
    editarItem(item, index) {
      this.pagamento = item;
      this.pagamentoIndex = index;
      this.verificarFormasPagamento();
      this.editarPagamento = true;
      this.dialogPagamento = true;
    },
    excluirItem(index) {
      this.pagamentos.splice(index, 1);
      this.calcularTotalPago();
    },
    dialogClose() {
      this.editarPagamento = false;
      this.dialogPagamento = false;
      this.limparPagamento();
    },
    novoPagamento() {
      this.verificarFormasPagamento();
      this.dialogPagamento = true;
    },
    verificarFormasPagamento() {
      for (let index = 0; index < this.formasdePagamento.length; index++) {
        if (
          this.pagamentos.length > 0 &&
          this.formasdePagamento[index].value != 1 &&
          this.formasdePagamento[index].value != 2 &&
          this.formasdePagamento[index].value != 3
        ) {
          // if (this.pagamentos.length > 0) {
          let indexPagamento = this.pagamentos.findIndex((ele) => {
            return ele.tipoPagId == this.formasdePagamento[index].value;
          });
          if (indexPagamento >= 0) {
            this.formasdePagamento[index].disabled = true;
          } else {
            this.formasdePagamento[index].disabled = false;
          }
        } else {
          this.formasdePagamento[index].disabled = false;
        }
      }
    },
    async getCreditoCliente() {
      const creditoClienteService = new CreditoClienteService();
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
      const clienteId = this.comandas[0].cliente_id;

      const credito = await creditoClienteService.getCreditosCliente(
        unidadeId,
        clienteId
      );
      let creditos = [];
      if (credito.data.creditos) {
        for (let index = 0; index < credito.data.creditos.length; index++) {
          const element = credito.data.creditos[index];
          creditos.push({
            id: element.id,
            valor: element.valor,
            valorUsado: 0,
          });
        }
      }
      this.creditos = {
        creditos: creditos,
        total: credito.data.total_creditos[0].valor,
      };
    },
    async getFormasPagamento() {
      this.formasdePagamento = await this.formaPagamentoService
        .getFormaPagamentoPaginatedList()
        .then((resp) => resp.json())
        .then((resp) => {
          let formasPagamento = [];
          for (
            let index = 0;
            index < resp.data.forma_pagamento.length;
            index++
          ) {
            const element = resp.data.forma_pagamento[index];
            if (element.desativado_por == null) {
              formasPagamento.push({
                text: element.descricao,
                value: element.id,
                codauth: element.codauth,
                disabled: false,
              });
            }
          }
          return formasPagamento;
          // return resp.data.forma_pagamento
          //   .filter((item) => item.desativado_em == null)
          //   .map((item) => {
          //     return {
          //       text: item.descricao,
          //       value: item.id,
          //       codauth: item.codauth,
          //     };
          //   });
        });
    },
    validarPagamento() {
      if (this.valorPagar - this.totalPago == 0) {
        return true;
      } else {
        return false;
      }
    },
    gerarModelo() {
      let comandaId = this.comandas[0].comanda_id;
      let comandaItens = [];
      for (let index = 0; index < this.comandas.length; index++) {
        const comanda = this.comandas[index];
        comandaItens.push({ comanda_item_id: comanda.comanda_item_id });
      }
      let formasPagamento = [];
      for (let index = 0; index < this.pagamentos.length; index++) {
        const element = this.pagamentos[index];
        let pagamento = {};
        switch (element.tipoPagId) {
          case "1" /* Credito */:
            pagamento = {
              card: {
                ano_val: "",
                bandeira: "",
                codauth: element.codAut,
                cvv: "",
                debcred: "credito",
                maquineta: element.maquineta,
                mes_val: "",
                number: "",
                titular: "",
              },
              dinheiro: {
                troco: 0,
                valor: 0,
              },
              forma_id: element.tipoPagId,
              observacao: element.observacao,
              pix: {
                chave: "",
              },
              valor: parseFloat(element.valor),
            };
            break;
          case "2" /* Debito */:
            pagamento = pagamento = {
              card: {
                ano_val: "",
                bandeira: "",
                codauth: element.codAut,
                cvv: "",
                debcred: "debito",
                maquineta: element.maquineta,
                mes_val: "",
                number: "",
                titular: "",
              },
              dinheiro: {
                troco: 0,
                valor: 0,
              },
              forma_id: element.tipoPagId,
              observacao: element.observacao,
              pix: {
                chave: "",
              },
              valor: parseFloat(element.valor),
            };
            break;
          case "9" /* Credito CLiente */:
            pagamento = {
              card: {
                ano_val: "2023",
                bandeira: "",
                codauth: "",
                cvv: "",
                debcred: "debito",
                maquineta: "",
                mes_val: "01",
                number: "",
                titular: "",
              },
              creditocliente: this.modeloCredCliente(element.creditos),
              dinheiro: {
                troco: 0,
                valor: 0,
              },
              forma_id: element.tipoPagId,
              observacao: element.observacao,
              pix: {
                chave: "",
              },
              valor: parseFloat(element.valor),
            };
            break;
          case "7" /* Dinheiro */:
            pagamento = {
              card: {
                ano_val: "2023",
                bandeira: "",
                codauth: "",
                cvv: "",
                debcred: "debito",
                maquineta: "",
                mes_val: "01",
                number: "",
                titular: "",
              },
              dinheiro: {
                troco: parseFloat(element.valorTroco),
                valor: parseFloat(element.valorRecebido),
              },
              forma_id: element.tipoPagId,
              observacao: element.observacao,
              pix: {
                chave: "",
              },
              valor: parseFloat(element.valor),
            };
            break;
          case "3" /* Pix */:
            pagamento = {
              card: {
                ano_val: "2023",
                bandeira: "",
                codauth: "",
                cvv: "",
                debcred: "debito",
                maquineta: "",
                mes_val: "01",
                number: "",
                titular: "",
              },
              dinheiro: {
                troco: 0,
                valor: 0,
              },
              forma_id: element.tipoPagId,
              observacao: element.observacao,
              pix: {
                chave: element.chavePix,
              },
              valor: parseFloat(element.valor),
            };
            break;
          default:
            break;
        }
        formasPagamento.push(pagamento);
      }
      let modelo = {
        card: {},
        dinheiro: {},
        pix: {},
        comanda_id: comandaId,
        comanda_itens: comandaItens,
        valor_total: this.formatValor(this.valorPagar),
        valor_original: this.formatValor(this.valorTotal),
        // valor_total: this.formatValor(this.valorTotal),
        // valor_original: this.formatValor(this.valorPagar),
        tipo_desconto: this.formatValor(this.desconto),
        desconto_percent: this.descontoPercent,
        desconto_valor: this.getValorDescontoModel(),
        formas_pagamento: formasPagamento,
      };
      return modelo;
    },
    modeloCredCliente(creditos) {
      let modelo = [];
      for (let index = 0; index < creditos.length; index++) {
        const credito = creditos[index];
        modelo.push({
          credito_id: credito.id,
          gerar_credito:
            parseFloat(credito.valor) == parseFloat(credito.valorUsado)
              ? "N"
              : "S",
          valor_total: parseFloat(credito.valor),
          valor_usado: parseFloat(credito.valorUsado),
        });
      }
      return modelo;
    },
    getValorDescontoModel() {
      if (this.formatValor(this.desconto) == 2) {
        return this.descontoValor;
      }
      if (this.formatValor(this.desconto) == 1) {
        return 0;
      } else {
        if (this.formatValor(this.descontoValor) > this.valorTotal) {
          return this.valorTotal;
        } else {
          return this.formatValor(this.descontoValor);
        }
      }
    },
    getDesconto(descontoValor) {
      if (this.formatValor(descontoValor) > this.valorTotal) {
        return this.formatarSaldo(this.valorTotal);
      } else {
        return descontoValor;
      }
    },
    async pagarItens() {
      if (this.validarPagamento) {
        const modelo = this.gerarModelo();
        const response = await this.formaPagamentoService.pagamentoLivre(
          modelo
        );
        if (response.status === 200) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Item Pago",
            color: "sucess",
          });
          this.$emit("response");
        } else if (response.status === 428) {
          this.$_ACTIONS_showSnackbarMessage({
            message: response.message ?? "Sem caixa",
            color: "blue",
          });
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Ocorreu um erro interno. Tente novamente mais tarde",
            color: "error",
          });
        }
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Verifique os valores informados.",
          color: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.card-desconto {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.theme--dark.v-btn.v-btn--disabled,
.theme--dark.v-btn.v-btn--disabled .v-icon {
  color: rgba(29, 175, 128) !important;
}
.text-green {
  color: rgba(29, 175, 128);
}
.table-overflow {
  max-height: 200px;
  overflow: auto;
}
</style>
