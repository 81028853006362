<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Administrativos
        </h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="administrativos"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            v-model="dialogCadastro"
            max-width="500px"
            persistent
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#1daf80"
                dark
                class="mb-2 bg-verde"
                rounded
                v-bind="attrs"
                v-on="on"
                v-if="allowCadastrarBtn"
              >
                Novo Administrativo
              </v-btn>
            </template>
            <v-card>
              <v-toolbar style="background-color: #1daf80; color: #fff">
                <v-btn icon dark @click="dialogCadastro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Cadastrar administrativo</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <cadastro-novo-administrativo
                v-if="dialogCadastro"
                @close="dialogCadastro = false"
                @response="atualizarListaAdministrativos"
              />
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-select
            :items="unidades"
            item-text="unidade"
            item-value="id_unidade"
            outlined
            placeholder="Unidade"
            v-model="unidadeSelected"
            class="col-2 mt-5 mr-3"
          ></v-select>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Pesquisar"
            hide-details
            class="col-3 mb-2"
            outlined
          ></v-text-field>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <cadastro-edicao-administrativo
              v-if="administrativo_detalhe"
              :administrativo="administrativo_detalhe"
              :visualizar="visualizar"
              :key="render"
              @close="save($event)"
            />
          </v-dialog>
          <!-- Dialog Altera Senha -->
          <v-dialog
            v-model="dialogAltSenha"
            hide-overlay
            transition="dialog-bottom-transition"
            max-width="500"
          >
            <modal-alterar-senha
              v-if="dialogAltSenha"
              :infoUsuario="infoUsuarioSelected"
              @close="(infoUsuarioSelected = {}), (dialogAltSenha = false)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="idPerfil == 1"
          icon
          small
          @click="editSenha(item)"
          class="mr-2"
        >
          <v-icon small title="Alterar Senha">
            mdi mdi-form-textbox-password
          </v-icon>
        </v-btn>
        <v-btn
          v-if="allowVisualizarBtn"
          icon
          small
          @click="editItem(item, true)"
          class="mr-2"
        >
          <v-icon small title="Visualizar">
            mdi mdi-eye
          </v-icon>
        </v-btn>
        <v-btn
          v-if="allowEditarBtn"
          icon
          small
          @click="editItem(item, false)"
          class="mr-2"
        >
          <v-icon small title="Editar">
            fas fa-edit
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <p class="text center">Nenhum registro encontrado</p>
        <v-btn color="#1daf80" dark @click="atualizarListaAdministrativos">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroEdicaoAdministrativo from "@/components/administrativos/cadastro_edicao_administrativo.vue";
import CadastroNovoAdministrativo from "@/components/administrativos/cadastro_novo_administrativo.vue";
import AuthService from "../services/auth_service";
// import endpoints from "../router/endpoints";
import AdministrativoService from "../services/administrativo_service";
import UnidadeService from "../services/unidade_service";
import ModalAlterarSenha from "@/components/senha/modal-alterar-senha.vue";

export default {
  components: {
    CadastroEdicaoAdministrativo,
    CadastroNovoAdministrativo,
    ModalAlterarSenha,
  },

  data: () => ({
    administrativo_detalhe: {},
    render: 0,
    dialog: false,
    loading: false,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    dialogCadastro: false,
    administrativo: {},
    visualizar: false,
    administrativos: [],
    headers: [
      { text: "Nome", align: "start", sortable: false, value: "nome" },
      { text: "CPF", value: "cpf", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Status", value: "ativo", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    services: {
      authService: AuthService.build(),
      administrativoService: AdministrativoService.build(),
      unidadeService: UnidadeService.build(),
    },
    unidades: [],
    unidadeSelected: {},
    idPerfil: JSON.parse(sessionStorage.getItem("vuex")).perfil.id,
    infoUsuarioSelected: {},
    dialogAltSenha: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Novo Procedimento"
        : "Editar Procedimento";
    },
    allowCadastrarBtn() {
      return 1;
      // return this.services.authService.temPermissao(endpoints["AdministrativoController::POST->register"]);
    },
    allowEditarBtn() {
      return 1;
      // return this.services.authService.temPermissao(endpoints["AdministrativoController::POST->updateAdministrativoData/$1"])
    },
    allowVisualizarBtn() {
      return 1;
      // return this.services.authService.temPermissao(endpoints["AdministrativoController::GET->getAdministrativoByID/$1"])
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaAdministrativos();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarListaAdministrativos();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarListaAdministrativos();
      }
    },
    unidadeSelected() {
      this.atualizarListaAdministrativos();
    },
  },

  async mounted() {
    await this.listUnidades();
    const unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade;
    this.unidadeSelected = this.unidades.find(
      (un) => (un.id_unidade = unidade.id)
    );
  },

  methods: {
    forceRender() {
      this.render += 1;
    },
    async atualizarListaAdministrativos() {
      this.loading = true;
      const {
        administrativos,
        pagination,
      } = await this.services.administrativoService.getUnidadePaginatedList({
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        search: this.search,
        order: this.options.ordenacao,
        desc: this.options.desc,
        unidade: this.unidadeSelected.id_unidade || "",
      });
      this.administrativos = administrativos.map((adm) => {
        return {
          ...adm,
          ativo: adm.ativo == 1 ? "Ativo" : "Inativo",
        };
      });
      this.totalList = pagination.numRows;
      this.loading = false;
    },

    async listUnidades() {
      await this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((resp) => resp.json())
        .then((resp) => {
          this.unidades = resp.data;
        });
    },

    async loadingAdministrativo(administrativoId) {
      this.administrativo_detalhe = await this.services.administrativoService.getDetalhesByAdministrativoId(
        administrativoId
      );
      this.dialog = true;
      // const administrativoService = new AdministrativoService();
      // administrativoService.get(
      //   {
      //     onSucess: (status) => (body) => {
      //       if(status === 200){
      //         this.administrativo_detalhe = body.data;
      //         this.dialog = true;
      //       }
      //
      //     },
      //     onError: (error) => {
      //       this.messageSnackbar = error;
      //       this.typeMessageSnackbar = "error";
      //       console.log(error);
      //     },
      //     onEnd: () => {},
      //   },id);
    },

    editItem(item, bool) {
      this.editedIndex = this.administrativos.indexOf(item);
      this.administrativo = item;
      this.visualizar = bool;
      this.editedItem = Object.assign({}, item);
      this.loadingAdministrativo(this.administrativo.administrativoId);
      // this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.dialogCadastro = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.administrativos[this.editedIndex], this.editedItem);
      } else {
        this.administrativos.push(this.editedItem);
      }
      this.administrativo_detalhe = false;
      this.options.ordenacao = false;
      this.options.desc = false;
      this.forceRender();
      this.atualizarListaAdministrativos();
      this.close();
    },
    editSenha(item) {
      this.infoUsuarioSelected = {
        id: item.administrativoId,
        perfilId: item.idPerfil,
        nome: item.nome,
        email: item.email,
        idUsuario: item.usuario_id,
      };
      this.dialogAltSenha = true;
    },
  },
};
</script>
