<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Forma Pagamento</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="container p-3" v-if="!loading">
      <div class="row">
        <div class="col-6">
          <h5 class="text-left">
            Valor a Pagar: {{ formatarSaldo(infoComanda.valorPagar) }}
          </h5>
        </div>
        <div class="col-6">
          <h5 class="text-right">
            Falta Pagar: {{ formatarSaldo(calcularValorPagar()) }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <v-select
            :items="formasdePagamento"
            v-model="formaPagamentoSelecionada"
            item-text="text"
            item-value="value"
            label="Forma Pagamento"
          ></v-select>
        </div>
        <div class="col-8 pt-6">
          <v-text-field
            v-model="valorPagamento"
            v-money="moneyConfig"
            @keydown="verificarTecla($event)"
            type="text"
            step="0.01"
            min="0"
            label="Valor"
            style="padding-top: 0 !important"
            :messages="'Campo Obrigatório'"
            :error-messages="messageError('valor')"
          ></v-text-field>
        </div>
      </div>
      <div
        v-if="
          (formaPagamentoSelecionada == 1 || formaPagamentoSelecionada == 2) &&
            formatValor(valorPagamento) > 0
        "
      >
        <h5 class="text-left">
          Cartão
          {{ formaPagamentoSelecionada == 1 ? "Crédito" : "Débito" }}
        </h5>
        <div class="row">
          <div class="col-6">
            <v-text-field
              label="Código de autorização"
              v-model="codAutPagamento"
            />
          </div>
          <div class="col-6">
            <v-text-field
              label="Maquineta"
              required
              v-model="maquinetaPagamento"
              :messages="'Campo Obrigatório'"
            />
          </div>
        </div>
      </div>
      <div
        v-if="formaPagamentoSelecionada == 9 && formatValor(valorPagamento) > 0"
      >
        <h5 class="text-left">
          Credito Cliente
        </h5>
        <div class="row">
          <div class="col-12">
            <div class="table-credito">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        id
                      </th>
                      <th class="text-left">
                        Valor Disponível
                      </th>
                      <th class="text-left">
                        Valor Usado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in creditosCliente" :key="item.id">
                      <td class="text-left">{{ item.id }}</td>
                      <td class="text-left">
                        {{ formatarSaldo(item.valor) }}
                      </td>
                      <td class="text-left col-5">
                        <div class="px-5">
                          <v-text-field
                            v-money="moneyConfig"
                            @keydown="
                              verificarTecla($event),
                                handleCreditoValor($event.target.value, item)
                            "
                            type="text"
                            @change="
                              handleCreditoValor($event.target.value, item)
                            "
                            @blur="
                              handleCreditoValor($event.target.value, item)
                            "
                            :value="item.valorUsado"
                            :disabled="parseFloat(item.valor) == 0"
                          ></v-text-field>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="formaPagamentoSelecionada == 7 && formatValor(valorPagamento) > 0"
      >
        <h5 class="text-left">
          Dinheiro
        </h5>
        <div class="row">
          <div class="col-6">
            <v-text-field
              v-model="valorRecebidoPagamento"
              v-money="moneyConfig"
              @keydown="verificarTecla($event)"
              type="text"
              step="0.01"
              min="0"
              label="Valor Recebido"
              :messages="'Campo Obrigatório'"
            ></v-text-field>
          </div>
          <div class="col-6">
            <v-text-field
              label="Troco"
              disabled
              required
              v-model="valorTrocoPagamento"
            />
          </div>
        </div>
      </div>
      <div
        v-if="formaPagamentoSelecionada == 3 && formatValor(valorPagamento) > 0"
      >
        <h5 class="text-left">
          Pix
        </h5>
        <div class="row">
          <div class="col-12">
            <v-text-field label="Chave Pix" v-model="pixPagamento" />
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="formaPagamentoSelecionada && formatValor(valorPagamento) > 0"
      >
        <div class="col-12">
          <v-textarea
            label="Observações"
            maxlength="255"
            rows="3"
            v-model="observacaoPagamento"
          ></v-textarea>
          <div class="text-right">
            <v-btn
              color="#1daf80"
              class="mb-2 white--text"
              type="number"
              @click="adicionarPagamento()"
              :disabled="!validarForm()"
              :dark="validarForm()"
            >
              {{ validarIndex() ? "Editar" : "Adicionar Pagamento" }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center p-4" v-else>
      <v-progress-circular indeterminate color="#02004f"></v-progress-circular>
    </div>
  </v-card>
</template>
<style scoped>
.v-text-field__details {
  display: none !important;
}
</style>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import { VMoney } from "v-money";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  props: {
    pagamento: {
      type: Object,
      required: false,
    },
    pagamentoIndex: {
      type: Number,
      required: false,
    },
    userCreditos: {
      type: Object,
      required: false,
    },
    infoComanda: {
      type: Object,
      required: true,
    },
    editar: {
      type: Boolean,
      required: true,
    },
  },
  directives: { money: VMoney },
  data: () => ({
    moneyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: true,
    },
    itemPagamento: {},
    formasdePagamento: [],
    formaPagamentoSelecionada: "",
    valorPagamento: 0,
    valorRecebidoPagamento: 0,
    valorTrocoPagamento: 0,
    observacaoPagamento: "",
    codAutPagamento: "",
    maquinetaPagamento: "",
    pixPagamento: "",
    //
    creditos: [],
    creditosCliente: [],
    loading: true,
  }),
  watch: {
    valorRecebidoPagamento() {
      if (this.formaPagamentoSelecionada == 7) {
        if (this.validarTroco()) {
          let valorTroco =
            this.formatValor(this.valorRecebidoPagamento) -
            this.formatValor(this.valorPagamento);
          this.valorTrocoPagamento = this.formatarSaldo(valorTroco);
        } else {
          this.valorTrocoPagamento = this.formatarSaldo(0);
        }
      }
    },
  },
  async mounted() {
    this.formasdePagamento = this.infoComanda.formasdePagamento;
    this.valorTrocoPagamento = this.formatarSaldo(0);
    this.creditosCliente = [];
    this.creditos = [];
    if (this.editar) {
      this.receberPagamento();
    } else {
      this.valorPagamento = parseFloat(this.calcularValorPagar()).toFixed(2);
      this.creditosCliente = this.userCreditos.creditos;
    }
    this.loading = false;
  },
  computed: {},
  methods: {
    validarTroco() {
      if (
        this.formatValorFloat(this.valorRecebidoPagamento) >
        this.formatValorFloat(this.valorPagamento)
      ) {
        return true;
      } else {
        return false;
      }
    },
    receberPagamento() {
      switch (this.pagamento.tipoPagId) {
        case "1":
          this.formaPagamentoSelecionada = this.pagamento.tipoPagId;
          this.valorPagamento = parseFloat(
            this.pagamento.valor.replaceAll(",", "")
          ).toFixed(2);
          this.observacaoPagamento = this.pagamento.observacao;
          this.codAutPagamento = this.pagamento.codAut;
          this.maquinetaPagamento = this.pagamento.maquineta;
          break;
        case "2":
          this.formaPagamentoSelecionada = this.pagamento.tipoPagId;
          this.valorPagamento = parseFloat(
            this.pagamento.valor.replaceAll(",", "")
          ).toFixed(2);
          this.observacaoPagamento = this.pagamento.observacao;
          this.codAutPagamento = this.pagamento.codAut;
          this.maquinetaPagamento = this.pagamento.maquineta;
          break;
        case "9":
          for (
            let index = 0;
            index < this.userCreditos.creditos.length;
            index++
          ) {
            const element = this.userCreditos.creditos[index];
            let indexCredito = this.pagamento.creditos.findIndex((ele) => {
              return ele.id == element.id;
            });
            if (indexCredito >= 0) {
              let item = element;
              item.valorUsado = this.pagamento.creditos[
                indexCredito
              ].valorUsado;
              this.creditosCliente.push(item);
            } else {
              this.creditosCliente.push(element);
            }
          }
          this.creditos = this.pagamento.creditos;
          // Credito Cliente
          this.formaPagamentoSelecionada = this.pagamento.tipoPagId;
          this.valorPagamento = parseFloat(
            this.pagamento.valor.replaceAll(",", "")
          ).toFixed(2);
          this.observacaoPagamento = this.pagamento.observacao;
          break;
        case "7":
          this.formaPagamentoSelecionada = this.pagamento.tipoPagId;
          this.valorPagamento = parseFloat(
            this.pagamento.valor.replaceAll(",", "")
          ).toFixed(2);
          this.observacaoPagamento = this.pagamento.observacao;
          this.valorRecebidoPagamento = parseFloat(
            this.pagamento.valorRecebido.replaceAll(",", "")
          ).toFixed(2);
          this.valorTrocoPagamento = this.formatarSaldo(
            this.pagamento.valorTroco
          );
          break;
        case "3":
          this.formaPagamentoSelecionada = this.pagamento.tipoPagId;
          this.valorPagamento = parseFloat(
            this.pagamento.valor.replaceAll(",", "")
          ).toFixed(2);
          this.observacaoPagamento = this.pagamento.observacao;
          this.pixPagamento = this.pagamento.chavePix;
          break;
        default:
          break;
      }
    },
    formatValor(valor) {
      if (typeof valor == "string") {
        const newValor = parseFloat(
          valor
            .replaceAll("R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ).toFixed(2);
        return newValor;
      } else {
        return valor;
      }
      // return parseFloat(valor).toFixed(2)
    },
    formatValorFloat(valor) {
      return parseFloat(this.formatValor(valor));
    },
    formatarSaldo(saldo) {
      if (saldo) {
        saldo = saldo.toString().replaceAll(",", "");
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(saldo);
      } else {
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(0);
      }
    },
    getTipoPagamentoDesc(id) {
      let desconto = this.formasdePagamento.find((ele) => {
        return ele.value == id;
      });
      return desconto.text;
    },
    verificarTecla(event) {
      if (
        ((event.keyCode >= 65 && event.keyCode <= 90) ||
          (event.keyCode >= 97 && event.keyCode <= 122)) &&
        ![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 188, 190].includes(
          event.keyCode
        )
      ) {
        event.preventDefault();
      }
    },
    adicionarPagamento() {
      switch (this.formaPagamentoSelecionada) {
        case "1":
          this.itemPagamento = {
            tipoPagId: this.formaPagamentoSelecionada,
            tipoPagDec: this.getTipoPagamentoDesc(
              this.formaPagamentoSelecionada
            ),
            valorFormatada: this.valorPagamento,
            valor: this.formatValor(this.valorPagamento),
            codAut: this.codAutPagamento,
            maquineta: this.maquinetaPagamento,
            observacao: this.observacaoPagamento,
          };
          break;
        case "2":
          this.itemPagamento = {
            tipoPagId: this.formaPagamentoSelecionada,
            tipoPagDec: this.getTipoPagamentoDesc(
              this.formaPagamentoSelecionada
            ),
            valorFormatada: this.valorPagamento,
            valor: this.formatValor(this.valorPagamento),
            codAut: this.codAutPagamento,
            maquineta: this.maquinetaPagamento,
            observacao: this.observacaoPagamento,
          };
          break;
        case "9":
          this.itemPagamento = {
            tipoPagId: this.formaPagamentoSelecionada,
            tipoPagDec: this.getTipoPagamentoDesc(
              this.formaPagamentoSelecionada
            ),
            valorFormatada: this.valorPagamento,
            valor: this.formatValor(this.valorPagamento),
            creditos: this.creditos,
            observacao: this.observacaoPagamento,
          };
          break;
        case "7":
          this.itemPagamento = {
            tipoPagId: this.formaPagamentoSelecionada,
            tipoPagDec: this.getTipoPagamentoDesc(
              this.formaPagamentoSelecionada
            ),
            valorFormatada: this.valorPagamento,
            valor: this.formatValor(this.valorPagamento),
            valorRecebidoFormatada: this.valorRecebidoPagamento,
            valorRecebido: this.formatValor(this.valorRecebidoPagamento),
            valorTrocoFormatada: this.valorTrocoPagamento,
            valorTroco: this.formatValor(this.valorTrocoPagamento),
            observacao: this.observacaoPagamento,
          };
          break;
        case "3":
          this.itemPagamento = {
            tipoPagId: this.formaPagamentoSelecionada,
            tipoPagDec: this.getTipoPagamentoDesc(
              this.formaPagamentoSelecionada
            ),
            valorFormatada: this.valorPagamento,
            valor: this.formatValor(this.valorPagamento),
            chavePix: this.pixPagamento,
            observacao: this.observacaoPagamento,
          };
          break;
        default:
          break;
      }
      this.$emit("response", {
        item: this.itemPagamento,
        index: this.pagamentoIndex,
      });
      this.$emit("close");
    },
    validarInputs() {
      switch (this.formaPagamentoSelecionada) {
        case "1":
          if (
            // this.codAutPagamento == "" ||
            this.maquinetaPagamento == "" ||
            this.formatValor(this.valorPagamento) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        case "2":
          if (
            // this.codAutPagamento == "" ||
            this.maquinetaPagamento == "" ||
            this.formatValor(this.valorPagamento) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        case "9":
          // Credito Cliente
          if (
            !this.validarCreditos() ||
            this.formatValor(this.valorPagamento) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        case "7":
          if (
            this.formatValorFloat(this.valorRecebidoPagamento) <
              this.formatValorFloat(this.valorPagamento) ||
            this.formatValorFloat(this.valorRecebidoPagamento) <= 0 ||
            this.formatValorFloat(this.valorPagamento) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        case "3":
          if (
            // this.pixPagamento == "" ||
            this.formatValor(this.valorPagamento) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        default:
          return false;
      }
    },
    validarForm() {
      if (this.calcularValorPagar() >= this.formatValor(this.valorPagamento)) {
        if (this.validarInputs()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    calcularValorPagar() {
      if (this.validarIndex()) {
        let valorPagar =
          parseFloat(this.infoComanda.valorFaltaPagar) +
          parseFloat(this.pagamento.valor);
        return valorPagar;
      } else {
        return this.infoComanda.valorFaltaPagar;
      }
    },
    validarIndex() {
      if (typeof this.pagamentoIndex == "number" && this.pagamentoIndex >= 0) {
        return true;
      } else {
        return false;
      }
    },
    handleCreditoValor(event, item) {
      if (this.formaPagamentoSelecionada == "9") {
        let indexCredito = this.creditos.findIndex((ele) => {
          return ele.id == item.id;
        });
        if (indexCredito >= 0) {
          if (this.validarValorCredito(this.formatValor(event), item.valor)) {
            this.creditos.splice(indexCredito, 1, {
              ...item,
              valorUsado: this.formatValor(event),
            });
          } else {
            this.creditos.splice(indexCredito, 1);
          }
        } else {
          if (this.validarValorCredito(this.formatValor(event), item.valor)) {
            this.creditos.push({
              ...item,
              valorUsado: this.formatValor(event),
            });
          }
        }
      }
    },
    validarValorCredito(event, valor) {
      if (event <= parseFloat(valor) && event > 0) {
        return true;
      } else {
        return false;
      }
    },
    validarCreditos() {
      let total = 0;
      for (let index = 0; index < this.creditos.length; index++) {
        total = total + parseFloat(this.creditos[index].valorUsado);
      }
      return total == this.formatValor(this.valorPagamento) ? true : false;
    },
    messageError(tipoErro) {
      switch (tipoErro) {
        case "valor":
          if (
            this.formatValor(this.valorPagamento) > this.calcularValorPagar()
          ) {
            return "Valor tem que ser menor que o restante a pagar";
          } else {
            return "";
          }
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.card-desconto {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.table-credito {
  max-height: 180px;
  overflow: auto;
}
</style>
