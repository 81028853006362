<template>
  <div>
    <v-dialog v-model="loadingModal" width="250">
      <v-card class="px-2 py-3">
        <h4>Gerando Arquivo</h4>
        <v-progress-linear indeterminate color="#1daf80"></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="dessertHeaders"
      :items="comandas"
      item-key="id"
      @item-expanded="comandaItens($event)"
      show-expand
      class="elevation-1"
      :loading="loading"
      loading-text="Carregando..."
    >
      <template v-slot:item.nome_pet="{ item }">
        {{ item.nome_pet }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="p-0 font-table">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Tipo</th>
                <th scope="col">Procedimento</th>
                <th scope="col">Convênio</th>
                <th scope="col">Data - Hora de Requisição</th>
                <th scope="col">Data - Hora de Execução</th>
                <th scope="col">Data - Hora do Pagamento</th>
                <th scope="col">Status</th>
                <th scope="col">Forma de pagamento</th>
                <th scope="col">Valor Original</th>
                <th scope="col">Valor Cobrado</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="itemComanda in item.itensComanda"
                :key="itemComanda.comanda_item_id"
              >
                <td>{{ itemComanda.tipo_procedimento_descricao }}</td>
                <td>{{ itemComanda.procedimento_descricao }}</td>
                <td>{{ itemComanda.tipo_convenio_descricao }}</td>
                <td>
                  {{
                    `
                  ${itemComanda.data_requisicao
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_requisicao}
                `
                  }}
                </td>
                <td>
                  {{
                    itemComanda.data_execucao == "1900-01-01"
                      ? "-"
                      : `
                  ${itemComanda.data_execucao
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_execucao}
                `
                  }}
                </td>
                <td>
                  {{
                    itemComanda.data_pagamento == "1900-01-01"
                      ? "-"
                      : `
                  ${itemComanda.data_pagamento
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_pagamento}
                `
                  }}
                </td>
                <td>{{ itemComanda.status_descricao }}</td>
                <td>{{ itemComanda.formaPagamento }}</td>
                <!-- <td>
                  {{
                    Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(itemComanda.valor)
                  }}
                </td> -->
                <td>
                  {{
                    Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(itemComanda.valor_original)
                  }}
                </td>
                <td>
                  {{
                    Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(itemComanda.valor_cobrado)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
                title="Gerar documento"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi mdi-file-download
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="gerarPdf(item)">
                <h5 class="item-list-print pdf">
                  <v-icon class="mr-2">
                    mdi mdi-file-pdf-box
                  </v-icon>
                  PDF
                </h5>
              </v-list-item>
              <v-list-item @click="gerarExcel(item)">
                <h5 class="item-list-print excel">
                  <v-icon class="mr-2">
                    mdi mdi-file-excel-box
                  </v-icon>
                  Excel
                </h5>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            class="btn"
            small
            @click="fecharComanda(item)"
            v-if="item.data_fechada == 'comanda aberta'"
            >Fechar comanda</v-btn
          > -->
          <v-btn
            icon
            class="ml-2"
            title="Fechar comanda"
            small
            @click="fecharComanda(item)"
            v-if="item.data_fechada == 'comanda aberta'"
          >
            <v-icon>
              mdi mdi-text-box-check
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="#1daf80"
            class="ml-2"
            title="Auditoria"
            small
            @click="showAuditoriaComandas(item)"
            v-if="item.procedimentos_publicos != 0"
          >
            <v-icon>
              mdi mdi-file-eye
            </v-icon>
          </v-btn>
          <v-btn
            icon
            class="ml-2"
            title="Detalhe comanda"
            small
            @click="showDetalhesComandas(item)"
          >
            <v-icon>
              mdi mdi-magnify
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <template v-if="dialogDetalhesComandas">
      <v-dialog
        v-model="dialogDetalhesComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark class="bg-verde">
            <v-btn icon dark @click="dialogDetalhesComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comanda Detalhes </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <modal-comanda-detalhes
            :comanda="comandaDetalhes"
            :tutor="tutor"
            @close="() => (dialogDetalhesComandas = false)"
          />
        </v-card>
      </v-dialog>
    </template>

    <template v-if="dialogAuditoriaComandas">
      <v-dialog
        v-model="dialogAuditoriaComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark class="bg-verde">
            <v-btn icon dark @click="dialogAuditoriaComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Auditoria Comanda</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <modal-auditoria-comanda
            :comanda="comandaDetalhes"
            :tutor="tutor"
            @close="() => (dialogAuditoriaComandas = false)"
          />
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import PacienteService from "../../services/paciente_service";
import ModalComandaDetalhes from "@/components/pacientes/modal_comanda_detalhes_teste.vue";
import ModalAuditoriaComanda from "@/components/relatorios/modal_auditoria_comanda.vue";

export default {
  mixins: [Mixin],
  emits: ["recarregarComandas"],
  props: {
    comandas: Array,
    loading: Boolean,
  },
  components: {
    ModalComandaDetalhes,
    ModalAuditoriaComanda,
  },
  data() {
    return {
      dessertHeaders: [
        { text: "Id", align: "start", value: "id", sortable: false },
        {
          text: "Tutor",
          align: "start",
          value: "nome_cliente",
          sortable: false,
        },
        { text: "Pet", value: "nome_pet", sortable: false },
        { text: "Abertura", value: "data_criada", sortable: false },
        { text: "Fechamento", value: "data_fechada", sortable: false },
        { text: "Valor Total", value: "valor_total_fomatado", sortable: false },
        {
          text: "Valor Aberto",
          value: "valor_aberto_fomatado",
          sortable: false,
        },
        { text: "Valor Pago", value: "valor_pago_fomatado", sortable: false },
        { text: "", value: "actions", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
      tutor: {},
      dialogDetalhesComandas: false,
      dialogAuditoriaComandas: false,
      comandaDetalhes: {},
      comandaService: ComandaService.build(),
      pacienteService: new PacienteService(),
      loadingModal: false,
    };
  },
  watch: {
    comandas() {},
  },
  methods: {
    async comandaItens(event) {
      if (event.value) {
        this.loading = true;
        const item = event.item;
        item.itensComanda = await this.getComandaItems(item.id);
        item.itensComanda = item.itensComanda.map((item) => {
          return { ...item, formaPagamento: "" };
        });

        item.itensComanda.map((item) => {
          item.formaPagamento = item.id_forma_pagamento
            ? this.formasPagamento.filter(
                (formPag) => formPag.id == item.id_forma_pagamento
              )[0].descricao
            : "-";
        });
        this.loading = false;
      }
    },
    async getComandaItems(comandaId) {
      const response = await this.comandaService.getComandaItensByComandaId(
        comandaId
      );

      return response;
    },
    async fecharComanda(comanda) {
      const response = await this.comandaService.fecharComanda(comanda.id ?? 0);
      if (response.status == 200) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A comanda foi fechada com sucesso.",
          color: "sucess",
        });
        this.$emit("recarregarComandas");
      } else {
        const error = await response.json();
        this.$_ACTIONS_showSnackbarMessage({
          message: error.message,
          color: "error",
        });
      }
    },
    async showDetalhesComandas(comanda) {
      const resp = await this.pacienteService.getPacienteByClienteId(
        comanda.id_cliente
      );
      this.tutor = resp.data;

      this.comandaDetalhes = {
        ...comanda,
        comanda_id: comanda.id,
        cliente_id: comanda.id_cliente,
        valor: parseFloat(comanda.valor_total.slice(3)),
        comandaEstaAberta: comanda.data_fechada === "comanda aberta",
      };
      this.dialogDetalhesComandas = true;
    },
    async showAuditoriaComandas(comanda) {
      console.log(comanda);
      const resp = await this.pacienteService.getPacienteByClienteId(
        comanda.id_cliente
      );
      this.tutor = resp.data;

      this.comandaDetalhes = {
        ...comanda,
        comanda_id: comanda.id,
        cliente_id: comanda.id_cliente,
        valor: parseFloat(comanda.valor_total.slice(3)),
        comandaEstaAberta: comanda.data_fechada === "comanda aberta",
      };
      this.dialogAuditoriaComandas = true;
    },
    gerarPdf(item) {
      if (item.id) {
        this.loadingModal = true;
        this.comandaService
          .pdfByComandaId(item.id)
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Dados encontrados com sucesso! Gerando PDF...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `Comanda ${item.id} - Tutor ${item.nome_cliente} - Pet ${item.nome_pet}.pdf`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          })
          .finally(() => {
            this.loadingModal = false;
          });
      }
    },
    gerarExcel(item) {
      if (item.id) {
        this.loadingModal = true;
        this.comandaService
          .excelByComandaId(item.id)
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message:
                "Dados encontrados com sucesso! Gerando arquivo para Excel...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `Comanda ${item.id} - Tutor ${item.nome_cliente} - Pet ${item.nome_pet}.xlsx`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          })
          .finally(() => {
            this.loadingModal = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.item-list-print i {
  vertical-align: sub;
}
.item-list-print.pdf {
  color: #b51308;
}
.item-list-print.pdf i {
  color: #b51308;
}
.item-list-print.excel {
  color: #0a7641;
}
.item-list-print.excel i {
  color: #0a7641;
}
.font-table {
  font-size: 0.7rem !important;
}
</style>
