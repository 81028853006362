<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Relatório - Produtos/Serviços
        </h3>
      </div>
    </div>

    <v-row align="center" class="my-3">
      <v-col class="d-flex">
        <v-select
          :items="unidades"
          label="Unidade"
          item-value="id"
          v-model="unidadeSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="green darken-4"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="success" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-select
          :items="convenios"
          item-text="descricao"
          item-value="id"
          label="Convênio"
          v-model="convenioSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <v-select
          :items="tipoList"
          item-text="descricao"
          item-value="id"
          label="Execução"
          v-model="tipoSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <!-- <v-btn class="mx-2" dark color="primary" @click="pdfRelatorio">
            Exportar PDF
          </v-btn> -->
        <v-speed-dial
          v-model="fab"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              style="
                color: #fff;
                background-color: #1daf80;
                border-radius: 50px;
              "
              color="blue darken-2"
              dark
              class="btnWid"
            >
              <v-icon v-if="fab"> mdi-close </v-icon>
              <span v-else> Exportar </span>
            </v-btn>
          </template>
          <!-- <template v-slot:activator>
            <v-btn color="blue darken-2" dark @click="pdfRelatorio">
              Exportar PDF
            </v-btn>
          </template> -->
          <v-btn dark color="green" class="btnWid" @click="pdfRelatorio">
            PDF
          </v-btn>
          <v-btn dark color="indigo" class="btnWid" @click="excelRelatorio">
            EXCEL
          </v-btn>
        </v-speed-dial>
      </v-col>
    </v-row>

    <TabelaProcedimentos
      :comandas="comandas"
      :loading="loadingTable"
      @recarregarComandas="getRelatorio"
    />
  </div>
</template>
<script>
import UnidadeService from "../services/unidade_service";
import ProcedimentoService from "../services/procedimento_service";
import TabelaProcedimentos from "@/components/relatorios/tabela-relatorio-procedimentos.vue";
import FormaPagamentoService from "@/services/forma_pagamento_service";
import ConvenioService from "../services/convenio_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: { TabelaProcedimentos },
  mixins: [Mixin],
  data: () => ({
    datas: null,
    menu: false,
    loadingTable: false,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    unidades: [],
    unidadeSelected: "",
    convenios: [
      // { descricao: "Público", id: 1 },
      { descricao: "Particular", id: 2 },
      // { descricao: "Plano M100", id: 3 },
      // { descricao: "Plano M50", id: 4 },
      // { descricao: "Todos", id: 5 },
    ],
    convenioSelected: "2",
    tipoList: [
      { descricao: "Executado", id: 2 },
      { descricao: "Não executado", id: 1 },
      { descricao: "Todos", id: 3 },
    ],
    tipoSelected: "",
    comandas: [],
    procedimentoId: "",
    formasPagamento: [],
    services: {
      unidadeService: UnidadeService.build(),
      procedimentoService: ProcedimentoService.build(),
      formaPagamentoService: FormaPagamentoService.build(),
      convenioService: ConvenioService.build(),
    },
    direction: "bottom",
    fab: false,
    hover: false,
    left: false,
  }),
  watch: {},
  beforeMount() {
    this.setUnidades();
    this.setFormasPagamento();
    this.setConvenios();
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    setFormasPagamento() {
      this.services.formaPagamentoService
        .getFormaPagamentoPaginatedList()
        .then((resp) => resp.json())
        .then((resp) => {
          this.formasPagamento = resp.data.forma_pagamento.map((item) => {
            return { id: item.id, descricao: item.descricao };
          });
        });
    },
    setConvenios() {
      this.services.convenioService
        .getTipoConvenio()
        .then((resp) => resp.json())
        .then((resp) => {
          this.convenios = resp.data;
        })
        .catch((err) => console.log(err));
    },
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },
    pdfRelatorio() {
      this.loadingTable = true;

      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const convenio = this.convenioSelected;
      const execucao = this.tipoSelected;

      if (!dataInicial || !dataFinal || !unidade || !convenio || !execucao) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingTable = false;
        return;
      }

      if (execucao == 3) {
        this.services.procedimentoService
          .pdfProcedimentosFull(
            dataInicial,
            dataFinal,
            unidade,
            convenio,
            execucao
          )
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Dados encontrados com sucesso! Gerando PDF...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `${dataInicial}-${dataFinal}.pdf`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          })
          .finally(() => {
            this.loadingTable = false;
          });
        return;
      }

      this.services.procedimentoService
        .pdfRelatorioProcedimentos(
          dataInicial,
          dataFinal,
          unidade,
          convenio,
          execucao
        )
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    excelRelatorio() {
      this.loadingTable = true;

      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const convenio = this.convenioSelected;
      const execucao = this.tipoSelected;

      if (!dataInicial || !dataFinal || !unidade || !convenio || !execucao) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingTable = false;
        return;
      }

      if (execucao == 3) {
        this.services.procedimentoService
          .excelProcedimentosFull(
            dataInicial,
            dataFinal,
            unidade,
            convenio,
            execucao
          )
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message:
                "Dados encontrados com sucesso! Gerando arquivo para Excel...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `${dataInicial}-${dataFinal}.xlsx`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          })
          .finally(() => {
            this.loadingTable = false;
          });
        return;
      }

      this.services.procedimentoService
        .excelRelatorioProcedimentos(
          dataInicial,
          dataFinal,
          unidade,
          convenio,
          execucao
        )
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message:
              "Dados encontrados com sucesso! Gerando arquivo para Excel...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    getRelatorio() {
      this.loadingTable = true;

      if (this.comandaId) {
        this.services.comandaService
          .getRelatorioComandaById(this.comandaId)
          .then((response) => {
            this.comandas = response.data.map((item) => {
              return {
                ...item,
                valor_total: Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.valor_total),
                valor_pago: Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.valor_pago ? item.valor_pago : "0"),
                data_criada: item.data_criada
                  .split("-")
                  .reverse()
                  .join("/"),
                data_fechada:
                  item.data_fechada == "1900-01-01"
                    ? "comanda aberta"
                    : item.data_fechada
                        .split("-")
                        .reverse()
                        .join("/"),
                itensComanda: [],
              };
            });
          })
          .then(() => {
            this.comandas.map(async (item) => {
              item.itensComanda = await this.getComandaItems(item.id);
              item.itensComanda = item.itensComanda.map((item) => {
                return { ...item, formaPagamento: "" };
              });

              item.itensComanda.map((item) => {
                item.formaPagamento = item.id_forma_pagamento
                  ? this.formasPagamento.filter(
                      (formPag) => formPag.id == item.id_forma_pagamento
                    )[0].descricao
                  : "-";
              });
            });
            this.loadingTable = false;
          });
        this.loadingTable = false;
        return;
      }

      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const status = this.statusSelected
        ? this.statusSelected.toLowerCase()
        : "";

      if (!dataInicial || !dataFinal || !unidade || !status) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });

        this.loadingTable = false;
        return;
      }

      this.services.comandaService
        .getRelatorioComandas(dataInicial, dataFinal, unidade, status)
        .then((response) => {
          this.comandas = response.data.map((item) => {
            return {
              ...item,
              valor_total: Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(item.valor_total),
              valor_pago: Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(item.valor_pago ? item.valor_pago : "0"),
              data_criada: item.data_criada
                .split("-")
                .reverse()
                .join("/"),
              data_fechada:
                item.data_fechada == "1900-01-01"
                  ? "comanda aberta"
                  : item.data_fechada
                      .split("-")
                      .reverse()
                      .join("/"),
              itensComanda: [],
            };
          });
        })
        .then(() => {
          this.comandas.map(async (item) => {
            item.itensComanda = await this.getComandaItems(item.id);
            item.itensComanda = item.itensComanda.map((item) => {
              return { ...item, formaPagamento: "" };
            });

            item.itensComanda.map((item) => {
              item.formaPagamento = item.id_forma_pagamento
                ? this.formasPagamento.filter(
                    (formPag) => formPag.id == item.id_forma_pagamento
                  )[0].descricao
                : "-";
            });
          });
          this.loadingTable = false;
        });
    },
    async getComandaItems(comandaId) {
      const response = await this.services.comandaService.getComandaItensByComandaId(
        comandaId
      );

      return response;
    },
  },
};
</script>
<style scoped>
.btnWid {
  width: 7rem;
}
</style>
