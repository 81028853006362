<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Atendimento</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <v-dialog v-model="dialogMessage" width="500">
      <div class="container" style="background-color: white">
        <div class="row justify-content-center">
          <div class="col-10">
            <div style="display: inline-block;">
              <h3 class="mb-4 mt-4">
                Processando atendimentos...
              </h3>
              <v-progress-linear
                indeterminate
                height="6"
                rounded
                color="#1daf80"
              ></v-progress-linear>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-stepper v-model="step" style="box-shadow: initial !important;">
      <v-stepper-header>
        <v-stepper-step color="#1daf80" :complete="step > 1" step="1">
          Procedimentos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step color="#1daf80" step="2">
          Formulário
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="container">
            <div class="row">
              <div class="col-6">
                <v-card-title>
                  Procedimentos
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchProcedimentos"
                    append-icon="mdi-magnify"
                    label="Pesquisa"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  v-model="procedimentosSelecionados"
                  :headers="headers"
                  :items="procedimentos"
                  :search="searchProcedimentos"
                  :single-select="false"
                  show-select
                  class="elevation-1"
                  height="320px"
                  @toggle-select-all="selectAllT"
                >
                  <template v-slot:header.data-table-select="{}">
                    <v-simple-checkbox
                      @input="selectAllToggle"
                      :value="selectedAll"
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>
              </div>
              <div class="col-6">
                <v-card-title>
                  Procedimentos Selecionados
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchProcedimentosSelecionados"
                    append-icon="mdi-magnify"
                    label="Pesquisa"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headersSelected"
                  :items="procedimentosSelecionados"
                  :search="searchProcedimentosSelecionados"
                  class="elevation-1"
                  height="320px"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="red"
                      elevation="1"
                      fab
                      x-small
                      @click="removeItem(item)"
                    >
                      <v-icon color="white">
                        mdi-close
                      </v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 text-right">
                <v-btn
                  :dark="procedimentosSelecionados.length != 0"
                  color="#1daf80"
                  :disabled="procedimentosSelecionados.length == 0"
                  @click="step = 2"
                >
                  Continuar
                </v-btn>
              </div>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="container">
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <h4>Tutor: {{ infos.nomeTutor }}</h4>
                <h4 class="text-left">
                  ID: {{ infos.petId }} <br />
                  Pet: {{ infos.nomePet }}
                </h4>
              </div>
            </div>
            <div
              class="row"
              :class="form.perguntas.length == 1 ? 'pergunsta' : ''"
              v-for="(input, index) in form.perguntas"
              :key="`pergunta${index}`"
            >
              <form-consulta-fill
                :pergunta="input"
                v-if="input"
                @resposta="respondido($event)"
                class="col-12"
              />
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <v-btn
                  dark
                  text
                  color="#1976d2"
                  elevation="1"
                  @click="step = 1"
                >
                  Procedimentos
                </v-btn>
                <v-btn
                  :dark="!loadingAtendimento"
                  elevation="1"
                  color="#1daf80"
                  :disabled="loadingAtendimento"
                  @click="concluir"
                >
                  <span v-if="!loadingAtendimento">
                    Concluir Atendimento
                  </span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    style="width: 24px; height: 24px"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- <div class="px-10 py-4">
      <div class="container">
        {{ infos }}
      </div>
    </div> -->
  </v-card>
</template>

<script>
// import PacienteService from "@/services/paciente_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import FormularioService from "@/services/formulario_service.js";
import FormConsultaFill from "@/components/consultas/formulario_consulta_fill.vue";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import ConsultaService from "@/services/consulta_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: { FormConsultaFill },
  mixins: [Mixin],
  data: () => ({
    services: {
      procedimentoService: new ProcedimentoService(),
      formularioService: new FormularioService(),
      requisicaoExameService: RequisicaoExameService.build(),
      consultaService: new ConsultaService(),
    },
    step: 1,
    procedimentos: [],
    procedimentosSelecionados: [],
    searchProcedimentos: "",
    searchProcedimentosSelecionados: "",
    headers: [
      {
        text: "Procedimento",
        align: "start",
        sortable: false,
        value: "procedimento",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: false,
        value: "tipo_procedimento",
      },
    ],
    headersSelected: [
      {
        text: "Procedimento",
        align: "start",
        sortable: false,
        value: "procedimento",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: false,
        value: "tipo_procedimento",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
    form: {},
    resp: [],
    form_resp: {
      id_formulario: 0,
      id_agendamento: 0,
      titulo: "",
      descricao: "",
      respostas: [],
      id_requisicao_procedimento: 0,
    },
    model: [],
    statusModel: [],
    formId: "",
    loadingAtendimento: false,
    dialogMessage: false,
  }),
  props: {
    infos: {
      required: true,
    },
  },
  watch: {
    statusModel() {
      if (this.statusModel.length > 0 && !this.statusModel.includes(0)) {
        this.loadingAtendimento = false;
        if (
          this.statusModel.includes(200) &&
          !this.statusModel.includes(206) &&
          !this.statusModel.includes(500)
        ) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Atendimento executados com sucesso",
            color: "sucess",
          });
          this.$emit("close");
        } else if (
          !this.statusModel.includes(200) &&
          !this.statusModel.includes(206) &&
          this.statusModel.includes(500)
        ) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Não foi possivel executar",
            color: "error",
          });
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Alguns atendimento não foram executados",
            color: "warning",
          });
          this.$emit("close");
        }
      }
    },
    loadingAtendimento() {
      if (this.loadingAtendimento && this.model.length > 2) {
        this.dialogMessage = true;
      } else {
        this.dialogMessage = false;
      }
    },
  },
  beforeMount() {
    this.getProcedimento();
    this.getFormId();
  },
  mounted() {},
  computed: {
    selectedAll() {
      return this.procedimentosSelecionados.length == this.procedimentos.length
        ? true
        : false;
    },
  },
  methods: {
    getProcedimento() {
      this.services.requisicaoExameService
        .getRequisicoesAbertasAtendimentoMedico(
          this.infos.petId,
          this.infos.unidadeId
        )
        .then(async (response) => {
          const { data } = await response;
          this.procedimentos = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectAllToggle() {
      if (this.procedimentosSelecionados.length == this.procedimentos.length) {
        this.procedimentosSelecionados = [];
      } else {
        this.procedimentosSelecionados = this.procedimentos;
      }
    },
    removeItem(item) {
      let index = this.procedimentosSelecionados.findIndex((ele) => {
        return ele == item;
      });
      if (index >= 0) {
        this.procedimentosSelecionados.splice(index, 1);
      }
    },
    getFormId() {
      this.services.formularioService.getAllMulti(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.formId = body.data[0].id;
              this.getForm();
            } else {
              if (status != 206 && status != 204) {
                this.$_ACTIONS_showSnackbarMessage({
                  message:
                    body.message || this.$global.messages.internalServerError,
                  color: status === 200 ? "sucess" : "error",
                });
              }
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        { ativo: 1 }
      );
    },
    getForm() {
      this.services.formularioService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form.titulo = body.data.titulo;
              this.form.descricao = body.data.descricao;
              this.form.id_medico = body.data.id_medico;
              this.form.perguntas = body.data.perguntas
                .filter((pergunta) => pergunta.ativo)
                .sort((a, b) => {
                  if (a.ordem < b.ordem) return -1;
                  if (a.ordem > b.ordem) return 1;
                  return 0;
                });
              this.loadingFormulario = false;
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            // this.$emit("close", { error });
            console.log(error);
          },
          onEnd: () => {},
        },
        this.formId
      );
    },
    async atendimento(model, index) {
      await this.services.consultaService
        .postAtendimento(model)
        .then(async (response) => {
          // const body = await response.json();
          this.statusModel.splice(
            index,
            1,
            response.status == (200 || 206) ? response.status : 500
          );
        })
        .catch((error) => {
          this.statusModel[index] = 500;
          console.log(error);
        });
    },
    async concluir() {
      this.model = [];
      this.statusModel = [];
      this.model = this.createModel();
      this.statusModel = Array(this.model.length).fill(0);
      this.loadingAtendimento = true;
      for (let index = 0; index < this.model.length; index++) {
        try {
          setTimeout(() => {
            this.atendimento(this.model[index], index);
          }, (index + 1) * 2000);
        } catch (error) {
          console.error("Erro ao fazer a requisição:", error);
        }
      }
    },
    respondido(e) {
      if (this.resp.length < 1) {
        this.resp.push({ id_pergunta: e.id_pergunta, resposta: e.resposta });
      } else {
        let respEvent = this.resp
          .map((p) => p.id_pergunta)
          .indexOf(e.id_pergunta);
        if (respEvent >= 0) {
          this.resp[respEvent] = {
            id_pergunta: e.id_pergunta,
            resposta: e.resposta,
          };
        } else {
          this.resp.push({ id_pergunta: e.id_pergunta, resposta: e.resposta });
        }
      }
    },
    getProcedimentosModel(lista) {
      let listProcedimentos = [];
      for (let index = 0; index < lista.length; index++) {
        const element = lista[index];
        listProcedimentos.push({
          id_requisicao: element.id,
        });
      }
      return listProcedimentos;
    },
    createModel() {
      let models = [];
      let lista = this.agruparConsecutivos();
      for (let index = 0; index < lista.length; index++) {
        models.push({
          id_formulario: parseInt(this.formId),
          titulo: this.form.titulo,
          descricao: this.form.descricao,
          respostas: this.resp,
          requisicoes: this.getProcedimentosModel(lista[index]),
          id_pet: this.infos.petId,
          id_unidade: this.infos.unidadeId,
          id_medico: this.infos.medicoId,
        });
      }
      return models;
    },
    agruparConsecutivos() {
      let resultado = [[]];
      for (let i = 0; i < this.procedimentosSelecionados.length; i++) {
        let item = this.procedimentosSelecionados[i];
        for (let iResult = 0; iResult < resultado.length; iResult++) {
          const res = resultado[iResult];
          let indexFilter = res.findIndex((ele) => {
            return ele.procedimento_id == item.procedimento_id;
          });
          if (indexFilter == -1) {
            res.push({
              id: item.id,
              procedimento_id: item.procedimento_id,
            });
            resultado[iResult] = res;
            break;
          } else if (!resultado[iResult + 1]) {
            resultado.push([
              {
                id: item.id,
                procedimento_id: item.procedimento_id,
              },
            ]);
            break;
          }
        }
      }
      return resultado;
    },
  },
};
</script>
<style>
.container-formata-tabs {
  width: 100%;
}
.modal-nav-ajustes {
  background-color: #1daf80 !important;
  color: #ffffff !important;
  font-size: 1.5rem;
  font-weight: 500;
}
.pergunsta textarea {
  height: 300px !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
}
</style>
