<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">{{ titulo }}</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pets"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            persistent
            fullscreen
            v-model="dialogCadastrar"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                class="mb-2 btn-style-green"
                v-bind="attrs"
                v-on="on"
              >
                Novo Pet
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarPetPosCadastro"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este Pet?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1daf80" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex mt-2">
            <v-text-field
              v-model="searchId"
              append-icon="mdi-magnify"
              label="Id"
              placeholder="Id"
              class="mr-5 col-4"
              single-line
              hide-details
            />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Nome do pet"
              placeholder="Nome do pet"
              single-line
              hide-details
            />
          </div>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            fullscreen
            hide-overlay
            max-width="500px"
            v-if="dialogEdicao"
            v-model="dialogEdicao"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :petId="editedPetId"
              :visualizar="visualizar"
              @close="closeDialogEdited"
              @response="atualizarPetPosEdicao($event)"
            />
          </v-dialog>

          <v-dialog
            v-model="dialogArquivo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <arquivo
              :paciente="petArquivo"
              @close="() => (dialogArquivo = false)"
            />
          </v-dialog>

          <v-dialog
            fullscreen
            hide-overlay
            max-width="500px"
            v-model="dialogAgendarPet"
          >
            <modal-pet-agendamento
              :pet="petAgendamento"
              @close="closeDialogAgendarPet"
              @response="atualizarPetPosEdicao($event)"
            />
          </v-dialog>

          <!-- DIALOG DE REQUISITAR CONSULTA -->
          <v-dialog
            v-model="dialogRequisitarConsulta"
            v-if="dialogRequisitarConsulta"
            fullscreen
            persistent
          >
            <RequisitarConsulta
              @close="() => (dialogRequisitarConsulta = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>

          <!-- DIALOG DE REQUISITAR EXAMES -->
          <v-dialog
            v-model="dialogRequisitarExames"
            v-if="dialogRequisitarExames"
            fullscreen
            persistent
          >
            <RequisitarExamesPets
              v-if="dialogRequisitarExames"
              @close="() => (dialogRequisitarExames = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>
          <!-- DIALOG DE REQUISITAR EXAMES -->
          <v-dialog
            v-model="dialogRequisitarExamesLaudo"
            v-if="dialogRequisitarExamesLaudo"
            fullscreen
            persistent
          >
            <RequisitarExamesLaudoPets
              v-if="dialogRequisitarExamesLaudo"
              @close="() => (dialogRequisitarExamesLaudo = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>

          <v-dialog
            v-model="dialogRequisitarMedicacao"
            v-if="dialogRequisitarMedicacao"
            fullscreen
            persistent
          >
            <RequisitarMedicacao
              v-if="dialogRequisitarMedicacao"
              @close="() => (dialogRequisitarMedicacao = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>

          <v-dialog
            v-model="dialogRequisitarHigiene"
            v-if="dialogRequisitarHigiene"
            fullscreen
            persistent
          >
            <RequisitarHigiene
              v-if="dialogRequisitarHigiene"
              @close="() => (dialogRequisitarHigiene = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>

          <v-dialog
            v-model="dialogRequisitarInternacao"
            v-if="dialogRequisitarInternacao"
            fullscreen
            persistent
          >
            <RequisitarInternacao
              v-if="dialogRequisitarInternacao"
              @close="() => (dialogRequisitarInternacao = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>
          <v-dialog
            v-model="dialogRequisitarCirurgia"
            v-if="dialogRequisitarCirurgia"
            fullscreen
            persistent
          >
            <RequisitarCirurgia
              v-if="dialogRequisitarCirurgia"
              @close="() => (dialogRequisitarCirurgia = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowNewConsultaBtn && !validarTelevet"
          small
          class="mr-2"
          @click="requisitarExames(item.petId, item.clienteId)"
          title="Requisitar Exames"
        >
          mdi-clipboard-text-clock
        </v-icon>

        <v-icon small class="mr-2" @click="verArquivos(item)" title="Arquivos">
          fas fa-folder
        </v-icon>

        <v-icon
          v-if="allowNewConsultaBtn && !validarTelevet"
          small
          class="mr-2"
          @click="requisitarConsulta(item.petId, item.clienteId)"
          title="Requisitar Consulta"
        >
          mdi-application-import
        </v-icon>

        <v-icon
          title="Visualizar cadastro"
          v-if="allowVisualizarBtn"
          small
          class="mr-2"
          @click="editItem(item, true)"
        >
          mdi mdi-eye
        </v-icon>
        <v-icon
          title="Editar cadastro"
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item, false)"
        >
          mdi-pencil
        </v-icon>
        <!-- <v-icon
          small
          class="mr-2"
          @click="showDialogAgendarPet(item)"
          title="Agenda"
        >
          fas fa-file-medical
        </v-icon> -->
        <v-icon
          v-if="!validarTelevet"
          small
          class="mr-2"
          @click="showDialogInternacao(item)"
          title="Requisitar Internação"
        >
          mdi-hospital-building
        </v-icon>
        <v-icon
          v-if="!validarTelevet"
          small
          class="mr-2"
          @click="showDialogMedicacoes(item)"
          title="Requisitar Medicação"
        >
          mdi-pill
        </v-icon>
        <v-icon
          v-if="!validarTelevet"
          small
          class="mr-2"
          @click="requisitarExamesLaudo(item.petId, item.clienteId)"
          title="Requisitar Exame - Laudo"
        >
          mdi-clipboard-pulse
        </v-icon>
        <!-- <v-icon
          v-if="!validarTelevet"
          small
          class="mr-2"
          @click="showDialogHigiene(item)"
          title="Requisitar Higiene"
        >
          mdi-shower
        </v-icon> -->
        <v-icon
          v-if="!validarTelevet"
          small
          class="mr-2"
          @click="showDialogCirurgia(item)"
          title="Requisitar Cirurgia"
        >
          mdi-box-cutter
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from "@/components/pets/modal_cadastro";
import modalEdicao from "@/components/pets/modal_edicao";
import PetsService from "../services/pets_service.js";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import ModalPetAgendamento from "@/components/pets/modal_pet_agendamento";
import RequisitarConsulta from "@/components/recepcao/infoChamada/buttons/marcar-consulta/requisitarConsulta-semcf.vue";
import RequisitarExamesPets from "@/components/requisitar_exames/requisitar_exame.vue";
import RequisitarExamesLaudoPets from "@/components/requisitar_exames_laudo/requisitar_exame_laudo.vue";
import RequisitarMedicacao from "@/components/recepcao/infoChamada/buttons/requisitar-medicacao-pet.vue";
import RequisitarHigiene from "@/components/recepcao/infoChamada/buttons/requisitar-higiene.vue";
import RequisitarInternacao from "@/components/recepcao/infoChamada/buttons/requisitar-internacao.vue";
import RequisitarCirurgia from "@/components/recepcao/infoChamada/buttons/requisitar-cirurgia.vue";

export default {
  mixins: [Mixin],
  components: {
    modalCadastro,
    modalEdicao,
    ModalPetAgendamento,
    RequisitarConsulta,
    RequisitarExamesPets,
    RequisitarExamesLaudoPets,
    RequisitarMedicacao,
    RequisitarHigiene,
    RequisitarInternacao,
    RequisitarCirurgia,
    Arquivo,
  },
  data: () => ({
    petId: "",
    clienteId: "",
    petArquivo: {},
    dialogArquivo: false,
    dialogAgendarPet: false,
    dialogRequisitarConsulta: false,
    dialogRequisitarExames: false,
    dialogRequisitarExamesLaudo: false,
    dialogRequisitarMedicacao: false,
    dialogRequisitarHigiene: false,
    dialogRequisitarInternacao: false,
    dialogRequisitarCirurgia: false,
    petAgendamento: {},
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    dialog: false,
    dialogObs: false,
    editedPetId: {},
    search: "",
    searchId: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    dialogCadastro: false,
    pet: {},
    pets: [],
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "petId",
      },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "Cor", value: "cor", sortable: false },
      { text: "Nome do Tutor", value: "clienteNome", sortable: false },
      { text: "CPF do Tutor", value: "clienteCpf", sortable: false },
      { text: "Sexo", value: "sexo", sortable: false },
      {
        text: "RGA Pedigre",
        value: "rgaPedigre",
        sortable: false,
      },
      {
        text: "Especie",
        value: "nomeEspecie",
        sortable: false,
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: false,
    services: {
      petService: PetsService.build(),
    },
    visualizar: false,
    //Televet
    validarTelevet:
      JSON.parse(sessionStorage.vuex).unidade.id == 24 ? true : false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarPets();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarPets();
      }
    },
    searchId(value, oldValue) {
      if (value.length > 1) {
        this.options.page = 1;
        this.atualizarPets(true);
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarPets(true);
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarPets();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarPets();
  },
  computed: {
    allowCadastrarBtn() {
      return 1;
    },
    allowEditarBtn() {
      return 1;
    },
    allowVisualizarBtn() {
      return 1;
    },
    allowNewConsultaBtn() {
      return 1;
    },
    allowDeletarBtn() {
      return 1;
    },
    titulo() {
      return JSON.parse(sessionStorage.vuex).perfil.id === 4
        ? "Meus pets"
        : "Pets";
    },
  },
  methods: {
    verArquivos(item) {
      this.petArquivo = { id_paciente: item.pacienteId, id_pet: item.petId };
      this.dialogArquivo = true;
    },
    requisitarConsulta(idPet, idTutor) {
      this.petId = idPet;
      this.clienteId = idTutor;
      this.dialogRequisitarConsulta = true;
    },
    requisitarExames(idPet, idTutor) {
      this.petId = idPet;
      this.clienteId = idTutor;
      this.dialogRequisitarExames = true;
    },
    requisitarExamesLaudo(idPet, idTutor) {
      this.petId = idPet;
      this.clienteId = idTutor;
      this.dialogRequisitarExamesLaudo = true;
    },
    atualizarPetPosCadastro() {
      this.atualizarPets();
      this.closeDialogCadastrar();
    },
    atualizarPetPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarPets();
    },
    async atualizarPets(filterId) {
      const paginationParams = {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.order,
        desc: this.options.desc,
        search: filterId ? this.searchId : this.search,
        filterId,
      };
      this.loading = true;
      await this.services.petService
        .getPetsPaginatedList(paginationParams)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, pets } = data;
          this.totalList = pagination.num_rows;
          this.pets = pets.map((pet) => this.mapPet(pet));
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapPet(pet) {
      return {
        petId: pet.id_pet,
        nome: pet.nome_pet ?? "",
        clienteNome: pet.nome,
        clienteCpf: pet.cpf,
        cor: pet.cor,
        sexo: pet.sexo,
        rgaPedigre: pet.rga_pedigree,
        nomeEspecie: pet.nome_especie,
        clienteId: pet.id_cliente,
        pacienteId: pet.id_paciente,
      };
    },
    editItem(item, bool) {
      this.visualizar = bool;
      this.editedPetId = parseInt(item.petId) || 0;
      this.dialogEdicao = true;
    },

    deleteItem({ petId }) {
      this.petIdToDelete = petId;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.petService
        .deletarpet(this.petIdToDelete)
        .then(async ({ status }) => {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? "O pet foi deletado com sucesso."
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
          status === 200 && (await this.atualizarPerfis());
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete();
        });
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDialogAgendarPet() {
      this.dialogAgendarPet = false;
    },

    showDialogAgendarPet(item) {
      this.dialogAgendarPet = true;
      this.petAgendamento = item;
    },

    showDialogMedicacoes(item) {
      this.clienteId = item.clienteId;
      this.petId = item.petId;
      this.dialogRequisitarMedicacao = true;
    },

    showDialogHigiene(item) {
      this.clienteId = {
        id_cliente: item.clienteId,
        nome: item.clienteNome,
      };
      this.petId = {
        id_pet: item.petId,
        nome: item.nome,
      };
      this.dialogRequisitarHigiene = true;
    },

    showDialogInternacao(item) {
      this.clienteId = {
        id_cliente: item.clienteId,
        nome: item.clienteNome,
      };
      this.petId = {
        id_pet: item.petId,
        nome: item.nome,
      };
      this.dialogRequisitarInternacao = true;
    },

    showDialogCirurgia(item) {
      console.log(item);
      this.clienteId = {
        id_cliente: item.clienteId,
        nome: item.clienteNome,
      };
      this.petId = {
        id_pet: item.petId,
        nome: item.nome,
      };
      this.dialogRequisitarCirurgia = true;
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 0px;
}
</style>
