<template>
  <div class="info-container d-flex justify-content-between w-50 ml-5 mt-5">
    <div class="text-left">
      <v-card-text>
        <span>
          <strong>Tutor: </strong>
          {{ infoPaciente.NomeTutor || infoPaciente.UsuarioSenha }} </span
        ><br />
        <span>
          <strong>Pet: </strong>
          {{ infoPaciente.NomePet }} </span
        ><br />
        <span class="font-weight-bold">
          Observações:
          <b class="red--text font-weight-regular">{{
            infoPaciente.ObservacaoExtra
          }}</b>
        </span>
        <!-- <v-dialog v-model="dialogObservacoes" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              depressed
              color="error"
              v-bind="attrs"
              v-on="on"
              class="mt-3"
              >Observações
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5 bg-danger text-white lighten-2">
              Observações
            </v-card-title>

            <h5 class="text-justify pt-5 px-5">
              {{
                infoPaciente.ObservacaoExtra
                  ? infoPaciente.ObservacaoExtra
                  : infoPaciente.Observacao
              }}
            </h5>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogObservacoes = false">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <!-- <span class="obs">
          <strong>Observações: </strong>
          {{ infoPaciente.Observacao }} </span
        ><br /> -->
      </v-card-text>
    </div>
    <div class="d-flex flex-column">
      <ConsultarTutor />
      <CadastrarTutor />
      <CadastrarPet />
      <v-btn
        small
        depressed
        color="primary"
        class="mb-3"
        @click="dialogSelectReq"
      >
        Requisições
      </v-btn>
      <v-btn small depressed color="success" @click="handleDialogComandas">
        Comandas
      </v-btn>

      <template v-if="dialogComandas">
        <v-dialog
          v-model="dialogComandas"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark style="background-color: #1daf80; color: #fff">
              <v-btn icon dark @click="dialogComandas = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Comandas </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>

            <modal-comandas :tutor="tutor" :petId="petIdComanda" />
          </v-card>
        </v-dialog>
      </template>

      <v-dialog
        v-model="dialogSelectRequisicao"
        v-if="dialogSelectRequisicao"
        max-width="500px"
      >
        <v-card>
          <v-card-title>Requisitar Serviço</v-card-title>
          <v-card-text>
            <v-select
              :items="requisicoes"
              v-model="selectedRequisicao"
              label="Selecione o que deseja requisitar"
            ></v-select>
          </v-card-text>

          <v-card-text
            v-if="!petEncaminhar.nome_pet && !infoPaciente.PetCodigo"
          >
            <v-autocomplete
              v-model="selectedTutor"
              :loading="loadingSearch"
              :items="tutores"
              :search-input.sync="searchTutor"
              hide-no-data
              hide-selected
              label="Selecione um tutor"
              item-text="nome"
              return-object
            ></v-autocomplete>
          </v-card-text>

          <v-card-text
            v-if="!petEncaminhar.nome_pet && !infoPaciente.PetCodigo"
          >
            <v-select
              :items="pets"
              v-model="selectedPet"
              label="Selecione um pet"
              item-text="nome"
              return-object
            ></v-select>
          </v-card-text>

          <v-card-actions>
            <v-btn color="gray" text @click="dialogSelectRequisicao = false">
              Fechar
            </v-btn>
            <v-btn
              small
              depressed
              :disabled="
                !selectedPet & !petEncaminhar & !infoPaciente.PetCodigo ||
                  !selectedRequisicao
              "
              color="primary"
              @click="requisitar"
            >
              Requisitar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRequisitarConsulta" fullscreen persistent>
        <MarcarConsulta
          v-if="dialogRequisitarConsulta"
          @close="() => (dialogRequisitarConsulta = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <v-dialog v-model="dialogRequisitarExame" fullscreen persistent>
        <RequisitarExamesPets
          v-if="dialogRequisitarExame"
          @close="() => (dialogRequisitarExame = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <v-dialog v-model="dialogRequisitarExameLaudo" fullscreen persistent>
        <RequisitarExamesLaudoPets
          v-if="dialogRequisitarExameLaudo"
          @close="() => (dialogRequisitarExameLaudo = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <v-dialog v-model="dialogRequisitarCirurgia" fullscreen persistent>
        <RequisitarCirurgia
          v-if="dialogRequisitarCirurgia"
          @close="() => (dialogRequisitarCirurgia = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <v-dialog v-model="dialogRequisitarInternacao" fullscreen persistent>
        <RequisitarInternacao
          v-if="dialogRequisitarInternacao"
          @close="() => (dialogRequisitarInternacao = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <v-dialog v-model="dialogRequisitarMedicacao" fullscreen persistent>
        <RequisitarMedicacao
          v-if="dialogRequisitarMedicacao"
          @close="() => (dialogRequisitarMedicacao = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <v-dialog v-model="dialogRequisitarHigiene" fullscreen persistent>
        <RequisitarHigiene
          v-if="dialogRequisitarHigiene"
          @close="() => (dialogRequisitarHigiene = false)"
          :petId="selectedPet || petId"
          :tutorId="selectedTutor"
          :petEncaminhar="petEncaminhar"
          :tutor="tutor"
        />
      </v-dialog>

      <!-- <v-btn
        small
        depressed
        color="error"
        class="mt-5"
        @click="dialogSelectReqExame"
      >
        Requisitar Exame
      </v-btn> -->
      <!-- <v-dialog
        v-model="dialogSelectRequisicaoExame"
        v-if="dialogSelectRequisicaoExame"
        max-width="500px"
      >
        <v-card>
          <v-card-title>Requisitar Exame</v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="selectedTutor"
              :loading="loadingSearch"
              :items="tutores"
              :search-input.sync="searchTutor"
              hide-no-data
              hide-selected
              label="Selecione um tutor"
              item-text="nome"
              return-object
            ></v-autocomplete>
          </v-card-text>

          <v-card-text>
            <v-select
              :items="pets"
              v-model="selectedPet"
              label="Selecione um pet"
              item-text="nome"
              return-object
            ></v-select>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="gray"
              text
              @click="dialogSelectRequisicaoExame = false"
            >
              Fechar
            </v-btn>
            <v-btn
              small
              depressed
              :disabled="!selectedPet"
              color="primary"
              @click="requisitarExame"
            >
              Requisitar Exame
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>
  </div>
</template>

<script>
import ConsultarTutor from "@/components/recepcao/infoChamada/buttons/consultar-cadastro/consultaCadastro.vue";
import CadastrarPet from "@/components/recepcao/infoChamada/buttons/cadastraPet.vue";
import CadastrarTutor from "@/components/recepcao/infoChamada/buttons/cadastrar-tutor/cadastraTutor.vue";
import PacienteService from "@/services/paciente_service.js";
import MarcarConsulta from "@/components/recepcao/infoChamada/buttons/marcar-consulta/marcarConsulta.vue";
import RequisitarExamesPets from "@/components/requisitar_exames/requisitar_exame_pet";
import RequisitarExamesLaudoPets from "@/components/requisitar_exames_laudo/requisitar_exame_laudo_pet";
import RequisitarCirurgia from "@/components/recepcao/infoChamada/buttons/requisitar-cirurgia.vue";
import RequisitarInternacao from "@/components/recepcao/infoChamada/buttons/requisitar-internacao.vue";
import RequisitarMedicacao from "@/components/recepcao/infoChamada/buttons/requisitar-medicacao.vue";
import RequisitarHigiene from "@/components/recepcao/infoChamada/buttons/requisitar-higiene.vue";
import ModalComandas from "@/components/pacientes/modal_comandas_teste.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    ConsultarTutor,
    CadastrarPet,
    CadastrarTutor,
    MarcarConsulta,
    RequisitarExamesPets,
    RequisitarExamesLaudoPets,
    RequisitarCirurgia,
    RequisitarInternacao,
    RequisitarMedicacao,
    RequisitarHigiene,
    ModalComandas,
  },
  data() {
    return {
      dialogObservacoes: false,
      dialogSelectRequisicao: false,
      dialogSelectRequisicaoExame: false,
      dialogRequisitarConsulta: false,
      dialogRequisitarExame: false,
      dialogRequisitarExameLaudo: false,
      dialogRequisitarCirurgia: false,
      dialogRequisitarInternacao: false,
      dialogRequisitarMedicacao: false,
      dialogRequisitarHigiene: false,
      loadingSearch: false,
      tutores: [],
      tutor: {},
      selectedTutor: "",
      pets: [],
      petId: {},
      selectedPet: "",
      searchTutor: "",
      infoPaciente: {},
      tokenChamefacil: "",
      requisicoes: [
        "Consulta",
        "Exame",
        "Medicação",
        "Cirurgia",
        "Internação",
        "Higiene",
        "Exame - Laudo",
      ],
      selectedRequisicao: "",
      petEncaminhar: {},
      dialogComandas: false,
      petIdComanda: null,
    };
  },
  beforeMount() {
    this.infoPaciente = JSON.parse(localStorage.getItem("infoPaciente"));
    if (this.infoPaciente.TutorCodigo) {
      this.getTutorByClienteId(this.infoPaciente.TutorCodigo);
    }
    console.log(this.infoPaciente);
  },
  watch: {
    searchTutor(nome) {
      if (!nome) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutor() {
      this.pets = "";
      this.pets = this.selectedTutor.pets;
    },
    // selectedPet() {
    //   console.log();
    //   console.log(this.selectedTutor);
    // },
  },
  methods: {
    async handleDialogComandas() {
      this.petEncaminhar = {};
      const petEncaminhar = JSON.parse(sessionStorage.vuex).petEcaminhar;
      if (!petEncaminhar.nome_pet) {
        if (this.infoPaciente.TutorCodigo) {
          this.petIdComanda = this.infoPaciente.PetCodigo;
          this.getTutorByClienteId(this.infoPaciente.TutorCodigo);
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Pet não identificado.",
            color: "error",
          });
          return;
        }
      } else {
        this.getTutorByClienteId(petEncaminhar.tutorId);
        this.petIdComanda = petEncaminhar.petId;
      }

      this.dialogComandas = true;
    },
    async dialogSelectReq() {
      this.petEncaminhar = {};
      const petEncaminhar = JSON.parse(sessionStorage.vuex).petEcaminhar;

      if (petEncaminhar.nome_pet) {
        this.petEncaminhar = petEncaminhar;
      }
      this.selectedPet = "";
      this.selectedTutor = "";
      this.searchTutor = "";
      this.dialogSelectRequisicao = true;
    },
    dialogSelectReqExame() {
      this.selectedPet = "";
      this.selectedTutor = "";
      this.searchTutor = "";
      this.dialogSelectRequisicaoExame = true;
    },
    async requisitar() {
      if (!this.selectedTutor) {
        const pacienteService = new PacienteService();
        const tutorId =
          this.petEncaminhar.tutorId || this.infoPaciente.TutorCodigo;

        this.tutor = await pacienteService
          .getPacienteByClienteId(tutorId)
          .then((resp) => resp.data);

        this.petId = {
          id_pet: this.petEncaminhar.petId || this.infoPaciente.PetCodigo,
          nome: this.petEncaminhar.nome_pet || this.infoPaciente.NomePet,
        };
      } else {
        this.tutor = this.selectedTutor;
      }

      this.dialogSelectRequisicao = false;

      if (this.selectedRequisicao == "Consulta") {
        this.dialogRequisitarConsulta = true;
      } else if (this.selectedRequisicao == "Exame") {
        this.dialogRequisitarExame = true;
      } else if (this.selectedRequisicao == "Cirurgia") {
        this.dialogRequisitarCirurgia = true;
      } else if (this.selectedRequisicao == "Internação") {
        this.dialogRequisitarInternacao = true;
      } else if (this.selectedRequisicao == "Medicação") {
        this.dialogRequisitarMedicacao = true;
      } else if (this.selectedRequisicao == "Higiene") {
        this.dialogRequisitarHigiene = true;
      } else if (this.selectedRequisicao == "Exame - Laudo") {
        this.dialogRequisitarExameLaudo = true;
      }
    },
    requisitarExame() {
      this.dialogSelectRequisicaoExame = false;
      this.dialogRequisitarExame = true;
    },
    async getTutorByClienteId(clienteId) {
      const pacienteService = new PacienteService();
      this.tutor = await pacienteService
        .getPacienteByClienteId(clienteId)
        .then((resp) => resp.data);
    },
  },
};
</script>

<style scoped>
@media (max-width: 1080px) {
  .info-container {
    width: 80vw !important;
    margin: 0 !important;
  }
}
</style>
