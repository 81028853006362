<template>
  <v-card>
    <div class="p-3 mt-8">
      <div class="row">
        <div class="col-md-8 m-auto">
          <div class="row justify-content-between">
            <h3 class="mt-0 mb-2 font-weight-bold text-left">
              Cadastro de Pet
            </h3>
            <button
              @click="voltar()"
              class="btn btn-app-primary font-weight-bold text-left"
            >
              Voltar para login
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mb-4">
      <!-- <a href="index.html"> -->
      <span>
        <img
          src="@/assets/images/logo_diagnostico_ambos.jpg"
          alt=""
          height="120px"
        />
        <!-- <img
          src="@/assets/images/Logotipo-Televet-Play-Color.png"
          alt=""
          height="120px"
        /> -->
        <!-- <img src="@/assets/images/logo-login.png" alt="" height="120px" /> -->
      </span>
      <!-- </a> -->
    </div>

    <div class="p-5">
      <v-dialog v-model="dialogIdPet" width="20%">
        <v-card class="py-5 border">
          <v-card-text>
            <h1 class="font-weight-regular">
              Id Pet
            </h1>
            <h1>
              {{ idPet }}
            </h1>
          </v-card-text>
          <v-card-actions>
            <v-btn color="#1daf80" class="m-auto" text @click="closeDialogIdPet"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <form class="p-lg-3 row">
        <!-- <div class="col-lg-12">
          <div class="mx-auto my-4">
            <h3>Adicionar foto do pet</h3>
            <picture-input
              buttonClass="btn btn-app-primary btn-round font-weight-bold"
              removeButtonClass="btn btn-danger btn-round font-weight-bold"
              ref="pictureInput"
              :prefill="image"
              @change="trocarImagem"
              width="400"
              height="400"
              margin="16"
              accept="image/jpeg,image/png,image/bmp"
              :prefillOptions="{
                fileType: 'png',
              }"
              size="10"
              :removable="true"
              :customStrings="{
                upload:
                  '<p> Seu dispositivo não oferece suporte para upload de arquivos. </p> ',
                drag: '<h3>Arraste uma imagem ou <br> clique aqui para selecionar um arquivo.</h3>',
                tap: 'Toque aqui para selecionar uma imagem <br> de sua galeria.',
                change: 'Trocar imagem',
                remove: 'Remover imagem',
                select: 'Selecione uma imagem',
                selected: '<p>Foto selecionada com sucesso!</p>',
                fileSize: 'O tamanho do arquivo excede o limite',
                fileType: 'Este tipo de arquivo não é compatível.',
              }"
            >
            </picture-input>
          </div>
        </div> -->

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Nome</label>
          <v-text-field
            v-model="petNome"
            :rules="[rules.required]"
            placeholder="Seu nome completo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">RGA Pedigree</label>
          <v-text-field
            v-model="rgaPedigree"
            placeholder="RGA Pedigree"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label>Espécie</label>
          <v-select
            style="padding-top: 0 !important"
            :items="especies"
            v-model="especieId"
          />
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Raça</label>
          <v-select
            style="padding-top: 0 !important"
            :items="racas"
            v-model="idRaca"
          />
        </div>

        <div class="col-lg-3 form-group clearfix">
          <label>Cor</label>
          <v-autocomplete
            v-model="cor"
            :rules="[rules.required]"
            :items="cores"
            label="Cor"
            style="padding-top: 0 !important"
          ></v-autocomplete>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Número do Chip</label>
          <v-text-field
            v-model="numeroChip"
            placeholder="Número do Chip"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div
          class="col-lg-3 form-group clearfix mb-0"
          v-if="!idClienteCadastrado"
        >
          <label class="mb-0" for="nome">Tutor Do Pet</label>
          <v-autocomplete
            class="mb-0 mt-0"
            v-model="idCliente"
            :items="clientes"
            :loading="loadingSearch"
            color="#1daf80"
            hide-no-data
            hide-details
            :search-input.sync="search"
            label="Buscar por nome"
            item-text="nome"
            return-object
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.cpf"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <div v-else class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Tutor Do Pet</label>
          <v-text-field
            disabled
            v-model="nomeClienteCadastrado"
            :rules="[rules.required]"
            placeholder="Tutor Do Pet"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Data Nascimento</label>
          <v-text-field
            v-model="dataNascimento"
            placeholder="Data Nascimento"
            :rules="[rules.required]"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'date'"
            :outlined="false"
          />
          <span class="text-muted">ex: "Dia/Mês/Ano"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Idade</label>
          <v-text-field
            v-model="idade"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :outlined="false"
          />
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Sexo</label>
          <v-radio-group :rules="[rules.required]" v-model="sexo">
            <v-radio label="Masculino" value="M" />
            <v-radio label="Feminino" value="F" />
          </v-radio-group>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Castrado</label>
          <v-radio-group :rules="[rules.required]" v-model="castrado">
            <v-radio label="Sim" value="true" />
            <v-radio label="Não" value="false" />
          </v-radio-group>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Porte</label>
          <v-radio-group :rules="[rules.required]" v-model="porte">
            <v-radio label="Grande" value="GRANDE" />
            <v-radio label="Médio" value="MÉDIO" />
            <v-radio label="Pequeno" value="PEQUENO" />
          </v-radio-group>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Peso (Kg)</label>
          <v-text-field
            v-model="peso"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :outlined="false"
            placeholder="Peso"
          />
          <span>Kg</span>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="nome">Observações</label>
          <v-textarea
            maxlength="200"
            v-model="observacao"
            placeholder="Observações"
            outlined
            style="padding-top: 0 !important"
          ></v-textarea>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarPet"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import PetsService from "@/services/pets_service";
import PacienteService from "@/services/paciente_service.js";
import EspecieService from "@/services/especie_service.js";
// import PictureInput from "vue-picture-input";

export default {
  mixins: [Mixin],
  components: {
    // PictureInput,
  },
  data: () => ({
    clientes: [],
    search: "",
    descricao: "",
    especieId: 0,
    especies: [],
    observacao: "",
    porte: "",
    peso: null,
    formCadastro: "",
    permissoes: [],
    listaRacas: [],
    petNome: "",
    image: "",
    raca: "",
    cor: "",
    cores: [
      "BEGE",
      "PRETA",
      "BRANCA",
      "ABRICOT",
      "BRANCO E PRETO",
      "BRANCO E BEGE",
      "BRANCO E MARROM",
      "BRANCO E CINZA",
      "BRANCO E VERMELHO",
      "AZUL",
      "BLACK AND TAN",
      "VERMELHO",
      "CINZA",
      "PRETO,BRANCO E MARROM",
      "BOSTON",
      "ARLEQUIM",
      "TIGRADO",
      "AZUL RUÃO",
      "CARACTERISTICA",
      "TRICOLOR",
      "INDEFINIDA",
      "AMARELO",
      "PINTADO",
      "PELO DURO (ARAME)",
      "DOURADO",
      "MARROM",
      "FULVO",
      "VERMELHO E BRANCO",
      "PRETO E VERMELHO",
      "BRONZE",
      "SAL E PIMENTA",
      "CARVAO",
      "MARTA E BRANCO",
      "MANTO NEGRO",
      "PRETO E AMARELO",
      "PRETO E MARROM",
      "CHAMPANHE",
      "BRANCO",
      "PRETO",
      "PRETA E CINZA",
      "CARAMELO",
      "BRANCO E AMARELO",
      "PRETO E BEGE",
    ],
    idCliente: 0,
    idPet: 0,
    numeroChip: "",
    dataNascimento: "",
    sexo: "",
    castrado: "",
    doado: "",
    rgaPedigree: "",
    idPetStatus: 2,
    idRaca: 0,
    loading: false,
    loadingSearch: false,
    services: {
      petService: PetsService.build(),
      especieService: EspecieService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    dialogIdPet: false,
  }),
  props: {
    idClienteCadastrado: {
      type: Number,
      required: false,
    },
    nomeClienteCadastrado: {
      type: String,
      required: false,
    },
  },
  computed: {
    racas() {
      let newListRacas = this.listaRacas.filter((r) => {
        if (r.id_especie == this.especieId) {
          return {
            value: r.id,
            text: r.nome,
          };
        }
      });
      return newListRacas.map(({ id, nome }) => {
        return {
          value: id,
          text: nome,
        };
      });
    },
    idade() {
      if (!this.dataNascimento) {
        return 0;
      }
      const dataAtual = new Date();
      const dataNascimentoPet = new Date(this.dataNascimento);
      // const dataNascimentoPet = new Date("9 1 2020");

      let anos = dataAtual.getFullYear() - dataNascimentoPet.getFullYear();
      let meses =
        anos * 12 + (dataAtual.getMonth() - dataNascimentoPet.getMonth());

      if (meses < 12) {
        return `${meses} meses`;
      } else {
        return `${Math.floor(meses / 12)} anos e ${meses % 12} meses`;
      }
    },
  },
  watch: {
    search(nome) {
      if (!nome) {
        this.pacientes = [];
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.clientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.clientes = body.data.pacientes;
            } else {
              this.clientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.clientes = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
  },
  async mounted() {
    this.formCadastro = document.forms[0];
    await this.setListaRacas();
    await this.setEspecies();
    if (JSON.parse(sessionStorage.vuex).perfil.id == 4) {
      this.idClienteCadastrado = JSON.parse(
        sessionStorage.vuex
      ).usuario.dados.clienteId;
      this.nomeClienteCadastrado = JSON.parse(
        sessionStorage.vuex
      ).usuario.dados.nome;
    }
  },
  methods: {
    cadastrarPet() {
      console.log("aqui é a função");
      this.loading = true;
      this.services.petService
        .register(this.getFormData())
        .then(async (response) => {
          this.loading = false;
          const resp = await response.json();
          if (response.status === 200) {
            console.log(resp);
            this.idPet = resp.data.id_pet;
            this.mostrarFeedback(response.status);
            this.$_ACTIONS_setPetEncaminhar({
              petId: this.idPet,
              tutorId: this.idClienteCadastrado
                ? this.idClienteCadastrado
                : this.idCliente.id_cliente,
              nome_pet: this.petNome,
              nome_tutor: this.nomeClienteCadastrado || this.idCliente.nome,
            });
            this.idCliente.id_cliente &&
              localStorage.setItem(
                "tutor_id",
                this.idCliente.id_cliente || this.idClienteCadastrado
              );
            this.idPet && localStorage.setItem("pet_id", this.idPet);
            this.dialogIdPet = true;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.errors[Object.keys(resp.errors)[0]],
              color: "error",
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialogIdPet() {
      this.dialogIdPet = false;
      this.resetForm();
      this.$emit("response");
    },
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarPet = this.$refs.pictureInput.file;
        this.image = this.$refs.pictureInput.image;
      } else {
        console.log("API File Reader não suportada: use o <form>");
      }
    },
    async setListaRacas() {
      await this.services.petService.getRacas().then((response) => {
        this.listaRacas = response.data.racas;
      });
    },
    setEspecies() {
      this.services.especieService
        .getEspeciesPaginatedList({
          page: null,
          per_page: null,
          sort: null,
          desc: null,
          search: null,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { especies } = data;
          this.especies = especies.map(({ id, nome }) => {
            return {
              value: id,
              text: nome,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "Pet cadastrado com sucesso!"
            : status === 400
            ? "Preencha os dados Corretamente"
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.image = "";
      this.petNome = "";
      this.cor = "";
      this.numeroChip = "";
      this.dataNascimento = "";
      this.idade = "";
      this.sexo = "";
      this.castrado = "";
      this.observacao = "";
      this.porte = "";
      this.peso = null;
      this.doado = "";
      this.rgaPedigree = "";
      this.idCliente = 0;
      this.idRaca = 0;
      this.especieId = 0;
    },
    getFormData() {
      const formData = new FormData();
      formData.append("foto", this.image);
      formData.append("nome", this.petNome);
      formData.append("cor", this.cor);
      formData.append("numero_chip", this.numeroChip);
      formData.append("data_nascimento", this.dataNascimento);
      formData.append("idade", this.idade);
      formData.append("sexo", this.sexo);
      formData.append("castrado", this.castrado);
      formData.append("doado", this.doado);
      formData.append("porte", this.porte);
      formData.append("peso", parseFloat(this.peso));
      formData.append("observacoes", this.observacao);
      formData.append("rga_pedigree", this.rgaPedigree);
      formData.append(
        "id_cliente",
        this.idClienteCadastrado
          ? this.idClienteCadastrado
          : this.idCliente.id_cliente
      );
      formData.append("id_pet_status", this.idPetStatus);
      formData.append("id_raca", this.idRaca);
      return formData;
    },
    voltar() {
      this.$router.replace("login");
    },
  },
};
</script>
