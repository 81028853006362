import HTTPService from "@/services/http_service.js";

export default class ConsultaService extends HTTPService {
  resource = "consulta";
  register(responseFunctions, consulta) {
    this.jsonRequest(responseFunctions, "POST", this.resource, consulta);
  }

  addAnamneseAdicional(responseFunctions, consulta) {
    const url = "anamnese/adicional/salvar";
    this.jsonRequest(responseFunctions, "POST", url, consulta);
  }

  multiAtendimentos(responseFunctions, consulta) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      "anamnese/medico/multiprocedimento",
      consulta
    );
  }

  async postAtendimento(model) {
    console.log(new Date());
    return await this.postJson("anamnese/medico/multiprocedimento", model);
  }

  multiAtendimentosNew(model) {
    this.postJson("anamnese/medico/multiprocedimento", model);
  }
  // registerAnamnese(responseFunctions, consulta) {
  //   this.jsonRequest(
  //     responseFunctions,
  //     "POST",
  //     this.resource + "-" + "requisicao",
  //     consulta
  //   );
  // }
  registerAnamnese(responseFunctions, consulta) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      this.resource + "-requisicao-multipla",
      consulta
    );
  }

  update(responseFunctions, formulario, idFormulario) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      this.resource + "/" + idFormulario,
      formulario
    );
  }

  // shortUpdate (responseFunctions, formulario, idFormulario) {
  //     this.jsonRequest(responseFunctions, 'POST', this.resource+"/simple/"+idFormulario, formulario )
  // }

  get(responseFunctions, idConsulta) {
    const url = this.resource + "/" + idConsulta;
    this.request(responseFunctions, "GET", url);
  }

  getListByPacienteId({ onSucess, onError, onEnd }, idPaciente) {
    this.request(
      { onSucess, onError, onEnd },
      "GET",
      this.resource + "/paciente/" + idPaciente
    );
  }

  // getAnamneseByName (responseFunctions, nome) {
  //     this.request(responseFunctions, 'GET', this.resource, null, { nome })
  // }

  // searchByNome (vm) {
  //     return nome =>{
  //         if (!nome) {
  //             vm.forms = [];
  //             vm.formulario=''
  //         }
  //         if (!nome || !(nome = nome.trim()) || nome.length < 3 || vm.loading_formulario) {
  //             return;
  //         }
  //         vm.loading_formulario = true;
  //         vm.anamneses = [];
  //         const requestFunctions = (
  //             {
  //                 onSucess: (status) => (body) => {
  //                     if (status === 200) {
  //                         vm.formularios = body.data.formularios;
  //                     } else {
  //                         vm.formularios = [];
  //                     }
  //                 },
  //                 onError: (error) => {
  //                     console.error(error);
  //                     vm.formularios = [];
  //                 },
  //                 onEnd: () => {
  //                     vm.loading = false;
  //                 },
  //             },
  //             this.getFormularioByName(requestFunctions, nome)
  //         );
  //     }
  // }
}
