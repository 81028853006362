<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Histórico Vouchers
        </h3>
      </div>
    </div>
    <v-form ref="form" v-model="form" lazy-validation>
      <div class="row justify-content-center">
        <div class="col-lg-8 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="listaUnidades"
            label="Unidade"
            item-text="desc"
            item-value="id"
            v-model="unidadesSelected"
          />
        </div>
        <!-- <div class="col-lg-4 form-group clearfix mb-3">
          <v-select
            style="padding-top: 0 !important"
            :items="statusList"
            label="Status"
            item-text="desc"
            item-value="id"
            v-model="statusSelected"
          />
        </div> -->
        <div class="col-lg-2 form-group clearfix">
          <button
            :disabled="!formValido"
            @click.prevent="getHistorico"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Pesquisar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </div>
    </v-form>

    <div class="row" v-if="pesquisado">
      <div class="col-12">
        <v-data-table
          :headers="headers"
          :items="vouchersLista"
          class="elevation-1"
          :loading="loading"
          v-on:update:sort-by="options.ordenacao = false"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="detalheCompra(item.id, item)">
              fas fa-eye
            </v-icon>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
            <v-btn color="#1daf80" dark @click="getHistorico">
              Atualizar
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Dialog Detalhe da Venda -->
    <v-dialog
      v-model="dialogDetalhe"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogDetalhe = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Detalhe Compra</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <detalhe-compra
          v-if="dialogDetalhe"
          :compra="compra"
          :detalhe="detalhe"
          @close="dialogDetalhe = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import LojaService from "../services/loja_service";
import VoucherService from "../services/voucher_service";
import DetalheCompra from "@/components/historico_compras/detalhe_compra.vue";
import UnidadeService from "../services/unidade_service";

export default {
  mixins: [Mixin],
  components: {
    DetalheCompra,
  },
  data: () => ({
    loading: false,
    pesquisado: false,
    formValido: false,
    search: "",
    totalList: 10,
    statusList: [
      { id: 0, desc: "Todos" },
      { id: 1, desc: "Confirmados" },
      { id: 2, desc: "Aguardando" },
      { id: 3, desc: "Falhou" },
    ],
    statusSelected: 0,
    vouchersLista: [],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    compra: "",
    detalhe: "",
    dialogDetalhe: false,
    listaUnidades: [],
    unidadesSelected: "",
    headers: [
      { text: "ID Tutor", value: "id_tutor", sortable: false },
      { text: "Tutor", value: "desc_tutor", sortable: false },
      { text: "Pet", value: "pet", sortable: false },
      { text: "Serial", value: "serial", sortable: false },
      { text: "Status", value: "desc_status", sortable: false },
      { text: "Tipo Voucher", value: "desc_tipo", sortable: false },
      { text: "Criação", value: "data_criacao_formatada", sortable: false },
      {
        text: "Vencimento",
        value: "data_vencimento_formatada",
        sortable: false,
      },
      { text: "Unidade", value: "unidade", sortable: false },
      // { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    services: {
      voucherService: VoucherService.build(),
      unidadeService: UnidadeService.build(),
      lojaService: LojaService.build(),
    },
  }),
  watch: {
    unidadesSelected() {
      this.validarFormulario();
    },
    statusSelected() {
      this.validarFormulario();
    },
  },

  mounted() {
    this.listarUnidades();
    // this.getHistorico();
  },
  methods: {
    validarFormulario() {
      if (this.unidadesSelected > 0) {
        this.formValido = true;
      } else {
        this.formValido = false;
      }
    },
    async listarUnidades() {
      this.listaUnidades = [];
      await this.services.unidadeService
        .getUnidadeList()
        .then((response) => {
          this.listaUnidades = response.map((u) => ({
            id: u.unidadeId,
            desc: u.nomeFantasia,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getHistorico() {
      this.vouchersLista = [];
      this.loading = true;
      await this.services.voucherService
        .getVoucherbyUnidadeId(this.unidadesSelected)
        .then(async (response) => {
          const { data } = await response.json();
          const vouchers = data.vouchers;
          for (let index = 0; index < vouchers.length; index++) {
            const element = vouchers[index];
            this.vouchersLista.push({
              id: element.id,
              id_tipo: element.id_tipo,
              id_tutor: element.id_tutor,
              desc_tipo: this.capitalize(element.tipo_voucher.toLowerCase()),
              id_status: element.id_status,
              desc_status: this.capitalize(
                element.voucher_status.toLowerCase()
              ),
              data_criacao: element.data_criacao,
              data_criacao_formatada: this.formatarData(element.data_criacao),
              data_vencimento: element.data_vencimento,
              data_vencimento_formatada: this.formatarData(
                element.data_vencimento,
                false
              ),
              serial: element.serial,
              id_pet: element.id_pet,
              pet: this.capitalize(element.pet.toLowerCase()),
              unidade: this.capitalize(element.unidade.toLowerCase()),
              id_usuario: element.id_usuario,
              desc_tutor: this.capitalize(element.tutor.toLowerCase()),
            });
          }
          this.loading = false;
          this.pesquisado = true;
        })
        .catch((error) => {
          this.loading = false;
          this.pesquisado = true;
          console.log(error);
        });
    },
    detalheCompra(id_compra, item) {
      this.idCompra = id_compra;
      this.compra = item;

      this.services.historicoComprasService
        .getDetalheCompra(this.lojaSelected, this.statusSelected)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data;
          this.detalhe = produtos;
          this.dialogDetalhe = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatarData(data_requisicao, tipo = true) {
      if (data_requisicao) {
        if (tipo) {
          const arrData = data_requisicao.split("-");
          return `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
        } else {
          return "";
        }
      } else {
        return "Sem Data";
      }
    },
    capitalize(string) {
      var words = string.split(" ");
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(" ");
    },
  },
};
</script>
<style scoped>
.btn-ativo {
  color: #1a8c1a !important;
}
.btn-inativo {
  color: #ae1111 !important;
  transform: rotate(180deg);
}
td {
  text-transform: capitalize;
}
</style>
