import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class MedicoParceiroService extends HTTPService {
  resource = "medico";

  static build() {
    return new MedicoParceiroService();
  }

  /**
     @returns {Promise<Response>}
     */
  getMedicosPaginatedList(status, { page, per_page, nome, order, desc }) {
    // return this.get(`medico-externo/${status}`, {
    console.log("s", status);
    return this.get(`medico-externo`, {
      page,
      per_page,
      nome,
      order,
      desc,
    });
  }

  /**
     @returns {Promise<{medicoId: number, nome: string, cpf: string, unidadeId: number}[]>}
     */
  async getMedicoList() {
    try {
      const response = await this.get("medico-externo");
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data.medicos.map((medico) => ({
        medicoId: ~~medico.id,
        nome: medico.nome || "",
        cpf: medico.cpf || "",
        unidadeId: ~~medico.clinica,
        email: medico.email || "",
      }));
    } catch (e) {
      return [];
    }
  }

  async getMedico(medicoId) {
    return await this.get(`medico-externo/${medicoId}`);
  }

  /**
   @returns {Promise<{medicoId: number, nome: string, cpf: string, unidadeId: number}[]>}
   */
  async getMedicoListByUnidadeId(unidadeId) {
    try {
      const response = await this.get("medico-externo", {
        unidade: unidadeId,
      });
      if (response.status !== 200) return [];
      const { data } = await response.json();
      const medicos = data.medicos.map((medico) => ({
        medicoId: ~~medico.id,
        nome: medico.nome?.toUpperCase() || "",
        cpf: medico.cpf || "",
        unidadeId: ~~medico.clinica,
        email: medico.email || "",
      }));
      medicos.sort((a, b) => (a.nome > b.nome ? 1 : -1));
      return medicos;
    } catch (e) {
      return [];
    }
  }

  async getMedicoTelevetListByUnidadeId() {
    try {
      const response = await this.get("medico-list/1");
      if (response.status !== 200) return [];
      const { data } = await response.json();
      const medicos = data.medicos.map((medico) => ({
        medicoId: ~~medico.id,
        nome: medico.nome?.toUpperCase() || "",
        cpf: medico.cpf || "",
        unidadeId: ~~medico.clinica,
        email: medico.email || "",
      }));
      medicos.sort((a, b) => (a.nome > b.nome ? 1 : -1));
      return medicos;
    } catch (e) {
      return [];
    }
  }

  async register(medico) {
    // this.fileRequest(responseFunctions, "POST", this.resource, medico);
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);
    // headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      body: medico,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/medico-externo`,
      requestOptions
    ).then(async (response) => {
      console.log(response);
      return response;
    });
  }
  async editarMedico(medicoId, medico) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: medico,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/medico-externo/${medicoId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
