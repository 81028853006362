<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Encaminhamento</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <!-- <v-text-field v-model="medico" label="Nome do médico"></v-text-field> -->
          <v-select
            v-model="convenioSelecionado"
            :items="convenios"
            label="Clínica"
            class="mb-0"
          >
          </v-select>
        </v-container>
        <vue-editor v-model="content" class="mt-5"></vue-editor>
        <div class="row mt-5">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button class="btn-style-green" @click="gerarPdf()">
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  data: () => ({
    medico: "",
    // convenios: ["Público", "Particular"],
    // convenioSelecionado: "",
    convenios: ["Centro de Olhos", "Diagnóstico Veterinário"],
    convenioSelecionado: "Centro de Olhos",
    content: "",
  }),
  components: {
    VueEditor,
  },
  methods: {
    gerarPdf() {
      // if (this.medico == "") {
      //   3;
      //   console.log("Faltou o nome do médico");
      //   return;
      // }
      this.$emit("gerarPDF", {
        doc: "encaminhamento",
        content: this.content,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.medico = "";
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
