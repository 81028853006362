import HTTPService from "@/services/http_service.js";

export default class SenhaService extends HTTPService {
  resource = "usuario";

  static build() {
    return new SenhaService();
  }

  alterarSenha(body) {
    return this.post(`${this.resource}/admchange-senha`, body);
  }
}
