<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Alterar Senha</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="container">
      <div class="row justify-content-center pt-3 pb-0">
        <div class="col-11 text-start">
          <p class="mb-0">
            <strong>Nome: </strong>{{ infoUsuario.nome }} <br /><strong
              >Email: </strong
            >{{ infoUsuario.email }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center pt-0 mt-0">
        <div class="col-11">
          <div class="form-group mb-5 form-group-mob">
            <v-text-field
              v-model="senha"
              label="Senha"
              :rules="passwordRules"
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="form-group mb-3 form-group-mob">
            <v-text-field
              v-model="confirmarSenha"
              :rules="confirmPasswordRules"
              label="Confirmar Senha"
            ></v-text-field>
          </div>
          <div class="form-group form-group-mob text-end">
            <v-btn
              elevation="1"
              :loading="loadingBtn"
              :disabled="disabledBtn()"
              :dark="!disabledBtn()"
              @click="alterarSenha()"
              color="#1daf80"
              >Alterar Senha</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import SenhaService from "../../services/senha_service.js";

export default {
  mixins: [Mixin],
  props: {
    infoUsuario: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    services: {
      senhaService: SenhaService.build(),
    },
    senha: "",
    confirmarSenha: "",
    loadingBtn: false,
  }),
  mounted() {},
  watch: {},
  computed: {
    passwordRules() {
      return [
        (v) => !!v || "Senha é obrigatória",
        (v) => (v && v.length >= 6) || "Senha deve ter pelo menos 6 caracteres",
      ];
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "Por favor, confirme sua senha",
        (v) => v === this.senha || "As senhas não coincidem",
      ];
    },
  },
  methods: {
    mostrarFeedback(status, response) {
      this.$_ACTIONS_showSnackbarMessage({
        message: status == 200 ? "Alterado com sucesso!" : response.message,
        color: status == 200 ? "sucess" : "error",
      });
    },
    modeloForm() {
      return { id_usuario: this.infoUsuario.idUsuario, senha: this.senha };
    },
    alterarSenha() {
      this.loadingBtn = true;
      this.services.senhaService
        .alterarSenha(this.modeloForm())
        .then(async (resp) => {
          const response = await resp.json();
          this.loadingBtn = false;
          this.mostrarFeedback(resp.status, response);
          if (resp.status == 200) {
            this.$emit("close");
          }
        })
        .catch((error) => {
          this.loadingBtn = false;
          console.error(error);
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    disabledBtn() {
      if (
        this.senha == this.confirmarSenha &&
        this.senha.length >= 6 &&
        this.confirmarSenha.length >= 6
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
