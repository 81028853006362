<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="visualizar">Visualizar atendente</span>
          <span class="headline" v-else>Editar assistente</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="!loadingData">
        <v-container>
          <v-form class="p-lg-3 row" ref="form">
            <!-- <div class="col-lg-12">
              <div class="mx-auto my-4">
                <div v-if="!visualizar">
                  <h3>Alterar foto de perfil</h3>
                  <div>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      color="#1daf80"
                      v-show="addFoto"
                      @click="addFoto = false"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                    <div class="mb-2" v-show="!addFoto">
                      <v-img
                        lazy-src="https://picsum.photos/id/11/10/6"
                        max-height="150"
                        max-width="250"
                        class="m-auto"
                        :src="preview"
                      ></v-img>
                      <v-file-input
                        v-model="foto"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Adicione uma foto"
                        prepend-icon="mdi-camera"
                        @click:clear="clean"
                        @change="onChange($event)"
                      ></v-file-input>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <v-img
                    v-if="image_perfil"
                    :src="image_perfil"
                    class="m-auto"
                    width="150"
                    height="150"
                  ></v-img>
                </div>
              </div>
            </div> -->

            <div class="col-lg-4 form-group clearfix mb-3">
              <label for="nome">Nome Completo</label>
              <v-text-field
                :error-messages="error.nome"
                v-model="atendente.nome"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Nome é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Seu nome completo"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-4 form-group clearfix mb-3">
              <label for="emailCadastroUsuario">Email</label>
              <v-text-field
                :error-messages="error.email"
                v-model="atendente.email"
                :disabled="visualizar"
                background-color="grey lighten-2"
                readonly
                :rules="[
                  validationService.required('Campo Email é obrigatório.'),
                  validationService.isEmail('Email inválido.'),
                ]"
                :type="'email'"
                placeholder="Escreva seu email"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Perfil</label>
              <v-select
                placeholder="Selecione o perfil do usuário"
                style="padding-top: 0 !important"
                :items="listaPerfis"
                :disabled="true"
                :rules="[rules.required]"
                v-model="atendente.id_perfil"
              />
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Celular:</label>
              <v-text-field
                v-mask="'(##)#####-####'"
                :error-messages="error.telefone1"
                v-model="atendente.telefone_1"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Telefone é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="(99) 99999-9999"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Fixo:</label>
              <v-text-field
                v-mask="'(##)####-####'"
                :error-messages="error.telefone2"
                v-model="atendente.telefone_2"
                :disabled="visualizar"
                :type="'text'"
                placeholder="(99) 99999-9999"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CPF">CPF</label>
              <v-text-field
                v-mask="'###.###.###-##'"
                :error-messages="error.cpf"
                v-model="atendente.cpf"
                disabled
                :type="'text'"
                :rules="[
                  validationService.required('Campo CPF é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="000.000.000-00"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="RG">RG</label>
              <v-text-field
                :error-messages="error.rg"
                v-model="atendente.rg"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label>Data de nascimento</label>
              <v-text-field
                :error-messages="error.data_nascimento"
                v-model="atendente.data_nascimento"
                :disabled="visualizar"
                clear-icon="clear"
                :rules="[
                  validationService.required(
                    'Campo Data de nascimento é obrigatório.'
                  ),
                  validationService.isNotEmpty(),
                ]"
                style="margin: 0 5px; padding-top: 5px !important"
                :type="'date'"
                :outlined="false"
              />
              <span class="text-muted">ex: "Dia/Mês/Ano"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label class="">Gênero</label>
              <v-radio-group
                :error-messages="error.genero"
                v-model="atendente.genero"
                :rules="[
                  validationService.required('Campo Gênero é obrigatório.'),
                ]"
                :disabled="visualizar"
              >
                <v-radio label="Masculino" value="M" />
                <v-radio label="Feminino" value="F" />
                <v-radio label="Outros" value="O" />
              </v-radio-group>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado-civil">Estado civil</label>
              <v-text-field
                :disabled="visualizar"
                :error-messages="error.estadoCivil"
                v-model="atendente.estado_civil"
                clear-icon="clear"
                style="margin: 0 5px; padding-top: 5px !important"
                :type="'text'"
                :outlined="false"
              />
              <span class="text-muted"
                >ex: Casado, Solteiro, Divorciado, Viúvo"</span
              >
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CEP">CEP</label>
              <v-text-field
                v-mask="'#####-###'"
                :error-messages="error.cep"
                v-model="atendente.cep"
                @blur="searchCep"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CEP é obrigatório.'),
                ]"
                placeholder="00000-000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "xxxxx-xxx"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Rua">Logradouro</label>
              <v-text-field
                :error-messages="error.logradouro"
                v-model="atendente.logradouro"
                :disabled="visualizar"
                background-color="grey lighten-2"
                :rules="[
                  validationService.required('Campo Logradouro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Bairro">Bairro</label>
              <v-text-field
                :error-messages="error.bairro"
                v-model="atendente.bairro"
                :disabled="visualizar"
                background-color="grey lighten-2"
                :rules="[
                  validationService.required('Campo Bairro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Numero">Número</label>
              <v-text-field
                :error-messages="error.numero"
                v-model="atendente.numero"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Número é obrigatório.'),
                ]"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Complemento">Complemento</label>
              <v-text-field
                :error-messages="error.complemento"
                v-model="atendente.complemento"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Referencia">Referência</label>
              <v-text-field
                :error-messages="error.referencias"
                v-model="atendente.referencias"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Cidade">Cidade</label>
              <v-text-field
                :error-messages="error.cidade"
                v-model="atendente.cidade"
                background-color="grey lighten-2"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Cidade é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado">Estado</label>
              <v-text-field
                :error-messages="error.estado"
                v-model="atendente.estado"
                background-color="grey lighten-2"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Estado é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div
              class="col-lg-3 form-group clearfix mt-n3"
              style="padding-top: 0 !important"
            >
              <label>Status</label>
              <v-select
                outlined
                :items="statusSelect"
                item-text="descricao"
                item-value="id"
                v-model="ativo"
              />
            </div>

            <div class="col-12 form-group clearfix mb-3">
              <label class="mb-3">Unidades</label>
              <v-data-table
                v-if="!visualizar"
                v-model="unidadesRecepcionista"
                :headers="headersListaUnidades"
                :items="unidades"
                :loading="loadingData"
                item-key="cnpj"
                show-select
                dense
                class="elevation-1"
                hide-default-footer
              >
              </v-data-table>

              <v-data-table
                v-else
                :headers="headersUnidadesRecepcionista"
                :items="unidadesRecepcionista"
                :loading="loadingData"
                item-key="cnpj"
                dense
                class="elevation-1"
                hide-default-footer
              >
              </v-data-table>
            </div>

            <div class="col-12 form-group">
              <button
                v-if="!visualizar"
                class="
                  btn btn-app-primary btn-flex btn-rounded
                  font-weight-bold
                  justify-content-end
                "
                @click.prevent="atualizarAssistente()"
              >
                <span v-if="!loading"> Salvar </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button>
            </div>
          </v-form>
          <v-snackbar
            v-if="messageSnackbar"
            v-model="messageSnackbar"
            :color="typeMessageSnackbar"
            :timeout="-1"
          >
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                :color="'white'"
                v-bind="attrs"
                @click="fecharSnackbar"
              >
                Ok
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card-text>
      <v-progress-circular v-else indeterminate color="primary" size="100" />
    </v-card>
  </div>
</template>

<script>
import AtendenteService from "@/services/atendente_recepcao_service.js";
import ValidationService from "@/services/validation_service.js";
import CEPMixin from "@/mixins/cep_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import { mapActions } from "vuex";
import MedicoService from "../../services/medico_service";
import UnidadeService from "../../services/unidade_service";
import PerfilService from "../../services/perfil_service";

export default {
  mixins: [CEPMixin, DadosCadastraisMixin],
  props: {
    atendente: Object,
    visualizar: Boolean,
  },
  beforeMount() {
    this.validationService = ValidationService;
  },
  data() {
    return {
      tipoFoto: "",
      addFoto: true,
      listaPerfis: [],
      preview: "",
      foto: null,
      avatarAssistente: "",
      messageSnackbar: "",
      typeMessageSnackbar: "",
      show1: false,
      show2: false,
      loading: false,
      password: "",
      // Formulário
      formCadastro: "",
      nome: "",
      email: "",
      perfil_id: 0,
      telefone1: "",
      telefone2: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      observacoes: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      convenio: "",
      senha: "",
      headersListaUnidades: [
        {
          text: "Unidade",
          align: "start",
          sortable: true,
          value: "unidadeDescricao",
        },
      ],
      unidadesRecepcionista: [],
      headersUnidadesRecepcionista: [
        {
          text: "Unidade",
          align: "start",
          sortable: true,
          value: "razao_social",
        },
      ],
      error: {
        nome: "",
        email: "",
        telefone1: "",
        telefone2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        observacoes: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
        validationService: {},
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      loadingData: false,
      services: {
        medicoService: MedicoService.build(),
        unidadeService: UnidadeService.build(),
        perfilService: PerfilService.build(),
      },
      medicos: [],
      unidades: [],
      headersListaMedicos: [
        { text: "Médico", align: "start", sortable: true, value: "nome" },
        { text: "CPF", align: "start", sortable: false, value: "cpf" },
        {
          text: "Unidade",
          align: "start",
          sortable: true,
          value: "unidadeNome",
        },
      ],
      statusSelect: [
        { descricao: "Ativo", id: 1 },
        { descricao: "Inativo", id: 2 },
      ],
      ativo: 0,
    };
  },
  async mounted() {
    this.formCadastro = document.forms[0];
    this.CEP_MIXIN_cep = this.atendente.cep;
    this.loadingData = true;
    const [unidades, perfis] = await Promise.all([
      this.services.unidadeService.getUnidadeList(),
      this.services.perfilService.getPerfilList(),
    ]);
    this.loadingData = false;
    this.unidades = unidades;
    this.unidadesRecepcionista = this.atendente.unidades;
    this.ativo =
      this.atendente.ativo == null ? 1 : parseInt(this.atendente.ativo);
    this.listaPerfis = perfis.map(({ perfilId, descricao }) => {
      return {
        value: perfilId.toString(),
        text: descricao,
      };
    });
    // this.medicosSelected = this.listaMedicosComUnidades.filter(({ medicoId }) =>
    //   this.assistente.medicos.includes(medicoId)
    // );
  },
  computed: {
    listaMedicosComUnidades() {
      return this.medicos
        .map((medico) => ({
          ...medico,
          unidadeNome:
            (
              this.unidades.find(
                ({ pessoaJuridicaId }) => pessoaJuridicaId === medico.unidadeId
              ) || {}
            ).nomeFantasia || "",
        }))
        .filter(({ medicoId }) => {
          return !this.visualizar || this.assistente.medicos.includes(medicoId);
        });
    },
    image_perfil() {
      var src = "";
      let tipo = this.atendente.tipo;
      if (!tipo) {
        return "";
      }
      tipo = this.atendente.tipo.toLowerCase();
      switch (tipo) {
        case "png":
          src = `data:image/png;base64,${this.atendente.foto}`;
          break;
        case "jpeg":
          src = `data:image/jpeg;base64,${this.atendente.foto}`;
          break;
        case "jpg":
          src = `data:image/jpeg;base64,${this.atendente.foto}`;
          break;
        default:
          src = "https://picsum.photos/id/103/200/300";
          break;
      }

      return src;
    },
  },
  watch: {
    assistente() {
      this.CEP_MIXIN_logradouro = this.assistente.logradouro;
      this.CEP_MIXIN_bairro = this.assistente.bairro;
      this.CEP_MIXIN_cidade = this.assistente.cidade;
      this.CEP_MIXIN_estado = this.assistente.estado;
      this.CEP_MIXIN_cep = this.assistente.cep;
    },
  },

  methods: {
    searchCep() {
      this.$buscarCep(this.atendente, this.atendente.cep);
    },
    ...mapActions({
      $_ACTIONS_showSnackbarMessage: "showSnackbarMessage",
    }),
    fecharSnackbar() {
      this.messageSnackbar = "";
      this.zerarErros();
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },

    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        this.tipoFoto = file.type;
        reader.readAsDataURL(file);
      }
    },

    atualizarAssistente() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let unidades = [];
      this.unidadesRecepcionista.map((element) => {
        if (element.unidadeId != undefined) {
          const index = unidades.findIndex((find) => find == element.unidadeId);
          if (index == -1) {
            unidades.push(element.unidadeId);
          }
        } else {
          const index = unidades.findIndex(
            (find) => find == element.unidade_id
          );
          if (index == -1) {
            unidades.push(element.unidade_id);
          }
        }
      });

      var formdata = new FormData();
      // const foto = document.querySelector("input[type=file]").files[0];

      formdata.append("ativo", this.ativo);
      formdata.append("nome", this.atendente.nome);
      formdata.append("email", this.atendente.email);
      formdata.append("telefone_1", this.atendente.telefone_1);
      formdata.append("telefone_2", this.atendente.telefone_2);
      formdata.append("cpf", this.atendente.cpf);
      formdata.append("rg", this.atendente.rg);
      formdata.append("data_nascimento", this.atendente.data_nascimento);
      formdata.append("genero", this.atendente.genero);
      formdata.append("estado_civil", this.atendente.estado_civil);
      formdata.append("profissao", this.atendente.profissao);
      formdata.append("cep", this.atendente.cep);
      formdata.append("logradouro", this.atendente.logradouro);
      formdata.append("bairro", this.atendente.bairro);
      formdata.append("cidade", this.atendente.cidade);
      formdata.append("estado", this.atendente.estado);
      formdata.append("numero", this.atendente.numero);
      formdata.append("complemento", this.atendente.complemento);
      formdata.append("referencias", this.atendente.referencias);
      formdata.append("convenio", this.atendente.convenio);
      formdata.append("id_perfil", this.atendente.id_perfil);
      // formdata.append("id_medico", 1); //retirar
      // formdata.append("foto", foto);
      formdata.append("unidades", unidades.join());
      // this.medicosSelected.forEach(({ medicoId }) => {
      //   formdata.append("medicos[]", medicoId);
      // });
      this.loading = true;

      const atendenteService = new AtendenteService();
      const idAtendenteAlterada = this.atendente.id;
      const idAtendenteUsuario = this.$_GETTERS_usuario.idAtendente;
      const responseFunctions = {
        onSucess: (status) => (body) => {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });

          if (status === 200 && idAtendenteAlterada === idAtendenteUsuario) {
            const usuarioDados = this.$_GETTERS_usuario;
            usuarioDados.dados.foto = this.preview;
            usuarioDados.dados.tipo = this.tipoFoto;
            this.$_ACTIONS_setUsuario(usuarioDados);
          }
        },
        onError: (error) => {
          this.messageSnackbar = error;
          this.typeMessageSnackbar = "error";
          console.log(error);
        },
        onEnd: () => {
          this.loading = false;
          this.atendente = {};
          this.$emit("close");
        },
      };
      atendenteService.update(responseFunctions, formdata, this.atendente.id);
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
