import {
  FormatarData,
  FormatoMoeda,
  // LogoHeader,
  // FormatoPercent,
} from "./utils_docs";

// export function RelatorioComanda(resumo, detalhes, unidade) {
export function RelatorioComanda(data) {
  let comandas = [];

  for (let index = 0; index < data.comandas.length; index++) {
    const element = data.comandas[index];
    let newItem = { ...element };
    newItem.pagamentos = data.pagamentos.filter(
      (eleFil) => eleFil.comanda_id == element.id
    );
    newItem.estornos = data.estornos.filter(
      (eleFil) => eleFil.id_comanda == element.id
    );
    newItem.itens_comandas = data.itens_comandas.filter(
      (eleFil) => eleFil.comanda_id == element.id
    );
    comandas.push(newItem);
  }
  console.log("Comandas", comandas);

  let content = [];

  for (let iComanda = 0; iComanda < comandas.length; iComanda++) {
    const eleComanda = comandas[iComanda];
    content.push([
      {
        text: "Comanda",
        style: "header",
        alignment: "center",
      },
      {
        style: "tableInfo",
        layout: {
          hLineColor: "#6c6c6c",
          vLineColor: "#6c6c6c",
        },
        table: {
          widths: "*",
          headerRows: 1,
          body: [
            [
              {
                text: "Id",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Tutor",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Pet",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Abertura",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Fechamento",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Valor Total",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Valor Aberto",
                style: "tHeader",
                border: [false, true, false, false],
              },
              {
                text: "Valor Pago",
                style: "tHeader",
                border: [false, true, false, false],
              },
            ],
            [
              {
                text: eleComanda.id,
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: eleComanda.nome_tutor,
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: eleComanda.nome_pet,
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: FormatarData(eleComanda.data_criada),
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: FormatarData(eleComanda.data_fechada),
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: getValor("total", eleComanda),
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: getValor("aberto", eleComanda),
                style: "cHeader",
                border: [false, false, false, false],
              },
              {
                text: getValor("pago", eleComanda),
                style: "cHeader",
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        text: `Resumo Comanda`,
        style: "titleType",
      },
      {
        columns: [
          {
            width: "25%",
            text: "",
          },
          {
            table: {
              body: [
                [
                  {
                    text: "Forma Pagamento",
                    style: "tInfo",
                    border: [false, true, false, false],
                  },
                  {
                    text: "Valor",
                    style: "tInfo",
                    border: [false, true, false, false],
                  },
                ],
                [
                  {
                    text: "Dinheiro",
                    style: "cInfo",
                    border: [false, false, false, false],
                    fillColor: "lightgray",
                  },
                  {
                    text: "R$: 0,00",
                    style: "cInfo",
                    border: [false, false, false, false],
                    fillColor: "lightgray",
                  },
                ],
                [
                  {
                    text: "Pix",
                    style: "cInfo",
                    border: [false, false, false, false],
                  },
                  {
                    text: "R$: 0,00",
                    style: "cInfo",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: "Cartão de Crédito",
                    style: "cInfo",
                    border: [false, false, false, false],
                    fillColor: "lightgray",
                  },
                  {
                    text: "R$: 0,00",
                    style: "cInfo",
                    border: [false, false, false, false],
                    fillColor: "lightgray",
                  },
                ],
                [
                  {
                    text: "Cartão de Débito",
                    style: "cInfo",
                    border: [false, false, false, false],
                  },
                  {
                    text: "R$: 0,00",
                    style: "cInfo",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: "Crédito Cliente",
                    style: "cInfo",
                    border: [false, false, false, false],
                    fillColor: "lightgray",
                  },
                  {
                    text: "R$: 0,00",
                    style: "cInfo",
                    border: [false, false, false, false],
                    fillColor: "lightgray",
                  },
                ],
                [
                  {
                    text: "Total Recebido *(não inclui Crédito Cliente)",
                    style: "cInfo",
                    bold: true,
                    border: [false, false, false, false],
                  },
                  {
                    text: "R$: 0,00",
                    style: "cInfo",
                    border: [false, false, false, false],
                  },
                ],
              ],
              widths: ["*", "auto"],
            },
            style: "tableMargin",
            layout: {
              hLineColor: "#6c6c6c",
              vLineColor: "#6c6c6c",
            },
          },
          {
            width: "25%",
            text: "",
          },
        ],
      },
    ]);
    if (eleComanda.itens_comandas && eleComanda.itens_comandas.length > 0) {
      let bodyItens = [];
      bodyItens.push([
        {
          text: "Tipo",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Procedimento",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Convênio",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Data - Hora de Requisição",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Data - Hora de Execução",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Data - Hora do Pagamento",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Status",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Forma de pagamento",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Valor Original",
          style: "tInfo",
          border: [false, false, false, true],
        },
        {
          text: "Valor Cobrado",
          style: "tInfo",
          border: [false, false, false, true],
        },
      ]);
      for (
        let iItensCom = 0;
        iItensCom < eleComanda.itens_comandas.length;
        iItensCom++
      ) {
        const eleItemCom = eleComanda.itens_comandas[iItensCom];
        bodyItens.push([
          {
            text: eleItemCom.tipo_procedimento_descricao,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text: eleItemCom.procedimento_descricao,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text: eleItemCom.tipo_convenio_descricao,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text:
              eleItemCom.data_requisicao == "1900-01-01"
                ? "-"
                : `${FormatarData(eleItemCom.data_requisicao)} - ${
                    eleItemCom.hora_requisicao
                  }`,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text:
              eleItemCom.data_pagamento == "1900-01-01"
                ? "-"
                : `${FormatarData(eleItemCom.data_pagamento)} - ${
                    eleItemCom.hora_pagamento
                  }`,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text:
              eleItemCom.data_execucao == "1900-01-01"
                ? "-"
                : `${FormatarData(eleItemCom.data_execucao)} - ${
                    eleItemCom.hora_execucao
                  }`,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text: eleItemCom.status_descricao,
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text: "-",
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text: FormatoMoeda(eleItemCom.valor_original),
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
          {
            text: FormatoMoeda(eleItemCom.valor_cobrado),
            style: "cInfo",
            border: [false, false, false, false],
            fillColor: iItensCom % 2 === 0 ? null : "lightgray",
          },
        ]);
      }
      content.push([
        {
          text: `Itens da Comanda`,
          style: "titleType",
        },
        {
          table: {
            body: bodyItens,
            widths: "*",
          },
          style: "tableMargin",
          layout: {
            hLineColor: "#6c6c6c",
            vLineColor: "#6c6c6c",
          },
        },
      ]);
    } else {
      content.push({
        text: `Sem Registros de Itens de Comanda`,
        style: "subTitle",
      });
    }
    if (eleComanda.estornos && eleComanda.estornos.length > 0) {
      content.push({
        text: `Estornos`,
        style: "titleType",
      });
      for (
        let iEstorno = 0;
        iEstorno < eleComanda.estornos.length;
        iEstorno++
      ) {
        const eleEstorno = eleComanda.estornos[iEstorno];
        let itensEstornos = [
          [
            {
              text: "Itens",
              style: "tInfo",
              border: [true, false, true, false],
              fillColor: "",
              colSpan: 5,
              alignment: "center",
            },
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: "Procedimento",
              style: "tInfo",
              border: [true, false, false, false],
              fillColor: "lightgray",
              colSpan: 3,
              alignment: "center",
            },
            {},
            {},
            {
              text: "Valor do Item",
              style: "tInfo",
              border: [false, false, true, false],
              fillColor: "lightgray",
              colSpan: 2,
              alignment: "center",
            },
            {},
          ],
          [
            {
              text: "Hemograma",
              style: "cInfo",
              border: [true, false, false, false],
              fillColor: "",
              colSpan: 3,
              alignment: "center",
            },
            {},
            {},
            {
              text: "R$ 40,00",
              style: "cInfo",
              border: [false, false, true, false],
              fillColor: "",
              colSpan: 2,
              alignment: "center",
            },
            {},
          ],
        ];
        // for (let iDetalhe = 0; iDetalhe < eleEstorno.detalhe.length; iDetalhe++) {
        //   const eleDetalhe = eleEstorno.detalhe[iDetalhe];
        //   itensEstornos.push([
        //     {
        //       text: eleDetalhe.descricao,
        //       style: "cInfo",
        //       border: [true, false, false, false],
        //       fillColor: iDetalhe % 2 === 0 ? "lightgray": null,
        //       colSpan: 3,
        //       alignment: "center",
        //     },
        //     {},
        //     {},
        //     {
        //       text: FormatoMoeda(eleDetalhe.valor),
        //       style: "cInfo",
        //       border: [false, false, true, false],
        //       fillColor: iDetalhe % 2 === 0 ? "lightgray": null,
        //       colSpan: 2,
        //       alignment: "center",
        //     },
        //     {},
        //   ],)
        // }
        content.push({
          table: {
            body: [
              [
                {
                  text: "Tipo de Estorno",
                  style: "tInfo",
                  border: [true, true, false, false],
                },
                {
                  text: "Data Criação",
                  style: "tInfo",
                  border: [false, true, false, false],
                  fillColor: "",
                },
                {
                  text: "Hora Criação",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Executante",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Valor",
                  style: "tInfo",
                  border: [false, true, true, false],
                },
              ],
              [
                {
                  text: eleEstorno.descricao,
                  style: "cInfo",
                  border: [true, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  // text: eleEstorno.dataCriacao ,
                  text: "01/01/2000",
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  // text: eleEstorno.horaCriacao ,
                  text: "12:10:22",
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  // text: eleEstorno.executor ,
                  text: "Teste",
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  text: FormatoMoeda(eleEstorno.total),
                  style: "cInfo",
                  border: [false, false, true, false],
                  fillColor: "lightgray",
                },
              ],
              ...itensEstornos,
            ],
            widths: "*",
          },
          // style: "tableMargin",
          layout: {
            hLineColor: "#6c6c6c",
            vLineColor: "#6c6c6c",
          },
        });
        content.push({
          table: {
            widths: ["*"],
            body: [[{ text: "", border: [false, true, false, false] }]],
          },
          layout: {
            hLineColor: "#6c6c6c",
            vLineColor: "#6c6c6c",
          },
        });
      }
    } else {
      content.push({
        text: `Sem Registros de estornos`,
        style: "subTitle",
      });
    }
    if (eleComanda.pagamentos && eleComanda.pagamentos.length > 0) {
      content.push({
        text: `Pagamentos`,
        style: "titleType",
      });
      for (
        let iPagamentos = 0;
        iPagamentos < eleComanda.pagamentos.length;
        iPagamentos++
      ) {
        const elePagamento = eleComanda.pagamentos[iPagamentos];
        let itensPagamento = [
          [
            {
              text: "Formas Pagamento",
              style: "tInfo",
              border: [true, false, true, false],
              fillColor: "",
              colSpan: 8,
              alignment: "center",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: "Forma de pagamento",
              style: "tInfo",
              border: [true, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "Valor",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "Dinheiro",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "Troco",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "Maquineta",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "Débito/Crédito",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "Cod Auth",
              style: "tInfo",
              border: [false, false, true, false],
              fillColor: "lightgray",
              colSpan: 2,
              alignment: "center",
            },
            {},
          ],
          [
            {
              text: "Dinheiro",
              style: "tInfo",
              border: [true, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "R$ 84,32",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "R$ 84,32",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "R$ 15,68",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "N",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "",
              style: "tInfo",
              border: [false, false, false, false],
              fillColor: "lightgray",
              alignment: "center",
            },
            {
              text: "autocod__6622a87f58444",
              style: "tInfo",
              border: [false, false, true, false],
              fillColor: "lightgray",
              colSpan: 2,
              alignment: "center",
            },
            {},
          ],
        ];
        // for (let iFormaPag = 0; iFormaPag < elePagamento.formaPagamento.length; iFormaPag++) {
        //   const eleFormaPagamento = elePagamento.formaPagamento[iFormaPag];
        //   itensPagamento.push( [
        //   {
        //     text: eleFormaPagamento.forma,
        //     style: "tInfo",
        //     border: [true, false, false, false],
        //     fillColor: "lightgray",
        //     alignment: "center",
        //   },
        //   {
        //     text: formatMoeda(eleFormaPagamento.valor),
        //     style: "tInfo",
        //     border: [false, false, false, false],
        //     fillColor: "lightgray",
        //     alignment: "center",
        //   },
        //   {
        //     text: formatMoeda(eleFormaPagamento.dinheiro),
        //     style: "tInfo",
        //     border: [false, false, false, false],
        //     fillColor: "lightgray",
        //     alignment: "center",
        //   },
        //   {
        //     text: formatMoeda(eleFormaPagamento.troco),
        //     style: "tInfo",
        //     border: [false, false, false, false],
        //     fillColor: "lightgray",
        //     alignment: "center",
        //   },
        //   {
        //     text: formatMoeda(eleFormaPagamento.Maquineta),
        //     style: "tInfo",
        //     border: [false, false, false, false],
        //     fillColor: "lightgray",
        //     alignment: "center",
        //   },
        //   {
        //     text: formatMoeda(eleFormaPagamento.tipoCredDeb),
        //     style: "tInfo",
        //     border: [false, false, false, false],
        //     fillColor: "lightgray",
        //     alignment: "center",
        //   },
        //   {
        //     text: formatMoeda(eleFormaPagamento.valor),
        //     style: "tInfo",
        //     border: [false, false, true, false],
        //     fillColor: "lightgray",
        //     colSpan: 2,
        //     alignment: "center",
        //   },
        //   {
        //   },
        // ])
        // }
        console.log("elePag ", elePagamento);
        content.push({
          table: {
            body: [
              [
                {
                  text: "Tipo",
                  style: "tInfo",
                  border: [true, true, false, false],
                },
                {
                  text: "Data Criação",
                  style: "tInfo",
                  border: [false, true, false, false],
                  fillColor: "",
                },
                {
                  text: "Hora Criação",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Executante",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Valor Original",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Desconto Valor",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Desconto (%)",
                  style: "tInfo",
                  border: [false, true, false, false],
                },
                {
                  text: "Valor",
                  style: "tInfo",
                  border: [false, true, true, false],
                },
              ],
              [
                {
                  // text: elePagamento.descricao,
                  text: "pagamento",
                  style: "cInfo",
                  border: [true, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  // text: elePagamento.dataCriacao ,
                  text: "01/01/2000",
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  // text: elePagamento.horaCriacao ,
                  text: "12:10:22",
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  // text: elePagamento.executor ,
                  text: "Teste",
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  text: FormatoMoeda("100"),
                  // text: FormatoMoeda(elePagamento.vOri),
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  text: FormatoMoeda("15.68"),
                  // text: FormatoMoeda(elePagamento.vDesconto),
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  text: "15,68%",
                  // text: FormatoMoeda(elePagamento.vDescontoPercent),
                  style: "cInfo",
                  border: [false, false, false, false],
                  fillColor: "lightgray",
                },
                {
                  text: FormatoMoeda("84.32"),
                  // text: FormatoMoeda(elePagamento.valor),
                  style: "cInfo",
                  border: [false, false, true, false],
                  fillColor: "lightgray",
                },
              ],
              ...itensPagamento,
            ],
            widths: "*",
          },
          // style: "tableMargin",
          layout: {
            hLineColor: "#6c6c6c",
            vLineColor: "#6c6c6c",
          },
        });
        content.push({
          table: {
            widths: ["*"],
            body: [[{ text: "", border: [false, true, false, false] }]],
          },
          layout: {
            hLineColor: "#6c6c6c",
            vLineColor: "#6c6c6c",
          },
        });
      }
    } else {
      content.push({
        text: `Sem Registros de pagamentos`,
        style: "subTitle",
      });
    }
  }

  // if (detalhes.descontos != null) {
  //   if (detalhes.descontos.length > 0) {
  //     let lista = [];
  //     for (let index = 0; index < detalhes.descontos.length; index++) {
  //       const element = detalhes.descontos[index];
  //       if (
  //         element.tipo_desconto_id == "2" ||
  //         element.tipo_desconto_id == "3"
  //       ) {
  //         lista.push(element);
  //       }
  //     }
  //     detalhes.descontos = lista;
  //   }
  // }

  //   let keysArr = getArrKey(resumo);

  //   content.push(getHeader(unidade));
  //   // content.push({
  //   //   text: `Relatório de Caixa`,
  //   //   style: "titleRelatorio",
  //   // });
  //   let fechamento = "-";
  //   if (resumo.data_fechamento != "1900-01-01") {
  //     fechamento = `${FormatarData(resumo.data_fechamento)} - ${
  //       resumo.hora_fechamento
  //     }`;
  //   }

  //   content.push({
  //     style: "tableInfo",
  //     layout: {
  //       hLineColor: "#6c6c6c",
  //       vLineColor: "#6c6c6c",
  //     },
  //     table: {
  //       widths: "*",
  //       headerRows: 1,

  //       body: [
  //         [
  //           {
  //             text: "Usuário",
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: "Caixa",
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: "Unidade",
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: "Abertura",
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: "Fechamento",
  //             style: "tInfo",
  //             border: [false, false, false, false],
  //           },
  //         ],
  //         [
  //           {
  //             text: resumo.usuario,
  //             style: "cInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: resumo.id_caixa,
  //             style: "cInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: resumo.unidade,
  //             style: "cInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: `${FormatarData(resumo.data_abertura)} - ${
  //               resumo.hora_abertura
  //             }`,
  //             style: "cInfo",
  //             border: [false, false, true, false],
  //           },
  //           {
  //             text: fechamento,
  //             style: "cInfo",
  //             border: [false, false, false, false],
  //           },
  //         ],
  //       ],
  //     },
  //   });
  //   content.push({
  //     margin: [0, 0, 0, 20],
  //     layout: {
  //       hLineColor: "#6c6c6c",
  //       vLineColor: "#6c6c6c",
  //     },
  //     table: {
  //       widths: "*",
  //       headerRows: 1,
  //       body: [
  //         [
  //           {
  //             text: `Saldo Abertura ${FormatoMoeda(resumo.saldo_abertura)}`,
  //             style: "tInfo",
  //             border: [false, false, true, true],
  //             margin: [0, 4, 0, 4],
  //           },
  //           {
  //             text: `Total Entradas ${FormatoMoeda(resumo.entradas)}`,
  //             style: "tInfo",
  //             border: [false, false, true, true],
  //             margin: [0, 4, 0, 4],
  //           },
  //           {
  //             text: `Total Sangrias ${FormatoMoeda(resumo.sangrias)}`,
  //             style: "tInfo",
  //             border: [false, false, true, true],
  //             margin: [0, 4, 0, 4],
  //           },
  //           {
  //             text: `Total Recebimentos ${FormatoMoeda(resumo.recebimentos)}`,
  //             style: "tInfo",
  //             border: [false, false, false, true],
  //             margin: [0, 4, 0, 4],
  //           },
  //         ],
  //         [
  //           {
  //             text: `Total Troco ${FormatoMoeda(resumo.total_troco)}`,
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //             margin: [0, 4, 0, 4],
  //           },
  //           {
  //             text: `Total Desconto ${FormatoMoeda(resumo.descontos)}`,
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //             margin: [0, 4, 0, 4],
  //           },
  //           {
  //             text: `Total Estornos ${FormatoMoeda(resumo.estornos_total)}`,
  //             style: "tInfo",
  //             border: [false, false, true, false],
  //             margin: [0, 4, 0, 4],
  //           },
  //           {
  //             text: `Saldo Dinheiro ${FormatoMoeda(resumo.saldo)}`,
  //             style: "tInfo",
  //             border: [false, false, false, false],
  //             margin: [0, 4, 0, 4],
  //           },
  //         ],
  //       ],
  //     },
  //   });

  //   for (let indexKey = 0; indexKey < keysArr.length; indexKey++) {
  //     const elementKey = keysArr[indexKey];
  //     content.push({
  //       style: "tableHeader",
  //       layout: {
  //         hLineColor: "#6c6c6c",
  //         vLineColor: "#6c6c6c",
  //       },
  //       table: {
  //         widths: "*",
  //         headerRows: 1,
  //         body: [
  //           [
  //             {
  //               text: elementKey.keyDesc,
  //               style: "header",
  //               border: [false, false, false, true],
  //             },
  //           ],
  //         ],
  //       },
  //     });
  //     if (detalhes[elementKey.key] && detalhes[elementKey.key].length > 0) {
  //       if (elementKey.activeHeader) {
  //         let headerItens = [];
  //         headerItens = detalheHeader(elementKey.header);
  //         content.push({
  //           table: {
  //             body: headerItens,
  //             widths: "*",
  //           },
  //           style: "tableMargin",
  //           layout: {
  //             hLineColor: "#6c6c6c",
  //             vLineColor: "#6c6c6c",
  //           },
  //         });
  //       }
  //       let bodyItens = [];
  //       bodyItens = detalheBody(
  //         detalhes[elementKey.key],
  //         elementKey.table,
  //         elementKey.tableObs
  //       );
  //       content.push({
  //         table: {
  //           body: bodyItens,
  //           widths: "*",
  //         },
  //         style: "tableMargin",
  //         layout: {
  //           hLineColor: "#6c6c6c",
  //           vLineColor: "#6c6c6c",
  //         },
  //       });
  //     } else {
  //       content.push({
  //         text: `Sem Registros de ${elementKey.key}`,
  //         style: "subTitle",
  //       });
  //     }
  //   }

  let modelo = {
    content: content,
    styles: {
      titleRelatorio: {
        fontSize: 12,
        bold: true,
        alignment: "center",
        margin: [0, 10, 0, 15],
      },
      tableInfo: {
        margin: [0, 0, 0, 5],
      },
      tHeader: {
        fontSize: 7,
        bold: true,
      },
      cHeader: {
        fontSize: 7,
      },
      tInfo: {
        fontSize: 6,
        bold: true,
      },
      cInfo: {
        fontSize: 6,
      },
      header: {
        fontSize: 14,
        bold: true,
        alignment: "center",
        margin: [0, 2, 0, 2],
      },
      tableHeader: {
        margin: [0, 5, 0, 15],
      },
      tableMargin: {
        margin: [0, 10, 0, 15],
      },
      titleType: {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: [0, 10, 0, 10],
      },
      subTitle: {
        fontSize: 10,
        alignment: "center",
        margin: [0, 0, 0, 20],
      },
      tableH: {
        fontSize: 8,
        bold: true,
        alignment: "center",
        margin: [0, 2, 0, 2],
      },
      tableC: {
        fontSize: 8,
        alignment: "center",
        margin: [0, 2, 0, 2],
      },
      tableO: {
        fontSize: 8,
        alignment: "left",
        margin: [0, 2, 0, 2],
      },
    },
    defaultStyle: {
      fontSize: 9,
    },
    footer: function(currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              text: `id`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };

  return modelo;
}

function getValor(tipo, element) {
  let estornos = element.valor_total_estornos
    ? parseFloat(element.valor_total_estornos)
    : 0;
  let pago = element.valor_pago ? parseFloat(element.valor_pago) : 0;
  let aberto = element.valor_em_aberto
    ? parseFloat(element.valor_em_aberto)
    : 0;
  switch (tipo) {
    case "total":
      return FormatoMoeda(aberto + (pago - estornos));
    case "aberto":
      return FormatoMoeda(aberto);
    case "pago":
      return FormatoMoeda(pago - estornos);
    default:
      return FormatoMoeda(0);
  }
}

// function detalheHeader(header) {
//   let headerContent = [];
//   let itemDesc = [];
//   let itemValor = [];
//   for (let indexDesc = 0; indexDesc < header.length; indexDesc++) {
//     const elementDesc = header[indexDesc];
//     itemDesc.push({
//       text: elementDesc.descricao,
//       style: "tableH",
//       border: [false, false, false, true],
//     });
//     itemValor.push({
//       text: elementDesc.valor,
//       style: "tableC",
//       border: [false, false, false, false],
//     });
//   }
//   headerContent.push(itemDesc);
//   headerContent.push(itemValor);
//   return headerContent;
// }

// function detalheBody(detalhesKey, tableKey, tableObs) {
//   let content = [];
//   let header = [];
//   let body = [];
//   for (let index = 0; index < tableKey.length; index++) {
//     const elementHeader = tableKey[index];
//     header.push({
//       text: elementHeader.descricao,
//       style: "tableH",
//       border: [false, false, false, true],
//     });
//   }
//   content.push(header);
//   for (let index = 0; index < detalhesKey.length; index++) {
//     const elementBody = detalhesKey[index];
//     body = [];
//     for (let indexKey = 0; indexKey < tableKey.length; indexKey++) {
//       const key = tableKey[indexKey].valor;
//       const tipo = tableKey[indexKey].tipo;
//       let valor = "";
//       if (tipo == "date") {
//         valor = FormatarData(elementBody[key]);
//       } else if (tipo == "number") {
//         valor = FormatoMoeda(elementBody[key]);
//       } else if (tipo == "percent") {
//         valor = FormatoPercent(elementBody[key]);
//       } else {
//         valor = elementBody[key];
//       }
//       body.push({
//         text: valor,
//         style: "tableC",
//         border: [false, false, false, false],
//         fillColor: index % 2 === 0 ? "lightgray" : null,
//       });
//     }
//     content.push(body);
//     if (tableObs) {
//       body = [];
//       const length = tableKey.length;
//       body.push({
//         text: `Observação: ${elementBody.observacao}`,
//         colSpan: length,
//         style: "tableO",
//         border: [false, false, false, false],
//         fillColor: index % 2 === 0 ? "lightgray" : null,
//       });
//       for (let count = 0; count < -length; count++) {
//         body.push({});
//       }
//       content.push(body);
//     }
//   }
//   // console.log(content);

//   return content;
// }

// function getHeader(unidade) {
//   return {
//     style: "titleRelatorio",
//     table: {
//       widths: "*",
//       body: [
//         [
//           {
//             stack: [
//               {
//                 image: LogoHeader(false),
//                 width: 50,
//               },
//             ],
//             alignment: "left",
//             border: [false, false, false, false],
//           },
//           {
//             stack: [
//               {
//                 text: `Relatório de Caixa`,
//               },
//             ],
//             border: [false, false, false, false],
//           },
//           {
//             stack: [
//               {
//                 width: "*",
//                 text: [
//                   {
//                     text: `Unidade: ${unidade.nome_fantasia}\n`,
//                     fontSize: 8,
//                     alignment: "right",
//                   },
//                   {
//                     text: `${unidade.logradouro}, ${unidade.numero} - ${unidade.cidade}\n`,
//                     fontSize: 8,
//                     alignment: "right",
//                   },
//                   {
//                     text: `CEP: ${unidade.cep}\n`,
//                     fontSize: 8,
//                     alignment: "right",
//                   },
//                   {
//                     text: `Tel: ${unidade.telefone}\n`,
//                     fontSize: 8,
//                     alignment: "right",
//                   },
//                   {
//                     text: `Cnpj: ${unidade.cnpj}\n`,
//                     fontSize: 8,
//                     alignment: "right",
//                   },
//                 ],
//               },
//             ],
//             border: [false, false, false, false],
//           },
//         ],
//       ],
//     },
//   };
// }

// function getArrKey(resumo) {
//   const arr = [
//     {
//       key: "recebimentos",
//       keyDesc: "Recebimentos",
//       activeHeader: true,
//       header: [
//         {
//           descricao: "Dinheiro",
//           valor: FormatoMoeda(resumo.recebimentos_dinheiro),
//         },
//         {
//           descricao: "Crédito Cliente ",
//           valor: FormatoMoeda(resumo.recebimento_credito_cliente),
//         },
//         {
//           descricao: "Cartão Crédito",
//           valor: FormatoMoeda(resumo.recebimento_credito),
//         },
//         {
//           descricao: "Cartão Debito ",
//           valor: FormatoMoeda(resumo.recebimento_debito),
//         },
//         { descricao: "Pix", valor: FormatoMoeda(resumo.recebimento_pix) },
//       ],
//       tableObs: false,
//       table: [
//         {
//           valor: "forma_pagamento",
//           descricao: "Forma Pagamento",
//           tipo: "string",
//         },
//         { valor: "maquineta", descricao: "Maquineta", tipo: "string" },
//         { valor: "codauth", descricao: "COd. Aut.", tipo: "string" },
//         { valor: "valor", descricao: "Valor", tipo: "number" },
//         { valor: "data_movimento", descricao: "Data", tipo: "date" },
//         { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
//       ],
//     },
//     {
//       key: "entradas",
//       keyDesc: "Entradas",
//       activeHeader: false,
//       header: [],
//       tableObs: true,
//       table: [
//         { valor: "valor", descricao: "Valor", tipo: "number" },
//         { valor: "data_movimento", descricao: "Data", tipo: "date" },
//         { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
//         // { valor: "observacao", descricao: "Observação" },
//       ],
//     },
//     {
//       key: "sangrias",
//       keyDesc: "Sangrias",
//       activeHeader: false,
//       header: [],
//       tableObs: true,
//       table: [
//         { valor: "valor", descricao: "Valor", tipo: "number" },
//         { valor: "data_movimento", descricao: "Data", tipo: "date" },
//         { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
//         // { valor: "observacao", descricao: "Observação" },
//       ],
//     },
//     {
//       key: "estornos",
//       keyDesc: "Estornos",
//       activeHeader: true,
//       header: [
//         {
//           descricao: "Dinheiro",
//           valor: FormatoMoeda(resumo.estornos_dinheiro),
//         },
//         {
//           descricao: "Crédito Cliente ",
//           valor: FormatoMoeda(resumo.estornos_credito_cliente),
//         },
//         {
//           descricao: "Cartão Crédito",
//           valor: FormatoMoeda(resumo.estornos_cartao_credito),
//         },
//         {
//           descricao: "Cartão Débito ",
//           valor: FormatoMoeda(resumo.estornos_cartao_debito),
//         },
//         { descricao: "Pix", valor: FormatoMoeda(resumo.estornos_pix) },
//       ],
//       tableObs: false,
//       table: [
//         { valor: "descricao", descricao: "Descrição", tipo: "string" },
//         { valor: "valor", descricao: "Valor", tipo: "number" },
//         { valor: "data_movimento", descricao: "Data", tipo: "date" },
//         { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
//       ],
//     },
//     {
//       key: "descontos",
//       keyDesc: "Descontos",
//       activeHeader: false,
//       header: [],
//       tableObs: false,
//       table: [
//         { descricao: "ID comanda", valor: "comanda_id", tipo: "string" },
//         { descricao: "Sem desconto", valor: "valor_original", tipo: "number" },
//         { descricao: "Desconto", valor: "desconto_valor", tipo: "number" },
//         {
//           descricao: "Porcentagem",
//           valor: "desconto_percent",
//           tipo: "percent",
//         },
//         { descricao: "Valor pago", valor: "valor", tipo: "number" },
//       ],
//     },
//   ];
//   return arr;
// }
