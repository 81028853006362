<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Médicos Parceiro</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="medicos"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            persistent
            fullscreen
            v-model="dialogCadastrar"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                color="#1daf80"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Novo Médico
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarMedicosPosCadastro"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este Médico?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1daf80" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <!-- <div class="d-flex mt-5">
            <v-select
              v-model="filtroStatus"
              :items="listStatus"
              item-text="label"
              item-value="id"
              class="mr-5 col-4"
            />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </div> -->
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            fullscreen
            hide-overlay
            max-width="500px"
            v-if="dialogEdicao"
            v-model="dialogEdicao"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :medicoId="editedMedicoId"
              @close="closeDialogEdited"
              @response="atualizarMedicosPosEdicao($event)"
            />
          </v-dialog>
          <!-- Dialog Altera Senha -->
          <v-dialog
            v-model="dialogAltSenha"
            hide-overlay
            transition="dialog-bottom-transition"
            max-width="500"
          >
            <modal-alterar-senha
              v-if="dialogAltSenha"
              :infoUsuario="infoUsuarioSelected"
              @close="(infoUsuarioSelected = {}), (dialogAltSenha = false)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="idPerfil == 1"
          icon
          small
          @click="editSenha(item)"
          class="mr-2"
        >
          <v-icon small title="Alterar Senha">
            mdi mdi-form-textbox-password
          </v-icon>
        </v-btn>
        <!-- <v-btn
          v-if="allowEditarBtn"
          icon
          small
          @click="editItem(item)"
          class="mr-2"
        >
          <v-icon small title="Editar">
            mdi-pencil
          </v-icon>
        </v-btn> -->
        <!-- <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon> -->
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from "@/components/medicos_parceiro/modal_cadastro";
import modalEdicao from "@/components/medicos_parceiro/modal_edicao";
import MedicoParceiroService from "../services/medico_parceiro_service.js";
import AuthService from "../services/auth_service";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import endpoints from "@/router/endpoints";
import ModalAlterarSenha from "@/components/senha/modal-alterar-senha.vue";

export default {
  mixins: [Mixin],
  components: { modalCadastro, modalEdicao, ModalAlterarSenha },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    dialog: false,
    dialogObs: false,
    editedMedicoId: 0,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    dialogCadastro: false,
    medico: {},
    medicos: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf", sortable: false },
      // { text: "Tipo", value: "tipo", sortable: false },
      {
        text: "E-mail",
        value: "email",
        sortable: false,
      },
      {
        text: "Gênero",
        value: "genero",
        sortable: false,
      },
      {
        text: "Status",
        value: "ativo",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: false,
    services: {
      medicoParceiroService: MedicoParceiroService.build(),
      authService: AuthService.build(),
    },
    listStatus: [
      { id: 100, label: "Todos" },
      { id: 1, label: "Ativos" },
      { id: 2, label: "Inativos" },
    ],
    filtroStatus: 100,
    idPerfil: JSON.parse(sessionStorage.getItem("vuex")).perfil.id,
    infoUsuarioSelected: {},
    dialogAltSenha: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarMedicos();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarMedicos();
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarMedicos();
      },
      deep: true,
    },
    filtroStatus() {
      this.atualizarMedicos();
    },
  },

  mounted() {
    this.atualizarMedicos();
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["MedicoController::POST->register"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["MedicoController::POST->updateMedicoData/$1"]
      );
    },
    allowDeletarBtn() {
      return false; // Médicos não podem ser deletados.
    },
  },
  methods: {
    atualizarMedicosPosCadastro() {
      this.atualizarMedicos();
      this.closeDialogCadastrar();
    },
    atualizarMedicosPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarMedicos();
    },
    async atualizarMedicos() {
      this.loading = true;
      await this.services.medicoParceiroService
        .getMedicosPaginatedList(this.filtroStatus, {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.order,
          desc: this.options.desc,
          nome: this.search,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          // const { pagination, medicos } = data;
          // this.totalList = pagination.num_rows;
          // this.medicos = medicos.map((medico) => {
          const { paginacao, dados } = data;
          this.totalList = paginacao.num_rows;
          this.medicos = dados.map((medico) => {
            return {
              medicoId: medico.id,
              nome: medico.nome,
              cpf: medico.cpf,
              tipo: medico.tipo,
              email: medico.email,
              genero: medico.genero,
              ativo: medico.ativo == 1 ? "Ativo" : "Inativo",
              perfil_id: medico.perfil_id,
              perfil_descricao: medico.perfil_descricao,
              usuario_id: medico.usuario_id,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(item) {
      this.editedMedicoId = parseInt(item.medicoId) || 0;
      // this.editedMedicoId = item;
      this.dialogEdicao = true;
    },

    deleteItem({ medicoId }) {
      this.medicoIdToDelete = medicoId;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.medicoParceiroService
        .deletarmedico(this.medicoIdToDelete)
        .then(async ({ status }) => {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? "O médico foi deletado com sucesso."
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
          status === 200 && (await this.atualizarPerfis());
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete();
        });
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editSenha(item) {
      this.infoUsuarioSelected = {
        id: item.medicoId,
        perfilId: item.perfil_id,
        nome: item.nome,
        email: item.email,
        idUsuario: item.usuario_id,
      };
      this.dialogAltSenha = true;
    },
  },
};
</script>
