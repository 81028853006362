<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #1daf80 !important; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Termos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div>
            <v-select
              v-model="convenioSelecionado"
              :items="convenios"
              label="Clínica"
              class="mb-0"
            >
            </v-select>
            <v-select
              @change="atualizarProcedimentos"
              v-model="tipo"
              :items="tipos_termos"
              return-object
              item-text="descricao"
              label="Tipo de termo"
            ></v-select>
          </div>
          <!-- <div v-if="tipo != 'termo_dois'">
            <v-select
              v-model="procedimento"
              :items="procedimentos"
              item-value="descricao"
              item-text="descricao"
              label="Procedimento"
              class="col-10"
              :loading="loading"
            ></v-select>
          </div> -->
        </v-container>
        <div class="p-1">
          <div class="text-right">
            <v-btn
              text
              rounded
              color="error"
              class="mr-4"
              @click="$emit('close')"
            >
              Cancelar
            </v-btn>
            <v-btn rounded dark class="bg-verde " @click="gerarPdf()">
              <span> Gerar PDF </span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";

export default {
  beforeMount() {
    this.atualizarProcedimentos();
  },

  mounted() {
    this.atualizarProcedimentos();
  },

  data: () => ({
    tipo: "",
    tipos_termos: [
      { cod: "termo_um", descricao: "Autorização para Eutanásia" },
      { cod: "termo_dois", descricao: "Autorização para Transfusão Sanguínea" },
      {
        cod: "termo_tres",
        descricao:
          "Termo de consentimento livre e esclarecido para transfusão sanguínea",
      },
      { cod: "termo_quatro", descricao: "Termo de Compromisso" },
      {
        cod: "termo_cinco",
        descricao:
          "Termo de Consentimento Livre e Esclarecido Para Realização de Procedimento Cirúrgico",
      },
      {
        cod: "termo_seis",
        descricao: "Retirada do animal da Internação",
      },
    ],
    procedimentos: [{ descricao: "Cirurgia teste" }],
    procedimento: "",
    // convenios: ["Público", "Particular"],
    // convenioSelecionado: "",
    convenios: ["Centro de Olhos", "Diagnóstico Veterinário"],
    convenioSelecionado: "Centro de Olhos",
  }),
  methods: {
    atualizarProcedimentos() {
      const procedimentoService = new ProcedimentoService();
      this.loading = true;
      this.procedimentos = [];
      procedimentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.procedimentos = body.data.procedimentos;
          } else {
            console.log(body);
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd: () => {
          this.loading = false;
        },
      });
    },
    gerarPdf() {
      if (this.tipo == "") {
        console.log("Escolher um  tipo de termo");
        return;
      }
      this.$emit("gerarPDF", {
        doc: "termo",
        termo_tipo: this.tipo.cod,
        termo: this.tipo.descricao,
        procedimento: this.procedimento,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.tipo = "";
      this.procedimento = "";
      this.procedimentos = [];
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
