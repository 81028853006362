<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between align-center">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Orçamento</h3>
        <v-btn class="bg-verde" rounded dark @click="showDialogNovoOrcamento"
          >Novo Orçamento</v-btn
        >
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="mt-5 w-50 d-flex align-items-center" style="gap: 1rem">
        <v-text-field
          label="ID"
          placeholder="Id Orçamento"
          outlined
          class="col-3"
          title="Id Orçamento"
          v-model="searchedIdOrcamento"
        ></v-text-field>
        <v-autocomplete
          label="Cliente"
          outlined
          :loading="loadingSearch"
          v-model="tutorSelected"
          :items="tutoresList"
          item-text="nome"
          item-value="id_cliente"
          :search-input.sync="searchTutor"
          @keydown.enter="searchTutorbyName(searchTutor)"
          hide-no-data
          hide-selected
        ></v-autocomplete>
        <v-select
          clearable
          outlined
          label="Pet"
          :loading="loadingSearch"
          :items="petsList"
          v-model="petSelected"
          item-text="nome_pet"
          item-value="id_pet"
        >
        </v-select>
        <v-btn class="bg-verde mb-8" rounded dark @click="searchOrcamentos()">
          Buscar
        </v-btn>
      </div>
    </div>

    <div>
      <v-dialog
        v-model="dialogNovoOrcamento"
        v-if="dialogNovoOrcamento"
        width="700px"
        scrollable
        persistent
      >
        <ModalNovoOrcamento @close="closeDialogNovoOrcamento" />
      </v-dialog>
    </div>

    <div>
      <v-dialog
        persistent
        v-model="dialogDetalhesOrcamento"
        v-if="dialogDetalhesOrcamento"
        width="700px"
        scrollable
      >
        <ModalNovoOrcamento
          :isEdit="true"
          :orcamentoDetalhes="orcamentoDetalhes"
          @close="closeDialogDetalhesOrcamento"
          @updateOrcamentos="searchOrcamentos"
        />
      </v-dialog>
    </div>

    <div>
      <v-dialog
        v-model="dialogGerarComanda"
        v-if="dialogGerarComanda"
        width="700px"
        scrollable
      >
        <ModalGerarComanda
          :orcamento="orcamentoComanda"
          @close="dialogGerarComanda = false"
          @updateOrcamentos="searchOrcamentos"
        />
      </v-dialog>
    </div>
    <template v-if="dialogComandas">
      <v-dialog
        v-model="dialogComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="dialogComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comandas </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <ModalComandas :tutor="tutorOrcamento" :petId="idPetOrcamento" />
        </v-card>
      </v-dialog>
    </template>

    <v-data-table
      :headers="headersOrcamento"
      :items="orcamentosList"
      class="elevation-1 mt-5"
    >
      <template v-slot:item.data_criacao="{ item }">
        {{
          item.data_criacao
            .split("-")
            .reverse()
            .join("/")
        }}
      </template>
      <template v-slot:item.valor="{ item }">
        {{ formatoMoeda(item.valor) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          text
          small
          @click="showDialogDetalhesOrcamento(item)"
          v-if="item.id_status == 1"
        >
          <v-icon title="Editar Orçamento">mdi-text-box-edit-outline</v-icon>
        </v-btn>
        <v-btn
          text
          small
          @click="gerarPdfOrcamento(item)"
          v-if="item.id_status != 4"
        >
          <v-icon title="Gerar PDF">mdi-printer-outline</v-icon>
        </v-btn>
        <v-btn
          text
          small
          @click="showDialogGerarComanda(item)"
          v-if="item.id_status == 1"
        >
          <v-icon title="Gerar Comanda">mdi-cash-plus</v-icon>
        </v-btn>
        <v-btn
          text
          small
          v-if="item.id_status == 2"
          @click="openDialogComanda(item)"
        >
          <v-icon title="Abrir Comandas">mdi-account-cash</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <template>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :paginate-elements-by-height="1400"
          filename="Orcamento"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf"
        >
          <section slot="pdf-content" class="m-4" id="pdfOrcamento">
            <div class="text-center">
              <img
                src="@/assets/images/logo_sistema.jpg"
                alt="logo centro olhos"
                width="70px"
              />
              <!-- <img
                src="../assets/images/logo_caes_gatos.jpg"
                alt="logo cães e gatos"
                width="50px"
              /> -->
            </div>
            <h3 class="text-center mb-5" style="margin-top: 1rem">
              ORÇAMENTO
            </h3>

            <div class="d-flex mt-n2" style="gap: 1rem">
              <p>
                <b>ID Orçamento: </b>
                {{ orcamentoPdf.id }}
              </p>
              <p>
                <b>Criado em: </b>
                {{ orcamentoPdf.data_criacao }}
              </p>
              <p>
                <b>Criado por: </b>
                {{ orcamentoPdf.nome_criador }}
              </p>
            </div>
            <div class="d-flex mt-n2" style="gap: 1rem">
              <p>
                <b>Cliente: </b>
                {{ orcamentoPdf.nome_cliente }} (ID:
                {{ orcamentoPdf.id_cliente }})
              </p>
              <p>
                <b>Pet: </b>
                {{ orcamentoPdf.nome_pet }} (ID: {{ orcamentoPdf.id_pet }})
              </p>
            </div>
            <table style="width:100%; border: 1px solid black;">
              <tr>
                <th style="padding:10px 15px 10px 15px;">
                  Procedimento
                </th>
                <th class="text-center" style="padding:10px 15px 10px 15px;">
                  Quantidade
                </th>
                <th class="text-center" style="padding:10px 15px 10px 15px;">
                  Valor Unitário
                </th>
                <th class="text-center" style="padding:10px 15px 10px 15px;">
                  Valor Total
                </th>
              </tr>
              <tr
                v-for="item in orcamentoPdf.itens"
                :key="item.id"
                style="border-bottom: 1px solid black;"
              >
                <td style="padding:10px 15px 10px 15px;">
                  {{ item.procedimento }}
                </td>
                <td class="text-center" style="padding:10px 15px 10px 15px;">
                  {{ item.quantidade }}
                </td>
                <td class="text-center" style="padding:10px 15px 10px 15px;">
                  {{ formatoMoeda(item.valor_unidade) }}
                </td>
                <td class="text-center" style="padding:10px 15px 10px 15px;">
                  {{ formatoMoeda(item.valor) }}
                </td>
              </tr>
              <tr>
                <td
                  class="text-right"
                  colspan="4"
                  style="padding:10px 15px 10px 15px;"
                >
                  <b>Valor Total: </b>
                  {{ formatoMoeda(orcamentoPdf.valorTotal) }}
                </td>
              </tr>
            </table>

            <p style="font-size: 0.8rem;">
              Este orçamento possui um prazo de validade de
              {{ orcamentoPdf.validade }} dia(s) a partir da data de criação ({{
                orcamentoPdf.data_criacao
              }}). Após o vencimento deste prazo, o orçamento não estará mais
              disponível para aceitação ou alterações.
            </p>

            <div class="mt-5 text-center" style="word-spacing: 5px">
              <p style="border-top: 1px solid black;">
                {{
                  `${unidade.logradouro}, ${unidade.numero} - ${unidade.cidade}/${unidade.estado} | CEP: ${unidade.cep}`
                }}
              </p>
              <p class="mt-n2">
                {{ `Unidade: ${unidade.nome} | Telefone: ${unidade.telefone}` }}
              </p>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </template>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VueHtml2pdf from "vue-html2pdf";
import ModalNovoOrcamento from "../components/orcamento/modal_novo_orcamento.vue";
import ModalGerarComanda from "../components/orcamento/modal_gerar_comanda.vue";
import ModalComandas from "../components/pacientes/modal_comandas_teste.vue";
import PacienteService from "../services/paciente_service";
import PetsService from "../services/pets_service";
import OrcamentoService from "../services/orcamento_service";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  components: {
    ModalNovoOrcamento,
    VueHtml2pdf,
    ModalGerarComanda,
    ModalComandas,
  },
  data() {
    return {
      headersOrcamento: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Cliente", value: "nome_cliente" },
        { text: "Pet", value: "nome_pet" },
        { text: "Data criação", value: "data_criacao" },
        { text: "Criado por", value: "nome_criador" },
        { text: "Valor total", value: "valor" },
        { text: "Status", value: "status" },
        { text: "", value: "actions" },
      ],
      orcamentosList: [],
      dialogGerarComanda: false,
      dialogNovoOrcamento: false,
      dialogDetalhesOrcamento: false,
      dialogComandas: false,
      loadingSearch: false,
      tutoresList: [],
      tutorSelected: "",
      searchTutor: "",
      services: {
        pacienteService: new PacienteService(),
        petsService: PetsService.build(),
        orcamentoService: OrcamentoService.build(),
      },
      petsList: [],
      petSelected: "",
      searchedIdOrcamento: "",
      unidade: "",
      orcamentoDetalhes: {},
      orcamentoPdf: {},
      orcamentoComanda: {},
      tutorOrcamento: {},
      idPetOrcamento: "",
    };
  },
  mounted() {
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade;
  },
  watch: {
    searchTutor(nome) {
      if (!nome) {
        return;
      }
      if (nome.length > 2) {
        this.searchTutorbyName(nome);
      }
    },
    tutorSelected() {
      this.searchPetByClienteId(this.tutorSelected);
    },
  },
  methods: {
    async openDialogComanda(item) {
      await this.services.pacienteService
        .getPacienteByClienteId(item.id_cliente)
        .then((resp) => {
          this.tutorOrcamento = resp.data;
        });
      this.idPetOrcamento = item.id_pet;

      this.dialogComandas = true;
    },
    showDialogGerarComanda(orcamento) {
      this.dialogGerarComanda = true;
      this.orcamentoComanda = orcamento;
    },
    showDialogNovoOrcamento() {
      this.dialogNovoOrcamento = true;
    },
    async showDialogDetalhesOrcamento(orcamento) {
      const itensOrcamento = await this.services.orcamentoService
        .getDetalhesOrcamentoById(orcamento.id)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else {
            return;
          }
        })
        .then((resp) => {
          if (!resp) return;
          return resp.data.itens;
        });

      if (!itensOrcamento) return;

      this.orcamentoDetalhes = {
        id: orcamento.id,
        cliente_nome: orcamento.nome_cliente,
        pet_nome: orcamento.nome_pet,
        itens: itensOrcamento.map((item) => {
          return {
            ...item,
            valor: parseFloat(item.valor_unidade),
            qtdProcedimento: parseInt(item.quantidade),
          };
        }),
        validade: orcamento.validade,
        data_criacao: orcamento.data_criacao,
        id_cliente: orcamento.id_cliente,
        id_pet: orcamento.id_pet,
      };
      this.dialogDetalhesOrcamento = true;
    },
    closeDialogNovoOrcamento() {
      this.dialogNovoOrcamento = false;
    },
    closeDialogDetalhesOrcamento() {
      this.dialogDetalhesOrcamento = false;
    },
    searchTutorbyName(nome) {
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutoresList = [];
      this.services.pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutoresList = body.data.pacientes;
            } else {
              this.tutoresList = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutoresList = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    async searchPetByClienteId(id) {
      await this.services.petsService.getPetByTutorId(id).then((resp) => {
        this.petsList = resp;
      });
    },
    searchOrcamentos() {
      if (this.searchedIdOrcamento) {
        this.tutorSelected = "";
        this.petSelected = "";
        this.services.orcamentoService
          .getOrcamentosByOrcamentoId(this.searchedIdOrcamento)
          .then((resp) => {
            if (resp.status == 200) {
              return resp.json();
            } else if (resp.status == 204) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Nenhum registro encontrado",
                color: "warning",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.internalServerError,
                color: "warning",
              });
            }
          })
          .then((resp) => {
            this.orcamentosList = [
              {
                ...resp.data.orcamento,
                status:
                  resp.data.orcamento.id_status == 1
                    ? "Pendente"
                    : resp.data.orcamento.id_status == 2
                    ? "Comanda gerada"
                    : "Prazo Expirado",
                nome_cliente: resp.data.cliente.nome,
                nome_pet: resp.data.pet.nome,
                id_cliente: resp.data.cliente.id_cliente,
                id_pet: resp.data.pet.id,
              },
            ];
          });
      } else if (this.petSelected) {
        this.services.orcamentoService
          .getOrcamentosByPetId(this.unidade.id, this.petSelected)
          .then((resp) => {
            if (resp.status == 200) {
              return resp.json();
            } else if (resp.status == 204) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Nenhum registro encontrado",
                color: "warning",
              });
              return;
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.internalServerError,
                color: "warning",
              });
            }
          })
          .then((resp) => {
            if (!resp) return;
            this.orcamentosList = resp.data.orcamento.map((orc) => {
              return {
                ...orc,
                status:
                  orc.id_status == 1
                    ? "Pendente"
                    : orc.id_status == 2
                    ? "Comanda gerada"
                    : "Prazo Expirado",
                nome_cliente: resp.data.cliente.nome,
                nome_pet: resp.data.pet.nome,
                id_cliente: resp.data.cliente.id_cliente,
                id_pet: resp.data.pet.id,
              };
            });
          });
      } else if (this.tutorSelected) {
        this.services.orcamentoService
          .getOrcamentosByClienteId(this.unidade.id, this.tutorSelected)
          .then((resp) => resp.json())
          .then((resp) => {
            this.orcamentosList = resp.data.orcamento.map((orc) => {
              return {
                ...orc,
                status:
                  orc.id_status == 1
                    ? "Pendente"
                    : orc.id_status == 2
                    ? "Comanda gerada"
                    : "Prazo Expirado",
                nome_cliente: resp.data.cliente.nome,
                nome_pet: orc.dados_pet.nome,
              };
            });
          });
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha algum dos parâmetros de pesquisa",
          color: "error",
        });
        return;
      }
    },
    async gerarPdfOrcamento(orcamento) {
      const itensOrcamento = await this.services.orcamentoService
        .getDetalhesOrcamentoById(orcamento.id)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else {
            return;
          }
        })
        .then((resp) => {
          if (!resp) return;
          return resp.data.itens;
        });

      this.orcamentoPdf = {
        id: orcamento.id,
        data_criacao: orcamento.data_criacao
          .split("-")
          .reverse()
          .join("/"),
        nome_criador: orcamento.nome_criador,
        nome_cliente: orcamento.nome_cliente,
        nome_pet: orcamento.nome_pet,
        id_pet: orcamento.id_pet,
        id_cliente: orcamento.id_cliente,
        itens: itensOrcamento,
        valorTotal: orcamento.valor,
        validade: orcamento.validade,
      };

      document.getElementById("pdfOrcamento").style.cssText =
        "font-size: 1rem; color: black;";

      this.$refs.html2Pdf.generatePdf();
    },
    formatoMoeda(valor) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(valor);
    },
  },
};
</script>

<style></style>
